import { DataProps } from '@innedit/formidable';
import { FeatureData } from '@innedit/innedit';
import { DocumentType, PageType } from '@innedit/innedit-type';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import HOCGroup from '../../../components/Group/HOC';
import Item from './Item';

// const sortFunc = (a, b) => b.datetime - a.datetime;

export interface FieldProps {
  label: string;
  name: string;
  type: string;
}

export interface DataPagesListProps extends Omit<DataProps, 'componentType'> {
  customFields?: FieldProps | FieldProps[];
  display?: 'inside' | 'group';
  editPathname?: string;
  espaceId: string;
  nothingLabel?: string;
  title?: string;
  type?: 'parent' | 'sub';
}

const DataPagesList: FC<DataPagesListProps> = ({
  customFields,
  display,
  editPathname,
  espaceId,
  nothingLabel,
  params,
  title,
  type = 'parent',
}) => {
  const [docs, setDocs] = useState<DocumentType<PageType>[]>();

  useEffect(() => {
    let isMounted = true;
    let unsub: (() => void) | undefined;

    const featureData = new FeatureData<PageType>({
      espaceId,
      collectionName: 'pages',
      parentCollectionName:
        !espaceId || 'sub' === type ? params?.collectionName : undefined,
      parentId: !espaceId || 'sub' === type ? params?.docId : undefined,
    });

    if ('sub' === type || params?.docId) {
      unsub = featureData.watch(
        newDocs => {
          if (isMounted) {
            setDocs(newDocs);
          }
        },
        {
          wheres: {
            parent: espaceId && 'parent' === type ? params?.docId : undefined,
          },
        },
      );
    } else {
      setDocs([]);
    }

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [espaceId, params]);

  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const model = new FeatureData<PageType>({
      espaceId,
      collectionName: 'pages',
      parentCollectionName:
        !espaceId || 'sub' === type ? params?.collectionName : undefined,
      parentId: !espaceId || 'sub' === type ? params?.docId : undefined,
    });

    const data: { [key: string]: any } = {
      libelle: '',
    };

    if (customFields) {
      (Array.isArray(customFields) ? customFields : [customFields]).forEach(
        field => {
          data[field.name] = '';
        },
      );
    }

    const newDoc: { [key: string]: any } = model.initialize({
      ...data,
      parent: espaceId && 'parent' === type ? params?.docId : undefined,
    });

    if (espaceId && 'parent' === type && !params?.docId) {
      throw new Error(
        'le docId est obligatoire pour associer cette feature a un parent',
      );
    }

    model.create(newDoc).catch(error => {
      throw new Error(`Features List : handleAdd() : ${error.message}`);
    });
  };

  const handleRemoveOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const index = event.currentTarget.getAttribute('data-index');
    if (docs && index) {
      const featureData = new FeatureData<PageType>({
        espaceId,
        collectionName: 'pages',
        parentCollectionName:
          !espaceId || 'sub' === type ? params?.collectionName : undefined,
        parentId: !espaceId || 'sub' === type ? params?.docId : undefined,
      });

      featureData.delete(docs[parseInt(index, 10)].id);
    }
  };

  if ('parent' === type && (!params || !params.docId)) {
    return null;
  }

  return (
    <HOCGroup addOnClick={handleAddOnClick} display={display} title={title}>
      {!docs && <p className="m-0">Chargement en cours</p>}
      {docs && 0 === docs.length ? (
        <p className="m-0">{nothingLabel || 'Aucun élément'}</p>
      ) : (
        <div className="flex flex-col space-y-2">
          {docs?.map((doc, index) => (
            <Item
              key={doc.id || index}
              doc={doc}
              editPathname={editPathname}
              index={index}
              removeOnClick={handleRemoveOnClick}
            />
          ))}
        </div>
      )}
    </HOCGroup>
  );
};

export default DataPagesList;
