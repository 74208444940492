import { DataFieldArrayProps, FieldArray } from '@innedit/formidable';
import React, { FC } from 'react';

import MediasRender from './Render';

export interface MediasProps
  extends Omit<DataFieldArrayProps, 'componentType'> {
  espaceId: string;
  docType?: string;
}

const Medias: FC<MediasProps> = props => (
  <FieldArray {...props} component={MediasRender} />
);

export default Medias;
