import { DataProps, useSelector } from '@innedit/formidable';
import React, { FC } from 'react';

import { ReducersProps } from '../../../reducers';

export interface DataUtilityQRCodeProps
  extends Omit<DataProps, 'componentType'> {
  field: string;
  formName: string;
}

const DataUtilityQRCode: FC<DataUtilityQRCodeProps> = ({ field, formName }) => {
  const values = useSelector(
    (state: ReducersProps) => state.form[formName].values,
  );
  const url = values[field];

  return (
    <div className="flex justify-center">
      <img alt="qrcode" src={url} />
    </div>
  );
};

export default DataUtilityQRCode;
