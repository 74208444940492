import styled from '@emotion/styled';

import font from '../../../styles/font';
import transition from '../../../styles/transition';
import { ButtonProps } from '../props';
import getButtonColorProps from './color';
import getButtonSizeProps from './size';
import getButtonVariantProps from './variant';

const ButtonEL = styled.button<ButtonProps>`
  appearance: none !important;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: ${font.weight.light};

  transition: background ${transition.timing.base} ${transition.easing.base},
    border-color ${transition.timing.base} ${transition.easing.base},
    color ${transition.timing.base} ${transition.easing.base},
    box-shadow ${transition.timing.base} ${transition.easing.base};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? 'none !important' : 'auto')};

  ${({ color }) => getButtonColorProps(color)};
  ${({ size, square }) => getButtonSizeProps({ size, square })};
  ${({ color, selected, variant }) =>
    getButtonVariantProps({ color, selected, variant })};
`;

export default ButtonEL;
