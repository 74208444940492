import { DocumentType, FeatureType } from '@innedit/innedit-type';
import classnames from 'classnames';
import objectHash from 'object-hash';
import React, { FC, SyntheticEvent, useRef } from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';

import Button from '../../../components/Button';
import IconDelete from '../../../icons/Delete';
import IconEdit from '../../../icons/Edit';
import Field from '../../../styles/Field';
import { FieldProps, LibelleProps } from './index';

const ItemTypes = {
  ITEM: 'item',
};

interface DragItem {
  index: number;
  photo: string;
  type: string;
}

const Libelle: FC<{
  className?: string;
  field?: LibelleProps;
  index: number;
  doc: DocumentType<FeatureType>;
  onChange: (
    event: SyntheticEvent<HTMLSelectElement | HTMLInputElement>,
  ) => void;
}> = ({ className, doc, field, index, onChange }) => {
  if (field && field.options) {
    return (
      <Field
        className="is-expanded select"
        noBorderRight
        noMarginBottom
        noMarginRight
        noRightRadius
      >
        <select
          className={classnames(className, 'select')}
          data-field="select"
          data-index={index}
          data-name={field.name}
          defaultValue={doc[field.name]}
          onChange={onChange}
        >
          <option value="">-- veuillez selectionner une valeur --</option>
          {field.options?.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </Field>
    );
  }

  return (
    <Field
      className={className}
      noBorderRight
      noMarginBottom
      noMarginRight
      noRightRadius
    >
      <div>{doc[field?.name || 'libelle']}</div>
      <div className="label">
        <span>Libellé</span>
      </div>
    </Field>
  );
};

export interface ListItemProps {
  customFields?: FieldProps | FieldProps[];
  espaceId: string;
  handleChangePosition: (oldIndex: number, newIndex: number) => void;
  index: number;
  doc: DocumentType<FeatureType>;
  libelle?: LibelleProps;
  onChange: (
    event: SyntheticEvent<HTMLSelectElement | HTMLInputElement>,
  ) => void;
  removeOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  showLibelle?: boolean;
}

const Item: FC<ListItemProps & { isDragging: boolean }> = ({
  customFields,
  espaceId,
  onChange,
  index,
  isDragging,
  doc,
  libelle,
  removeOnClick,
  showLibelle = true,
}) => (
  <div
    className={classnames(
      {
        isDragging,
      },
      'flex',
    )}
  >
    {showLibelle && (
      <Libelle
        className="flex-1"
        doc={doc}
        field={libelle}
        index={index}
        onChange={onChange}
      />
    )}
    {customFields &&
      (Array.isArray(customFields) ? customFields : [customFields]).map(
        (field, j) => (
          <Field
            key={objectHash(field)}
            className={classnames({
              'flex-1': !showLibelle,
              'min-w-36': showLibelle,
            })}
            noBorderRight
            noLeftRadius
            noMarginBottom
            noMarginLeft
            noMarginRight
            noRightRadius
          >
            <div>{doc[field.name]}</div>
            <div className="label">
              <span>{field.label}</span>
            </div>
          </Field>
        ),
      )}
    <div className="flex space-x-1 self-end">
      {doc.ref && doc.ref.id && (
        <Button
          className="no-left-radius"
          color="neutral"
          size="sm"
          tooltip={doc.ref.id}
          type="tooltip"
          variant="ghost"
        />
      )}
      <Button
        color="secondary"
        iconLeft={IconEdit}
        size="sm"
        to={`/espaces/${espaceId}/canaux/${doc.id}/settings/`}
        variant="ghost"
      />
      <Button
        color="danger"
        data-index={index}
        iconLeft={IconDelete}
        onClick={removeOnClick}
        size="sm"
        square
        variant="groove"
      />
    </div>
  </div>
);

const ItemPosition: FC<
  ListItemProps & {
    handleChangePosition: (oldIndex: number, newIndex: number) => void;
    index: number;
  }
> = ({
  espaceId,
  handleChangePosition,
  index,
  doc,
  libelle,
  onChange,
  removeOnClick,
  customFields,
  showLibelle,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.ITEM,
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
    }),
    drop(dragItem: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = dragItem.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      handleChangePosition(dragIndex, hoverIndex);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    item: { doc, index, type: ItemTypes.ITEM },
    type: ItemTypes.ITEM,
  });

  drag(drop(ref));

  return (
    <div ref={ref} className={isOver ? 'isOver' : ''}>
      <Item
        customFields={customFields}
        doc={doc}
        espaceId={espaceId}
        handleChangePosition={handleChangePosition}
        index={index}
        isDragging={isDragging}
        libelle={libelle}
        onChange={onChange}
        removeOnClick={removeOnClick}
        showLibelle={showLibelle}
      />
    </div>
  );
};

export default ItemPosition;
