import { DataProps, Form } from '@innedit/formidable';
import { DocumentType, ModelType } from '@innedit/innedit-type';
import React, { ReactElement } from 'react';

interface ModalProps<T extends ModelType> {
  espaceId?: string;
  formName: string;
  doc: DocumentType<T>;
  modalDatas: DataProps | DataProps[];
  onSubmit: (values: any) => void;
}

const Modal = <T extends ModelType>({
  espaceId,
  formName,
  doc,
  modalDatas,
  onSubmit,
}: ModalProps<T>): ReactElement | null => (
  <Form
    datas={modalDatas}
    hideSubmitButton
    initialValues={doc}
    name={formName}
    onSubmit={onSubmit}
    params={{ doc, espaceId, docId: doc.id }}
  />
);

export default Modal;
