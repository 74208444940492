import { DocumentType, SlotType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import compact from 'lodash/compact';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../Button';

dayjs.locale('fr');

export const calcEnd = (
  startTime: string,
  duration?: string,
): string | null => {
  if (!duration) {
    return null;
  }

  const [startH, startM] = startTime.split(':');
  const [durationH, durationM] = duration.split(':');

  return `${Number(startH) + Number(durationH)}:${String(
    Number(startM) + Number(durationM),
  ).padStart(2, '0')}`;
};

const RecurringItems: FC<{
  espaceId: string;
  datas: DocumentType<SlotType>[];
  produitId: string;
}> = ({ espaceId, datas, produitId }) => {
  const { t } = useTranslation();

  return (
    <>
      {['0', '1', '2', '3', '4', '5', '6'].map(day => {
        const items = datas.filter(d => day === d.day);

        if (0 === items.length) {
          return null;
        }

        return (
          <div key={day} className="flex flex-col w-full">
            <span>{`tous les ${t(`days.${day}`)}s :`}</span>
            {items
              .sort((a, b) => a.start.localeCompare(b.start))
              .map(({ duration, id, quantity, start }) => (
                <Button
                  key={id}
                  to={`/espaces/${espaceId}/produits/${produitId}/slots/${id}/update/`}
                  variant="clear"
                >
                  <div className="flex justify-between flex-1">
                    <span>
                      {compact([start, calcEnd(start, duration)]).join(' - ')}
                    </span>
                    <span className="text-light-800 italic">
                      {t('slot.places', { count: quantity })}
                    </span>
                  </div>
                </Button>
              ))}
          </div>
        );
      })}
    </>
  );
};

export default RecurringItems;
