import { useSelector, WrappedFieldArrayProps } from '@innedit/formidable';
import { PaiementItemType } from '@innedit/innedit-type';
import React, { SyntheticEvent, VFC } from 'react';

import HOCGroup from '../../../components/Group/HOC';
import { ReducersProps } from '../../../reducers';
import { DataPaiementItemsProps } from './index';
import Item from './Item';

const DataPaiementItemsRender: VFC<
  WrappedFieldArrayProps & DataPaiementItemsProps
> = ({ espaceId, formName, label, fields }) => {
  const { values } = useSelector(
    (state: ReducersProps) => state.form[formName],
  );

  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const newItem: PaiementItemType = {
      name: '',
      price: 0,
      quantity: 0,
    };

    fields.push(newItem);
  };

  const handleDeleteOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const idx = event.currentTarget.getAttribute('data-index');
    if (null !== idx) {
      fields.remove(parseInt(idx, 10));
    }
  };

  const editable = !values?.status;

  return (
    <HOCGroup
      addOnClick={editable ? handleAddOnClick : undefined}
      title={label}
    >
      {fields.length > 0 ? (
        <ul>
          {fields.map((name, fieldIndex, allfields) => (
            <Item
              key={name}
              deleteOnClick={editable ? handleDeleteOnClick : undefined}
              espaceId={espaceId}
              index={fieldIndex}
              isEditable={editable}
              name={name}
              produits={allfields}
            />
          ))}
        </ul>
      ) : (
        <p>Aucun produit</p>
      )}
    </HOCGroup>
  );
};

export default DataPaiementItemsRender;
