import { WrappedFieldArrayProps } from '@innedit/formidable';
import React, { FC } from 'react';

import { CaracteristiquesProps } from './index';
import Item from './Item';

const Render: FC<WrappedFieldArrayProps & CaracteristiquesProps> = ({
  fields,
}) => (
  <>
    {fields.map((field, idx, f) => (
      <Item
        key={f.get(idx).id}
        data={f.get(idx)}
        handleRemove={() => fields.remove(idx)}
        name={field}
      />
    ))}
  </>
);

export default Render;
