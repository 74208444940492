import { DataSection, FieldArrayFieldsProps } from '@innedit/formidable';
import { CommandeProduitType } from '@innedit/innedit-type';
import { Link } from 'gatsby';
import React, { FC, SyntheticEvent } from 'react';

import Button from '../../../components/Button';
import IconDelete from '../../../icons/Delete';

const Item: FC<{
  espaceId: string;
  data?: any;
  deleteOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  index: number;
  isEditable: boolean;
  name: string;
  produits: FieldArrayFieldsProps<CommandeProduitType>;
}> = ({ espaceId, data, deleteOnClick, index, isEditable, name, produits }) => {
  const item = produits.get(index);

  return (
    <div className="flex flex-row items-center space-x-6">
      {item.thumbnail && (
        <img alt={item.name} className="h-[60px]" src={item.thumbnail} />
      )}

      {isEditable ? (
        <div className="flex-1">
          <DataSection
            datas={
              [
                {
                  cols: ['none', 3],
                  componentType: 'grid',
                  datas: [
                    {
                      componentType: 'input',
                      labelShow: false,
                      name: 'name',
                      placeholder: 'Nom',
                    },
                    {
                      componentType: 'input',
                      labelShow: false,
                      name: 'quantity',
                      placeholder: 'Quantity',
                      type: 'number',
                    },
                    {
                      componentType: 'input',
                      labelShow: false,
                      name: 'price',
                      placeholder: 'Prix',
                      type: 'number',
                    },
                  ],
                },
              ] as any
            }
            name={name}
          />
        </div>
      ) : (
        <>
          <div className="flex-1">
            <div className="name">
              {item.id ? (
                <Link to={`/espaces/${espaceId}/produits/${item.id}/update/`}>
                  {item.name}
                </Link>
              ) : (
                <strong>{item.name}</strong>
              )}
            </div>
          </div>
          <div className="prix">
            <p>
              {item.price ? (
                <strong>{`${(item.quantity || 1) * item.price} €`}</strong>
              ) : (
                <span>Aucun prix</span>
              )}
              {undefined !== item.quantity && 1 < item.quantity && (
                <span>{` (${item.quantity} x ${item.price}€)`}</span>
              )}
            </p>
          </div>
        </>
      )}

      {deleteOnClick && (
        <Button
          color="danger"
          datas={{
            'data-index': index,
          }}
          iconLeft={IconDelete}
          onClick={deleteOnClick}
          variant="groove"
        />
      )}
    </div>
  );
};

export default Item;
