import { Box, Group } from '@innedit/formidable';
import React, { FC, ReactNode, SyntheticEvent } from 'react';

import IconAdd from '../../icons/Add';
import Button from '../Button';

const HOCGroup: FC<{
  addOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  customInfos?: ReactNode;
  display?: 'inside' | 'group' | 'content';
  title?: string;
}> = ({ addOnClick, children, customInfos, display, title }) => (
  <Box>
    <Group
      customInfos={
        customInfos ??
        (addOnClick && (
          <Button
            className="min-h-0 absolute right-0 top-0"
            iconLeft={IconAdd}
            onClick={addOnClick}
            variant="link"
          />
        ))
      }
      display={display}
      title={title}
    >
      {children}
    </Group>
  </Box>
);

export default HOCGroup;
