import { DocumentType, SlotType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import compact from 'lodash/compact';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../Button';

dayjs.locale('fr');

export const calcEnd = (
  startTime: string,
  duration?: string,
): string | null => {
  if (!duration) {
    return null;
  }

  const [startH, startM] = startTime.split(':');
  const [durationH, durationM] = duration.split(':');

  return `${Number(startH) + Number(durationH)}:${String(
    Number(startM) + Number(durationM),
  ).padStart(2, '0')}`;
};

interface SlotProps {
  espaceId: string;
  data: DocumentType<SlotType>;
  produitId: string;
}

const NotRecurringItem: FC<SlotProps> = ({ espaceId, data, produitId }) => {
  const { t } = useTranslation();

  const { date, duration, quantity, start } = data;

  const info = (
    <Button
      to={`/espaces/${espaceId}/produits/${produitId}/slots/${data.id}/update/`}
      variant="clear"
    >
      <div className="flex-1 flex justify-between">
        <span>{compact([start, calcEnd(start, duration)]).join(' - ')}</span>
        {quantity ? (
          <span className="text-light-800 italic">
            {t('slot.places', { count: quantity })}
          </span>
        ) : (
          'Éditer'
        )}
      </div>
    </Button>
  );

  return (
    <div className="flex flex-col flex-1">
      <span className="text-left">
        {date ? dayjs(date).format('dddd D MMM YYYY') : '<date>'}
      </span>
      {info}
    </div>
  );
};

export default NotRecurringItem;
