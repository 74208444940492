import { DataField, DataFieldProps } from '@innedit/formidable';
import { FeatureData, WhereProps } from '@innedit/innedit';
import { DocumentType, FeatureType } from '@innedit/innedit-type';
import React, { FC, memo, useEffect, useState } from 'react';

export interface DataFeaturesSelectProps
  extends Omit<DataFieldProps, 'componentType'> {
  espaceId: string;
  collectionName: string;
  orderDirection?: 'asc' | 'desc';
  orderField?: string;
  wheres?: WhereProps;
}
const DataFeaturesSelect: FC<DataFeaturesSelectProps> = ({
  espaceId,
  className,
  collectionName,
  formName,
  label,
  name,
  orderDirection,
  orderField,
  wheres,
}) => {
  const [options, setOptions] = useState<DocumentType<FeatureType>[]>();

  useEffect(() => {
    const feature = new FeatureData<FeatureType>({
      collectionName,
      espaceId,
      orderDirection,
      orderField,
    });

    const unsub: () => void = feature.watch(
      newDocs => {
        setOptions(newDocs);
      },
      {
        wheres,
      },
    );

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [espaceId, collectionName, orderDirection, orderField, wheres]);

  if (!options || 0 === options.length) {
    return null;
  }

  return (
    <DataField
      className={className}
      componentType="select"
      formName={formName}
      label={label}
      name={name}
      options={options.map(item => ({
        label: item.libelle,
        value: item.id,
      }))}
    />
  );
};

const propsAreEqual = (
  prevProps: DataFeaturesSelectProps,
  nextProps: DataFeaturesSelectProps,
): boolean =>
  prevProps.espaceId === nextProps.espaceId &&
  prevProps.collectionName === nextProps.collectionName &&
  prevProps.orderDirection === nextProps.orderDirection &&
  prevProps.orderField === nextProps.orderField;

export default memo(DataFeaturesSelect, propsAreEqual);
