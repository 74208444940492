import styled from '@emotion/styled';
import React, { FC, SyntheticEvent } from 'react';

import { borderRadius, colors } from '../../../styles/theme';

const CloseEL = styled.button`
  position: absolute;
  border: none;
  background: none;
  margin-right: 0;
  top: 21px;
  right: 13px;
  color: ${colors.dark[500]};
  transition: color 0.2s ease;
  outline: none;
  cursor: pointer;
  border-radius: ${borderRadius.s};
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  &:hover {
    background-color: ${colors.light[500]};
  }

  .text {
    display: none;
  }

  &:before {
    content: '✕';
    font-size: 20px;
  }
`;
interface ModalCloseProps {
  closeOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
}

const ModalClose: FC<ModalCloseProps> = ({ closeOnClick }) => (
  <CloseEL onClick={closeOnClick} type="button">
    <span className="text">Fermer</span>
  </CloseEL>
);

export default ModalClose;
