import { DataProps, DataSection } from '@innedit/formidable';
import React, { FC, SyntheticEvent } from 'react';

import Button from '../../components/Button';
import ButtonGroup from '../../components/Button/Group';
import IconDelete from '../../icons/Delete';
import IconPaste from '../../icons/Paste';
import { spacing } from '../../styles/theme';

export interface DataHyperLinkProps {
  deleteOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  pasteOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  index: number;
  name: string;
}
const DataHyperLink: FC<DataHyperLinkProps> = ({
  deleteOnClick,
  pasteOnClick,
  index,
  name,
}) => (
  <div className="flex space-x-2">
    <DataSection
      className="flex-1"
      datas={[
        {
          cols: ['none', 4],
          componentType: 'grid',
          datas: [
            {
              componentType: 'input',
              labelShow: false,
              name: 'label',
              placeholder: 'Libellé',
              wrapperProps: {
                col: ['full', 3],
              },
            },
            {
              componentType: 'input',
              fieldProps: {
                autoComplete: 'url',
              },
              labelShow: false,
              name: 'value',
              placeholder: 'URL',
              type: 'url',
            },
          ],
          gap: spacing[2],
        } as DataProps,
      ]}
      name={name}
    />
    <ButtonGroup>
      <Button
        color="neutral"
        datas={{
          'data-field': name,
        }}
        iconLeft={IconPaste}
        onClick={pasteOnClick}
        square
        variant="groove"
      />
      {deleteOnClick && (
        <Button
          color="danger"
          datas={{
            'data-index': index,
          }}
          iconLeft={IconDelete}
          onClick={deleteOnClick}
          square
          variant="groove"
        />
      )}
    </ButtonGroup>
  </div>
);

export default DataHyperLink;
