const displayCurrency = (value?: string): string => {
  switch (value) {
    case 'EUR':
      return '€';
    case 'USD':
      return '$';
    case 'GBP':
      return '£';
    default:
      return value ?? '€';
  }
};

export default displayCurrency;
