import { DataSection, useSelector } from '@innedit/formidable';
import { DocumentType, EspaceType } from '@innedit/innedit-type';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReducersProps } from '../../../reducers';

const PricesDefault: FC<{ espaceId: string }> = ({ espaceId }) => {
  const { t } = useTranslation();

  const {
    cms: { espaces },
  } = useSelector((state: ReducersProps) => state);

  const espace: DocumentType<EspaceType> | undefined = espaces?.find(
    e => e.id === espaceId,
  );

  const prices =
    espace && espace.products && espace.products.prices
      ? espace.products.prices
      : ['public'];

  return (
    <>
      {prices && prices.length > 0 && (
        <DataSection
          datas={
            [
              {
                cols: ['none', prices.length],
                componentType: 'grid',
                datas: prices.map(p => ({
                  componentType: 'input',
                  label: t(`espace.products.prices.${p}.label`),
                  name: p,
                  type: 'number',
                })),
              },
            ] as any[]
          }
          name="prices"
        />
      )}
    </>
  );
};

export default PricesDefault;
