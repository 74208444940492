import { DataField, DataProps } from '@innedit/formidable';
import { FeatureData } from '@innedit/innedit';
import { TarificationType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import displayCurrency from '../../utils/displayCurrency';

export interface TarificationsSelectProps
  extends Omit<DataProps, 'componentType'> {
  espaceId: string;
  formName: string;
  name: string;
}

const TarificationsSelect: FC<TarificationsSelectProps> = ({
  espaceId,
  name,
  formName,
  ...props
}) => {
  const [options, setOptions] = useState<any[]>();

  useEffect(() => {
    let isMounted = true;

    const tarificationData = new FeatureData<TarificationType>({
      espaceId,
      collectionName: 'tarifications',
      orderDirection: 'desc',
      orderField: 'datetime',
    });

    tarificationData
      .find({
        wheres: {
          boutique: espaceId,
        },
      })
      .then(newDocs => {
        if (isMounted) {
          setOptions(
            newDocs.map(doc => ({
              label: `${doc.libelle} (en ${displayCurrency(doc.currency)})`,
              value: doc.id,
            })),
          );
        }

        return true;
      })
      .catch(error => {
        toast.error(error.message);
        console.error(error.message);
      });

    return () => {
      isMounted = false;
    };
  }, [espaceId]);

  return (
    <DataField
      {...props}
      componentType="select"
      defaultValue="public"
      formName={formName}
      name={name}
      options={options}
    >
      TarificationsSelect
    </DataField>
  );
};

export default TarificationsSelect;
