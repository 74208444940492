import {
  change,
  useDispatch,
  WrappedFieldArrayProps,
} from '@innedit/formidable';
import React, { FC, SyntheticEvent } from 'react';

import HOCGroup from '../../components/Group/HOC';
import { DataHyperLinkProps } from './index';
import DataHyperLink from './Item';

const DataHyperlinksRender: FC<
  WrappedFieldArrayProps & Omit<DataHyperLinkProps, 'name'>
> = ({ display, fields, formName, title }) => {
  const dispatch = useDispatch();
  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    fields.push({
      name: '',
      value: '',
    });
  };

  const handleDeleteOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const idx = event.currentTarget.getAttribute('data-index');
    if (null !== idx) {
      fields.remove(parseInt(idx, 10));
    }
  };

  const handlePasteOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    const field = event.currentTarget.getAttribute('data-field');
    if (null !== field) {
      const value = await navigator.clipboard.readText();
      dispatch(change(formName, `${field}.value`, value));
    }
  };

  return (
    <HOCGroup addOnClick={handleAddOnClick} display={display} title={title}>
      {fields.length > 0 && (
        <div className="flex flex-col space-y-2">
          {fields.map((field, index) => (
            <DataHyperLink
              key={field}
              deleteOnClick={handleDeleteOnClick}
              index={index}
              name={field}
              pasteOnClick={handlePasteOnClick}
            />
          ))}
        </div>
      )}
      {0 === fields.length && <p>Aucun lien</p>}
    </HOCGroup>
  );
};

export default DataHyperlinksRender;
