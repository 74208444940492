import styled from '@emotion/styled';

import colors from '../../styles/colors';
import { spacing } from '../../styles/theme';

export const TabsBar = styled.ul`
  color: ${colors.dark[400]};
  border-bottom: 1px solid ${colors.neutral[100]} !important;
  padding: 0;
  margin: 0;
  li {
    padding: 0 ${spacing[5]} ${spacing[2.5]} ${spacing[5]};
    margin: 0;
  }
`;

export const TabsBarItem = styled.li`
  position: relative;

  &.is-active {
    font-weight: 400;
    color: ${colors.dark[600]};
    // background: ${colors.neutral[100]};
    // border-radius: ${spacing[1]};

    &:before {
      content: '';
      width: calc(100% - ${spacing[2]});
      border-bottom: 3px solid ${colors.primary[500]};
      position: absolute;
      transform: translate(-${spacing[4]});
      border-top-left-radius: ${spacing[1]};
      border-top-right-radius: ${spacing[1]};
      bottom: 0;
    }
  }
`;
