import { DataFieldArrayProps, FieldArray } from '@innedit/formidable';
import React, { VoidFunctionComponent } from 'react';

import ProductsRender from './Render';

export interface DataCommandesProduitsProps
  extends Omit<DataFieldArrayProps, 'componentType'> {
  espaceId: string;
  formName: string;
}

const DataCommandesProduits: VoidFunctionComponent<
  DataCommandesProduitsProps
> = props => (
  <FieldArray component={ProductsRender} name={props.name} props={props} />
);

export default DataCommandesProduits;
