import { ModelType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

dayjs.extend(advancedFormat);

class Feature<T extends ModelType> extends ModelEspace<T> {
  constructor(props: ModelEspaceProps<T>) {
    super({
      itemFieldTitle: 'createdAt',
      ...props,
    });
  }
}

export default Feature;
