import { IS_MOBILE, IS_WEBAPP } from '../constants/actionTypes2';

/**
 * isMobile
 *
 * @param value
 * @returns {AnyAction}
 */
export function isMobile(value) {
  return {
    test: value,
    type: IS_MOBILE,
  };
}

/**
 * isWebapp
 *
 * @param value
 * @returns {AnyAction}
 */
export function isWebapp(value) {
  return {
    test: value,
    type: IS_WEBAPP,
  };
}
