/* eslint-disable sort-keys-shorthand/sort-keys-shorthand */

import borderRadius from './borderRadius';
import colors from './colors';
import control from './control';
import font from './font';
import screens from './screens';
import spacing from './spacing';

const breakpoints = [screens.sm, screens.md, screens.lg, screens.xl];
const transition = {
  timing: {
    base: '0.3s',
    fast: '0.15s',
  },
  easing: {
    base: 'ease-in-out',
  },
};

const theme = {
  breakpoints,
  colors,
  defaultSpace: 6,
  font,
  screens,
  spacing,
};

export default theme;
export { borderRadius, colors, control, font, screens, spacing, transition };
