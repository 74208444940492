import { DataProps } from '@innedit/formidable';
import { FeatureData } from '@innedit/innedit';
import { DocumentType, FeatureType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import HOCGroup from '../../../components/Group/HOC';
import Item from './Item';

// const sortFunc = (a, b) => b.datetime - a.datetime;

export interface FieldProps {
  label: string;
  name: string;
  type: string;
}

export interface LibelleProps {
  component?: string;
  name: string;
  options?: {
    value: string;
    label: string;
  }[];
}

export interface DataChannelsCrudProps
  extends Omit<DataProps, 'componentType'> {
  customFields?: FieldProps | FieldProps[];
  display?: 'inside' | 'group';
  libelle?: LibelleProps;
  nothingLabel?: string;
  showLibelle?: boolean;
  title?: string;
}

const DataChannelsCrud: FC<DataChannelsCrudProps> = ({
  customFields,
  display,
  libelle,
  nothingLabel,
  params,
  showLibelle = true,
  title,
}) => {
  const [docs, setDocs] = useState<DocumentType<FeatureType>[]>();
  const [timeOuts, setTimeOuts] = useState<{ [id: string]: NodeJS.Timeout }>(
    {},
  );

  useEffect(() => {
    let isMounted = true;
    let unsub: () => void | undefined;

    if (params?.docId) {
      const featureData = new FeatureData<FeatureType>({
        collectionName: 'channels',
        espaceId: params.docId,
      });

      unsub = featureData.watch(newDocs => {
        if (isMounted) {
          setDocs(newDocs);
        }
      });
    }

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [params]);

  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (params?.docId) {
      const model = new FeatureData({
        collectionName: 'channels',
        espaceId: params.docId,
      });

      const data: { [key: string]: any } = {
        libelle: '',
      };

      if (customFields) {
        (Array.isArray(customFields) ? customFields : [customFields]).forEach(
          field => {
            data[field.name] = '';
          },
        );
      }

      const newDoc: { [key: string]: any } = model.initialize(data);

      model.create(newDoc).catch(error => {
        throw new Error(`Features List : handleAdd() : ${error.message}`);
      });
    } else {
      toast.error("L'espace n'existe pas");
    }
  };

  const handleOnChange = (
    event: SyntheticEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    const index = event.currentTarget.getAttribute('data-index');
    const attr = event.currentTarget.getAttribute('data-name');
    const { value } = event.currentTarget;

    if (docs && index && attr) {
      const item = docs[parseInt(index, 10)];

      if (timeOuts && timeOuts[item.id]) {
        clearTimeout(timeOuts[item.id]);
      }

      const timeoutId = setTimeout(() => {
        item.ref.update({
          [attr]: value,
          updatedAt: dayjs().toISOString(),
        });
      }, 1000);

      setTimeOuts(oldTimeOuts => ({ ...oldTimeOuts, [item.id]: timeoutId }));
    }
  };

  const handleRemoveOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const index = event.currentTarget.getAttribute('data-index');
    if (docs && index && params?.docId) {
      const featureData = new FeatureData<FeatureType>({
        collectionName: 'channels',
        espaceId: params.docId,
      });

      featureData.delete(docs[parseInt(index, 10)].id);
    }
  };

  const handleChangePosition = (oldIndex: number, newIndex: number) => {
    if (docs && newIndex - oldIndex !== 1 && params?.docId) {
      let datetime = dayjs().valueOf();
      const newDatetime = docs[newIndex].datetime;

      if (0 < newIndex) {
        const beforeDatetime = docs[newIndex - 1].datetime;
        const diff = Math.floor((beforeDatetime - newDatetime) / 2);
        datetime = newDatetime + diff;
      }
      if (datetime === docs[newIndex].datetime) {
        console.error(
          "le nouveau datetime est le même que celui de l'ancienne position",
        );
      }

      const featureData = new FeatureData<FeatureType>({
        collectionName: 'channels',
        espaceId: params.docId,
      });

      featureData.update(docs[oldIndex].id, {
        datetime,
        updatedAt: dayjs().toISOString(),
      });
    }
  };

  if (!params?.docId) {
    return null;
  }

  return (
    <HOCGroup addOnClick={handleAddOnClick} display={display} title={title}>
      {!docs && <p className="m-0">Chargement en cours</p>}
      {docs && 0 === docs.length ? (
        <p className="m-0">{nothingLabel || 'Aucun élément'}</p>
      ) : (
        <div className="mb-6">
          {docs?.map((doc, index) => (
            <Item
              key={doc.id || index}
              customFields={customFields}
              doc={doc}
              espaceId={params.docId}
              handleChangePosition={handleChangePosition}
              index={index}
              libelle={libelle}
              onChange={handleOnChange}
              removeOnClick={handleRemoveOnClick}
              showLibelle={showLibelle}
            />
          ))}
        </div>
      )}
    </HOCGroup>
  );
};

export default DataChannelsCrud;
