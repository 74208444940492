import {
  change,
  DataField,
  DataFieldProps,
  useDispatch,
} from '@innedit/formidable';
import { FeatureData } from '@innedit/innedit';
import {
  DocumentType,
  PriceType,
  TarificationType,
} from '@innedit/innedit-type';
import keyBy from 'lodash/keyBy';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import displayCurrency from '../../../utils/displayCurrency';

export interface DataPricesSelectProps
  extends Omit<DataFieldProps, 'componentType'> {
  espaceId: string;
  docCollectionName: string;
  docId: string;
  formName: string;
  hasEmpty?: boolean;
}

const DataPricesSelect: FC<DataPricesSelectProps> = ({
  espaceId,
  docCollectionName,
  docId,
  formName,
  hasEmpty,
  label,
  name,
}) => {
  const dispatch = useDispatch();
  const [docs, setDocs] = useState<DocumentType<PriceType>[]>();
  const [tarifications, setTarifications] =
    useState<DocumentType<TarificationType>[]>();

  useEffect(() => {
    let isMounted = true;

    const featureData = new FeatureData<TarificationType>({
      espaceId,
      collectionName: 'tarifications',
    });

    featureData
      .find({
        wheres: {
          hidden: false,
        },
      })
      .then(documents => {
        if (isMounted) {
          setTarifications(documents);
        }

        return true;
      })
      .catch(error => {
        toast.error(error.message);
        console.error(error.message);
      });

    return () => {
      isMounted = false;
    };
  }, [espaceId]);

  useEffect(() => {
    let isMounted = true;
    let unsub: any;

    if (docCollectionName && docId) {
      const featureData = new FeatureData<PriceType>({
        espaceId,
        collectionName: 'prices',
        parentCollectionName: docCollectionName,
        parentId: docId,
      });

      unsub = featureData.watch(newDocs => {
        if (isMounted) {
          setDocs(newDocs);

          if (newDocs && 1 === newDocs.length) {
            // il n'y a qu'un seul price, on peut le selectionner directement
            dispatch(change(formName, name, newDocs[0].id));
          }
        }
      }, {});
    }

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [espaceId, docCollectionName, docId]);

  if (!docId) {
    return null;
  }

  // il faut vérifier la compatibilité entre les différents prix
  // il ne peut pas y avoir plusieurs prix avec le même tarif / type

  if (!docs) {
    return <p>Chargement en cours</p>;
  }

  if (0 === docs.length) {
    return <p>Aucun prix</p>;
  }

  const tarificationsLUT = tarifications
    ? keyBy(tarifications, 'id')
    : undefined;

  return (
    <DataField
      componentType="select"
      formName={formName}
      hasEmpty={hasEmpty}
      label={label}
      name={name}
      options={docs.map(doc => {
        const price =
          tarificationsLUT && tarificationsLUT[doc.type]
            ? tarificationsLUT[doc.type]
            : doc;

        return {
          label: `${price.amount}${displayCurrency(price.currency)} ${
            price.type
          }`,
          value: doc.id,
        };
      })}
    />
  );
};

export default DataPricesSelect;
