import { DocumentType, PaiementType } from '@innedit/innedit-type';
import React, { FC } from 'react';

interface ItemRequiresPaymentMethodProps {
  espaceId: string;
  paiement: DocumentType<PaiementType>;
}

const ItemRequiresPaymentMethod: FC<ItemRequiresPaymentMethodProps> = ({
  espaceId,
  paiement,
}) => <div className="">Envoyer un lien de paiement</div>;

export default ItemRequiresPaymentMethod;
