import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { createStyles } from '@innedit/formidable';

import colors from '../../styles/colors';
import font from '../../styles/font';
import spacing from '../../styles/spacing';
import { screens } from '../../styles/theme';

const Group = styled.div<any>`
  ${createStyles}
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1.5rem;

  display: flex;
  flex-direction: column;

  ${props => {
    if ('inside' === props['data-display']) {
      return css`
        border-top: 1px solid ${colors.light[600]};
        padding: ${spacing[6]} ${spacing[6]} 0 ${spacing[6]};
        margin: 0 -${spacing[6]};
        width: auto;
      `;
    }

    if ('content' === props['data-display']) {
      return css`
        display: grid;
        gap: 1.5rem;
        @media (min-width: ${screens.md}) {
          grid-template-columns: repeat(3, minmax(0, 1fr));

          > div:last-child {
            grid-column: span 2 / span 2;
          }
        }

        > div:first-of-type h2,
        > h2 {
          font-weight: 600;
          font-size: ${font.size.base[0]};
          line-height: ${(font.size.base[1] as { lineHeight: string })
            .lineHeight};
        }

        & + & {
          margin-top: ${spacing[6]};
        }
      `;
    }

    return null;
  }};
`;

export const GroupBody = styled.div`
  background: ${colors.light[50]};
  // border: 1px solid ${colors.neutral[200]};
  padding: ${spacing[6]};

  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);

  @media (min-width: ${screens.md}) {
    border-radius: 0.5rem;
  }
`;

export const GroupDescription = styled.p`
  margin-bottom: ${spacing[5]};

  color: ${colors.neutral[300]};
  font-style: italic;
  font-size: ${font.size.sm[0]};
`;

interface GroupTitleProps {
  'data-display'?: 'inside' | 'group';
}

export const GroupTitle = styled.h2<GroupTitleProps>`
  text-align: left;
  margin-bottom: ${spacing[1]};
  font-size: ${font.size.lg[0]};
  margin-left: ${spacing[6]};

  @media (min-width: ${screens.md}) {
    margin-left: 0;
  }

  ${props => {
    if ('inside' === props['data-display']) {
      return css`
        font-weight: 300 !important;
        font-size: ${font.size.sm[0]} !important;
      `;
    }

    return null;
  }}
`;

export default Group;
