/* eslint-disable sort-keys-shorthand/sort-keys-shorthand */
module.exports = {
  timing: {
    base: '0.3s',
    fast: '0.15s',
  },
  easing: {
    base: 'ease-in-out',
  },
};
