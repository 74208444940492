import { WrappedFieldArrayProps } from '@innedit/formidable';
import React, { FC, SyntheticEvent } from 'react';

import HOCGroup from '../../../components/Group/HOC';
import { PricesTiersProps } from './index';
import Item from './Item';

const PricesTiersRender: FC<WrappedFieldArrayProps & PricesTiersProps> = ({
  fields,
}) => {
  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>): void => {
    event.preventDefault();

    const data = {};
    fields.push(data);
  };

  const handleRemoveOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();

    const index = event.currentTarget.getAttribute('data-index');
    if (null !== index) {
      fields.remove(parseInt(index, 10));
    }
  };

  return (
    <HOCGroup addOnClick={handleAddOnClick} display="inside" title="Niveaux">
      {fields.map((field, index) => (
        <Item
          key={field}
          index={index}
          name={field}
          removeOnClick={handleRemoveOnClick}
        />
      ))}
    </HOCGroup>
  );
};

export default PricesTiersRender;
