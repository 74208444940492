import { Box, DataProps } from '@innedit/formidable';
import { FeatureData } from '@innedit/innedit';
import { DocumentType, SlotType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';

import { GroupBody } from '../../../components/Group';
import Slots from '../../../components/Slots';

export interface DataProduitSlotsProps
  extends Omit<DataProps, 'componentType'> {
  espaceId: string;
  produitId: string;
}

const DataProduitSlots: FC<DataProduitSlotsProps> = ({
  espaceId,
  produitId,
}) => {
  const [slots, setSlots] = useState<DocumentType<SlotType>[]>();

  useEffect(() => {
    let isMounted = true;
    if (produitId) {
      const slotData = new FeatureData<SlotType>({
        espaceId,
        collectionName: 'slots',
        parentCollectionName: 'produits',
        parentId: produitId,
      });

      slotData.watch(
        documents => {
          if (isMounted) {
            setSlots(documents);
          }
        },
        {
          orderDirection: 'desc',
          orderField: 'datetime',
        },
      );
    }

    return () => {
      isMounted = false;
    };
  }, [produitId]);

  return (
    <Box>
      {slots && (
        <GroupBody>
          <Slots datas={slots} espaceId={espaceId} produitId={produitId} />
        </GroupBody>
      )}
    </Box>
  );
};

export default DataProduitSlots;
