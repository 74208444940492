import { DataFieldArrayProps, FieldArray } from '@innedit/formidable';
import React, { FC } from 'react';

import DataHyperlinksRender from './Render';

export interface DataHyperLinkProps
  extends Omit<DataFieldArrayProps, 'componentType'> {
  formName: string;
}

const DataHyperlinks: FC<DataHyperLinkProps> = ({ name, ...others }) => (
  <FieldArray component={DataHyperlinksRender} name={name} props={others} />
);

export default DataHyperlinks;
