import { Dispatch } from '@innedit/formidable';
import { EspaceData } from '@innedit/innedit';

import { USER_SET_ESPACES } from '../../constants/actionTypes2';

function loadEspaces(): any {
  return async (dispatch: Dispatch): Promise<any> => {
    const espaceData = new EspaceData({
      orderField: 'name',
    });

    const espaces = await espaceData.find();

    return dispatch({ espaces, type: USER_SET_ESPACES });
  };
}

export default loadEspaces;
