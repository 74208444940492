import { DataProps } from '@innedit/formidable';
import React, { FC } from 'react';

import DataCommandesResume from './Commandes/Resume';
// import CommandeNumero from '../containers/Commandes/components/Data/Numero';
// import CommandePayments from '../containers/Commandes/components/Data/Payments';
import DataCommandesSelect from './Commandes/Select';
// import CommandeTabsClient from '../containers/Commandes/components/Data/Tabs/Client';
// import CommandeTabsOptions from '../containers/Commandes/components/Data/Tabs/Options';
// import CommandeAcompte from '../containers/Commandes/components/Data/Validation/Acompte';
// import CommandeValidationAutomatic from '../containers/Commandes/components/Data/Validation/Automatic';
// import ContactFiltre from '../containers/Contacts/components/Data/Filtre';
import DataPhotos from './Photos';
// import ProduitAlgolia from '../containers/Produits/components/Data/Algolia';
import ProduitCaracteristiques from './Produits/Caracteristiques';
import ProduitSku from './Produits/Sku';

export interface DataBoutiquesProps extends DataProps {
  espaceId?: string;
  collectionName?: string;
  datas?: DataProps | DataProps[];
  formName: string;
  type: string;
}
const DataBoutiques: FC<DataBoutiquesProps> = props => {
  const { espaceId, name, type } = props;

  switch (type) {
    // case 'commande/numero':
    //   return <CommandeNumero {...props} />;
    //
    case 'commandes/resume': {
      return <DataCommandesResume {...props} />;
    }

    case 'commandes/select': {
      if (!espaceId) {
        return (
          <div>{type}: erreur de paramètre : espaceId est obligatoire</div>
        );
      }

      if (!name) {
        return <div>{type}: erreur de paramètre : name est obligatoire</div>;
      }

      return <DataCommandesSelect {...props} espaceId={espaceId} name={name} />;
    }
    // case 'commande/paiements':
    //   return <CommandePayments {...props} />;
    //
    // case 'commande/acompte':
    //   return <CommandeAcompte {...props} />;
    //
    // case 'commande/validation/automatic':
    //   return <CommandeValidationAutomatic {...props} />;
    //
    // case 'commande/tabs/options':
    //   return <CommandeTabsOptions {...props} />;

    case 'photos': {
      return <DataPhotos {...props} />;
    }

    // case 'produit/list':
    //   return <ProduitList {...props} />;

    // case 'produit/algolia':
    //   return <ProduitAlgolia {...props} />;
    //

    case 'produit/caracteristiques':
      if (!espaceId) {
        return (
          <div>{type}: erreur de paramètre : espaceId est obligatoire</div>
        );
      }

      if (!name) {
        return <div>{type}: erreur de paramètre : name est obligatoire</div>;
      }

      return (
        <ProduitCaracteristiques {...props} espaceId={espaceId} name={name} />
      );

    case 'produit/sku':
      if (!espaceId) {
        return (
          <div>{type}: erreur de paramètre : espaceId est obligatoire</div>
        );
      }

      if (!name) {
        return <div>{type}: erreur de paramètre : name est obligatoire</div>;
      }

      return (
        <ProduitSku
          {...props}
          componentType="produit/sku"
          espaceId={espaceId}
          name={name}
        />
      );

    default:
  }

  return <div>{`boutique : ce type de composant n'existe pas :  ${type}`}</div>;
};

export default DataBoutiques;
