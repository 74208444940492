import {
  change,
  DataField,
  DataFieldProps,
  Field,
  useDispatch,
} from '@innedit/formidable';
import React, { FC, SyntheticEvent } from 'react';
import slug from 'slug';

export interface DataSchemasDatasLabelProps
  extends Omit<DataFieldProps, 'componentType'> {
  formName: string;
}

const DataSchemasDatasLabel: FC<DataSchemasDatasLabelProps> = ({
  className,
  formName,
  name,
}) => {
  const dispatch = useDispatch();
  const handleOnChange = ({
    event,
    name: _name,
  }: {
    event?: SyntheticEvent<HTMLInputElement>;
    name?: string;
  }) => {
    const prefix = _name && _name.slice(0, -5);
    dispatch(
      change(formName, `${prefix}name`, slug(event?.currentTarget.value || '')),
    );
  };

  return (
    <>
      <Field component="input" disabled name="name" type="hidden" />
      <DataField
        className={className}
        componentType="input"
        handleOnChange={handleOnChange}
        label="Label"
        name={name}
        required
      />
    </>
  );
};

export default DataSchemasDatasLabel;
