import { DataProps, Group } from '@innedit/formidable';
import { PaiementData } from '@innedit/innedit';
import { DocumentType, PaiementType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';

import ItemRequiresCapture from './RequiresCapture';
import RequiresPaymentMethod from './RequiresPaymentMethod';
import ItemSucceeded from './Succeeded';

export interface DataPaiementManagerProps
  extends Omit<DataProps, 'componentType'> {
  espaceId: string;
  formName: string;
}

const DataPaiementManager: FC<DataPaiementManagerProps> = ({
  display,
  formName,
  params,
  espaceId,
}) => {
  const [paiement, setPaiement] = useState<DocumentType<PaiementType>>();

  const item = params && params[formName];

  useEffect(() => {
    let isMounted = true;
    if (item?.paiementId) {
      const paiementData = new PaiementData({
        espaceId,
      });

      paiementData.watchById(item.paiementId, doc => {
        if (isMounted) {
          setPaiement(doc);
        }

        return isMounted;
      });

      // let url = `espaces/${espaceId}/payment_intents/retrieve?id=${item.paiementId}`;
      // fetch(`${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/${url}`)
      //   .then(r => r.json())
      //   .then(r => {
      //     const [{ balance_transaction }] = r.charges.data;
      //
      //     if (balance_transaction) {
      //
      //       url = `espaces/${espaceId}/balance_transactions/retrieve?id=${balance_transaction}`;
      //
      //       return fetch(`${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/${url}`);
      //     }
      //
      //     return null;
      //   })
      //   .then(r => r && r.json())
      //   .catch(toast.error);
    }

    return () => {
      isMounted = false;
    };
  }, [item?.paiementId]);

  if (!paiement) {
    return null;
  }

  return (
    <Group display={display} title="Paiement">
      <dl className="grid grid-cols-3">
        {paiement.amountCapturable > 0 && (
          <>
            <dt className="col-span-2">Montant à valider</dt>
            <dd className="text-right">{paiement.amountCapturable} €</dd>
          </>
        )}
        {paiement.amountReceived > 0 && (
          <>
            <dt className="col-span-2">Montant reçu</dt>
            <dd className="text-right">{paiement.amountReceived} €</dd>
          </>
        )}

        {paiement.amountRefunded > 0 && (
          <>
            <dt className="col-span-2">Montant remboursé</dt>
            <dd className="text-right">{paiement.amountRefunded} €</dd>
          </>
        )}
      </dl>
      {!['requires_capture', 'requires_payment_method'].includes(
        paiement.status,
      ) && <ItemSucceeded espaceId={espaceId} paiement={paiement} />}
      {'requires_capture' === paiement.status &&
        'manual' === paiement.captureMethod &&
        paiement.amountCapturable > 0 && (
          <ItemRequiresCapture espaceId={espaceId} paiement={paiement} />
        )}
      {'requires_payment_method' === paiement.status && (
        <RequiresPaymentMethod espaceId={espaceId} paiement={paiement} />
      )}
    </Group>
  );
};

export default DataPaiementManager;
