import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

import font from '../../../styles/font';
import { ButtonSizeType } from '../props';

const ButtonText = styled.span``;

const getButtonSizeProps = ({
  size,
  square,
}: {
  size?: ButtonSizeType;
  square?: boolean;
}): any => {
  switch (size) {
    case 'xs':
      return css`
        font-size: ${font.size.xs[0]};
        min-height: ${rem(24)};
        padding: ${rem(4)} ${square ? 0 : rem(10)};
        min-width: ${square ? rem(24) : 'initial'};
        > * + * {
          margin-left: ${rem(6)};
        }
        svg {
          height: ${rem(10)};
          width: ${rem(10)};
        }
      `;

    case 'sm':
      return css`
        font-size: ${font.size.xs[0]};
        min-height: ${rem(32)};
        padding: ${rem(4)} ${square ? 0 : rem(12)};
        min-width: ${square ? rem(32) : 'initial'};
        > * + * {
          margin-left: ${rem(6)};
        }
        svg {
          height: ${rem(14)};
          width: ${rem(14)};
        }
      `;

    case 'md':
      return css`
        font-size: ${font.size.xs[0]};
        min-height: ${rem(36)};
        padding: ${rem(4)} ${square ? 0 : rem(12)};
        min-width: ${square ? rem(36) : 'initial'};
        > * + * {
          margin-left: ${rem(6)};
        }
        svg {
          height: ${rem(14)};
          width: ${rem(14)};
        }
      `;

    case 'lg':
      return css`
        min-height: ${rem(48)};
        padding: ${rem(8)} ${square ? 0 : rem(24)};
        min-width: ${square ? rem(48) : 'initial'};
        ${ButtonText} {
          min-width: ${'initial'};
        }
        > * + * {
          margin-left: ${rem(12)};
        }
        svg {
          height: ${rem(16)};
          width: ${rem(16)};
        }
      `;

    default:
      return css`
        font-size: ${font.size.sm[0]};
        ${font.size.xs[1]};
        min-height: ${rem(40)};
        padding: ${rem(11)} ${square ? 0 : rem(16)};
        min-width: ${square ? rem(40) : 'initial'};

        ${ButtonText} {
          min-width: ${'initial'};
        }
        > * + * {
          margin-left: ${rem(12)};
        }
      `;
  }
};

export default getButtonSizeProps;
