import { FieldArrayFieldsProps } from '@innedit/formidable';
import { CommandeProduitType } from '@innedit/innedit-type';
import React, { FC } from 'react';

import ItemEditable from './Editable';
import ItemNoEditable from './NoEditable';

const Item: FC<{
  editable: boolean;
  espaceId: string;
  index: number;
  name: string;
  produits: FieldArrayFieldsProps<CommandeProduitType>;
}> = ({ editable, espaceId, index, name, produits }) => {
  const item = produits.get(index);

  if (editable) {
    return <ItemEditable name={name} />;
  }

  return <ItemNoEditable espaceId={espaceId} item={item} />;
};

export default Item;
