import { DocumentType, PaiementType } from '@innedit/innedit-type';
import React, { FC, SyntheticEvent, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '../../../components/Button';

interface ItemRequiresCaptureProps {
  espaceId: string;
  paiement: DocumentType<PaiementType>;
}
const ItemRequiresCapture: FC<ItemRequiresCaptureProps> = ({
  espaceId,
  paiement,
}) => {
  const [amount, setAmount] = useState<number>(0);
  const [selectedItems, setSelectedItems] = useState<boolean[]>(
    paiement.items ? paiement.items.map(() => false) : [],
  );

  const handleOnChangeItem = (event: SyntheticEvent<HTMLInputElement>) => {
    if (undefined !== event.currentTarget.getAttribute('data-index')) {
      const index = parseInt(
        event.currentTarget.getAttribute('data-index') as string,
        10,
      );
      const newSelectedItems = [...selectedItems];
      newSelectedItems[index] = !newSelectedItems[index];

      setAmount(
        newSelectedItems.reduce((acc, currentValue, currentIndex) => {
          let a = 0;

          if (paiement.items) {
            a =
              paiement.items[currentIndex].quantity *
              paiement.items[currentIndex].price;
          }

          return acc + (currentValue ? a : 0);
        }, 0),
      );
      setSelectedItems(newSelectedItems);
    }
  };

  const handleCaptureOnSubmit = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const indexes = selectedItems
      .reduce((acc, value, index) => {
        if (value) {
          acc.push(index);
        }

        return acc;
      }, [] as number[])
      .join(',');
    const url = `espaces/${espaceId}/payment_intents/capture?paiement=${paiement.id}&amount=${amount}&items=${indexes}`;

    fetch(`${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/${url}`)
      .then(r => r.json())
      .catch(toast.error);
  };

  const handleCancelOnSubmit = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const url = `espaces/${espaceId}/payment_intents/cancel?paiement=${paiement.id}`;

    fetch(`${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/${url}`)
      .then(r => r.json())
      .catch(toast.error);
  };

  return (
    <div className="mt-6">
      {paiement.items?.map((item, index) => (
        <div key={item.id} className="flex space-x-3 items-center">
          <input
            checked={selectedItems[index]}
            data-index={index}
            onChange={handleOnChangeItem}
            type="checkbox"
          />
          {item.thumbnail && (
            <img alt={item.name} src={item.thumbnail} width={30} />
          )}
          <span className="truncate text-ellipsis overflow-hidden">
            {item.name}
          </span>
          <span className="whitespace-nowrap">{item.price} €</span>
        </div>
      ))}
      <div className="mt-6 flex">
        {amount > 0 ? (
          <Button
            className="flex-1"
            color="neutral"
            onClick={handleCaptureOnSubmit}
            text={`Capturer ${amount} €`}
            variant="groove"
          />
        ) : (
          <Button
            className="flex-1"
            color="danger"
            onClick={handleCancelOnSubmit}
            text="Annuler le paiement"
            variant="groove"
          />
        )}
      </div>
    </div>
  );
};

export default ItemRequiresCapture;
