import { WrappedFieldArrayProps } from '@innedit/formidable';
import React, { FC, SyntheticEvent } from 'react';

import HOCGroup from '../../../components/Group/HOC';
import { DataEspacesListProps } from './index';
import Item from './Item';

const DataListRender: FC<WrappedFieldArrayProps & DataEspacesListProps> = ({
  fields,
  formName,
  title,
}) => {
  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    fields.push('');
  };

  const handleRemoveOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const index = parseInt(
      String(event.currentTarget.getAttribute('data-index')),
      10,
    );

    if (undefined !== index && !Number.isNaN(index)) {
      fields.remove(index);
    }
  };

  return (
    <HOCGroup addOnClick={handleAddOnClick} title={title}>
      {fields.length > 0 ? (
        fields.map((field, idx) => (
          <Item
            key={field}
            formName={formName}
            index={idx}
            name={field}
            removeOnClick={handleRemoveOnClick}
          />
        ))
      ) : (
        <div style={{ margin: '1.5rem' }}>Aucune espace</div>
      )}
    </HOCGroup>
  );
};

export default DataListRender;
