import { WrappedFieldArrayProps } from '@innedit/formidable';
import { CommandeProduitType } from '@innedit/innedit-type';
import React, { SyntheticEvent, VFC } from 'react';

import HOCGroup from '../../../components/Group/HOC';
import { DataCommandesProduitsProps } from './index';
import Produit from './Item';

const DataCommandesProduitsRender: VFC<
  WrappedFieldArrayProps & DataCommandesProduitsProps
> = ({ display, espaceId, fields, formName, title, params }) => {
  const values = params && params[formName];

  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const newItem: Partial<CommandeProduitType> = {
      quantity: 1,
    };
    fields.push(newItem);
  };

  const editable = !values?.paymentStatus;

  return (
    <HOCGroup
      addOnClick={editable ? handleAddOnClick : undefined}
      display={display}
      title={title}
    >
      {fields.length > 0 ? (
        <div>
          <div className="grid grid-cols-4 gap-3">
            <div className="col-span-2">Description</div>
            <div>Quantité</div>
            <div>Prix unitaire</div>
          </div>
          <div className="flex flex-col space-y-1">
            {fields.map((name, fieldIndex, allfields) => (
              <Produit
                key={name}
                editable={editable}
                espaceId={espaceId}
                index={fieldIndex}
                name={name}
                produits={allfields}
              />
            ))}
          </div>
        </div>
      ) : (
        <p>Aucun produit</p>
      )}
    </HOCGroup>
  );
};

export default DataCommandesProduitsRender;
