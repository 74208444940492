import { DataField, DataFieldProps } from '@innedit/formidable';
import { CollectionData, DocumentData, ModelData } from '@innedit/innedit';
import { CollectionType, DocumentType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export interface DataDocumentsSelectProps
  extends Omit<DataFieldProps, 'componentType'> {
  collectionId: string;
  espaceId: string;
}

const DataDocumentsSelect: FC<DataDocumentsSelectProps> = ({
  collectionId,
  ...props
}) => {
  const [collection, setCollection] = useState<DocumentType<CollectionType>>();

  const [model, setModel] = useState<ModelData<any>>();
  const [options, setOptions] = useState<DocumentType<any>[]>();

  const collectionData = new CollectionData({ espaceId: props.espaceId });

  useEffect(() => {
    collectionData
      .findById(collectionId)
      .then(document => {
        if (document) {
          const newModel = new DocumentData({
            ...document,
            collectionName: 'documents',
            espaceId: props.espaceId,
            parentCollectionName: 'collections',
            parentId: collectionId,
          });

          setCollection(document);
          setModel(newModel);
        }

        return true;
      })
      .catch(toast.error);
  }, [collectionId]);

  useEffect(() => {
    let isMounted = true;
    if (model) {
      model
        .find()
        .then(documents => {
          if (isMounted) {
            setOptions(documents);
          }

          return false;
        })
        .catch(toast.error);
    }

    return () => {
      isMounted = false;
    };
  }, [model]);

  if (!options) {
    return null;
  }

  return (
    <DataField
      {...props}
      componentType="select"
      options={options.map(option => ({
        label:
          collection && collection.itemFieldTitle
            ? option[collection.itemFieldTitle]
            : option.name,
        value: option.id,
      }))}
    />
  );
};

export default DataDocumentsSelect;
