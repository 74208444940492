import { Link } from 'gatsby';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Date from './Date';
import Default from './Default';
import { ButtonProps } from './props';
import Tooltip from './Tooltip';

export { default as ButtonEL } from './styles';

const Button: FC<ButtonProps> = ({
  children,
  className,
  id,
  link,
  text,
  tooltip,
  type,
  value,
  ...others
}) => {
  const { t } = useTranslation();

  switch (type) {
    case 'tooltip': {
      if (!tooltip) {
        return <div>tooltip obligatoire</div>;
      }

      return (
        <Tooltip
          {...others}
          className={className}
          clipboard
          text={text}
          value={tooltip}
        >
          {children}
        </Tooltip>
      );
    }

    case 'date':
      return (
        <Date {...others} className={className} text={text} value={value}>
          {children}
        </Date>
      );

    case 'link':
      if (link) {
        return (
          <Link className={className} to={link}>
            {children}
            {text && <span>{t(text)}</span>}
          </Link>
        );
      }

      return <div>No link</div>;

    default:
      return (
        <Default {...others} className={className} text={text}>
          {children}
        </Default>
      );
  }
};

export default Button;

export type { ButtonProps };
