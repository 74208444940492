import { FieldArray, WrappedFieldArrayProps } from '@innedit/formidable';
import React, { FC } from 'react';

import Photos, { PhotoProps } from '../components/Photos';

const DataPhotosRender: FC<
  WrappedFieldArrayProps & {
    espaceId: string;
    docType: string;
    formName: string;
    id: string;
  }
> = ({ espaceId, fields, formName, docType, id }) => {
  const handleChangePosition = (oldIndex: number, newIndex: number): void => {
    const photo = fields.get(oldIndex);
    if (newIndex !== oldIndex) {
      if (newIndex < oldIndex) {
        fields.insert(newIndex, photo);
        fields.remove(oldIndex + 1);
      } else {
        // la nouvelle position est après
        fields.remove(oldIndex);
        fields.insert(newIndex, photo);
      }
    }
  };

  const handleRemoveItem = (index: number): void => {
    fields.remove(index);
  };

  const items: PhotoProps[] =
    fields.length > 0
      ? fields.getAll().map(field => ({ fullPath: field }))
      : [];

  if (0 === items.length) {
    return null;
  }

  return (
    <Photos
      espaceId={espaceId}
      formName={formName}
      handleChangePosition={handleChangePosition}
      handleRemoveItem={handleRemoveItem}
      items={items}
      title="Photos"
    />
  );
};

const DataPhotos: FC<any> = props => (
  <FieldArray {...props} component={DataPhotosRender} />
);

export default DataPhotos;
