import './src/styles/base.css';
import 'dayjs/locale/fr';

import { FormidableProvider } from '@innedit/formidable';
import { UserData } from '@innedit/innedit';
import { InneditProvider } from '@innedit/innedit-react';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { Translation as TranslationI18N } from 'react-i18next';

import { isMobile, isWebapp } from './src/actions/actions';
import loadEspace from './src/actions/espace/load';
import { isLogout, load } from './src/actions/user';
import Button from './src/components/Button';
import { FieldMessage, FieldTemplate } from './src/components/Field';
import Group, {
  GroupBody,
  GroupDescription,
  GroupTitle,
} from './src/components/Group';
import Input, { InputGroup } from './src/components/Input';
import Select from './src/components/Select';
import { TabsBar, TabsBarItem } from './src/components/Tabs';
import Textarea from './src/components/Textarea';
import Translation, {
  TranslationItem,
  TranslationItemLang,
} from './src/components/Translation';
import extendData from './src/datas';
import i18n from './src/i18n';
import reducers from './src/reducers';
import theme from './src/styles/theme';
import { mobilecheck } from './src/utils/functions';

let isMobileScreen = false;
let isWebappScreen = false;
if (typeof document !== `undefined` && typeof window !== `undefined`) {
  isMobileScreen = mobilecheck();
  const viewport = document.querySelector('meta[name=viewport]');
  if (viewport) {
    viewport.setAttribute(
      'content',
      'width=device-width, height=device-height, initial-scale=1, initial-scale=1',
    );
  }

  if (isMobileScreen) {
    document.documentElement.classList.remove('desktop');
    document.documentElement.classList.add('mobile');
  } else {
    document.documentElement.classList.add('desktop');
    document.documentElement.classList.remove('mobile');
  }

  isWebappScreen = !!window.navigator.standalone;
  if (isWebappScreen) {
    document.documentElement.classList.add('webapp');
  } else {
    document.documentElement.classList.remove('webapp');
  }
}

let defaultStore;
const initializeStore = store => {
  defaultStore = store;
  store.dispatch(isMobile(isMobileScreen));
  store.dispatch(isWebapp(isWebappScreen));

  if (typeof document !== `undefined` && typeof window !== `undefined`) {
    const [p1, espaceId] = window.location.pathname.substring(1).split('/');
    UserData.onChange(user => {
      if (user) {
        store.dispatch(load(user, 'espaces' === p1 ? espaceId : undefined));
      } else {
        store.dispatch(isLogout());
      }
    });
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  const [template, espaceId] = location.pathname.slice(1).split('/');
  if ('espaces' === template) {
    // C'est une page espace
    let prevEspaceId;
    let prevTemplate;
    if (prevLocation) {
      [prevTemplate, prevEspaceId] = prevLocation.pathname.slice(1).split('/');
    }

    if (
      defaultStore &&
      (!prevTemplate || 'espaces' !== prevTemplate || espaceId !== prevEspaceId)
    ) {
      // la page d'avant n'était pas de cet espace
      defaultStore.dispatch(loadEspace(espaceId));
    }
  }
};

const WrapRootElement = ({ element }) => {
  // process.env.BROWSER && window.State ? window.State.redux : {};

  if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
    window.navigator.serviceWorker
      .getRegistrations()
      .then(registrations => registrations.forEach(r => r.unregister()))
      .catch(error => {
        console.error(error);
      });
  }

  return (
    <InneditProvider>
      <DndProvider backend={isMobileScreen ? TouchBackend : HTML5Backend}>
        <TranslationI18N i18n={i18n}>
          {t => (
            <FormidableProvider
              extendData={extendData}
              extraReducers={reducers}
              initializeStore={initializeStore}
              sc={{
                button: Button,
                fieldMessage: FieldMessage,
                fieldTemplate: FieldTemplate,
                group: Group,
                groupBody: GroupBody,
                groupDescription: GroupDescription,
                groupTitle: GroupTitle,
                input: Input,
                inputGroup: InputGroup,
                select: Select,
                tabsBar: TabsBar,
                tabsBarItem: TabsBarItem,
                textarea: Textarea,
                translation: Translation,
                translationItem: TranslationItem,
                translationItemLang: TranslationItemLang,
              }}
              t={t}
              theme={{
                ...theme,
                mobile: isMobileScreen,
                webapp: isWebappScreen,
              }}
            >
              {element}
            </FormidableProvider>
          )}
        </TranslationI18N>
      </DndProvider>
    </InneditProvider>
  );
};

export const wrapRootElement = WrapRootElement;
