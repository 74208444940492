import { DataFieldArrayProps, FieldArray } from '@innedit/formidable';
import React, { FC } from 'react';

import DataListRender from './Render';

export interface DataEspacesListProps
  extends Omit<DataFieldArrayProps, 'componentType'> {
  formName: string;
  name: string;
  title: string;
}

const DataEspacesList: FC<DataEspacesListProps> = props => {
  const { name } = props;

  return <FieldArray component={DataListRender} name={name} props={props} />;
};

export default DataEspacesList;
