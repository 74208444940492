import { Box, DataProps } from '@innedit/formidable';
import { ProduitData } from '@innedit/innedit';
import { DocumentType, ProduitType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';

import Button from '../../../components/Button';
import { GroupBody } from '../../../components/Group';
import Media from '../../../components/Media';

export interface DataProduitParentProps
  extends Omit<DataProps, 'componentType'> {
  espaceId: string;
  formName: string;
  produitId: string;
}

const DataProduitParent: FC<DataProduitParentProps> = ({
  espaceId,
  formName,
  produitId,
}) => {
  const [produit, setProduit] = useState<DocumentType<ProduitType>>();

  useEffect(() => {
    let isMounted = true;
    if (produitId) {
      const produitData = new ProduitData({
        espaceId,
      });

      produitData.watchById(produitId, newDocument => {
        if (isMounted) {
          setProduit(newDocument);
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [produitId]);

  return (
    <Box>
      {produit && (
        <GroupBody className="bg-light-500">
          <div className="grid grid-cols-3 gap-3">
            {produit.mediaIds && (
              <div>
                <Media
                  className="rounded"
                  espaceId={espaceId}
                  id={produit.mediaIds[0]}
                  options="w=65&h=65"
                />
              </div>
            )}

            <div className="col-span-2">
              <h2 className="text-md">{produit.name}</h2>
              <Button
                to={`/espaces/${espaceId}/produits/${produitId}/update/`}
                variant="link"
              >
                Retour au produit
              </Button>
            </div>
          </div>
        </GroupBody>
      )}
    </Box>
  );
};

export default DataProduitParent;
