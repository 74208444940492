import { DataProps } from '@innedit/formidable';
import { ContentData } from '@innedit/innedit';
import { ContentType, DocumentType } from '@innedit/innedit-type';
import { navigate } from 'gatsby';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import HOCGroup from '../../../components/Group/HOC';
import Item from './Item';

export interface DataContentsListProps
  extends Omit<DataProps, 'componentType'> {
  editPathname: string;
  espaceId: string;
  display?: 'inside';
  parentCollectionName?: string;
  parentId?: string;
  title?: string;
}

const DataContentsList: FC<DataContentsListProps> = ({
  editPathname,
  espaceId,
  display,
  parentCollectionName,
  parentId,
  title,
}) => {
  const [contents, setContents] = useState<DocumentType<ContentType>[]>();

  const contentData = new ContentData({
    espaceId,
    parentCollectionName,
    parentId,
  });

  useEffect(() => {
    let isMounted = true;
    let unsub: (() => void) | undefined;

    if (parentId) {
      contentData.watch(newDocs => {
        if (isMounted) {
          setContents(newDocs);
        }
      });
    } else {
      setContents([]);
    }

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [parentId]);

  const handleAddOnClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (parentId) {
      contentData
        .create({
          medias: [],
          order: contents ? contents.length : 0,
        })
        .then(content => navigate(`${editPathname}${content.id}/`))
        .catch(console.error);
    }
  };

  const handleEditOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    const id = event.currentTarget.getAttribute('data-id');
    if (id) {
      await navigate(`${editPathname}${id}/`);
    }
  };

  const handleUpOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const i = parseInt(
      String(event.currentTarget.getAttribute('data-index')),
      10,
    );

    if (parentId && undefined !== i && contents && i > 0) {
      // On inverse le index et le index-1
      const contentMinus = contents[i - 1];
      contentData.update(contents[i].id, {
        order: i - 1,
      });
      contentData.update(contentMinus.id, {
        order: i,
      });
    }
  };

  const handleDownOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const i = parseInt(
      String(event.currentTarget.getAttribute('data-index')),
      10,
    );
    if (parentId && contents && i < contents.length - 1) {
      // On inverse le index et le index-1
      const contentPlus = contents[i + 1];
      contentData.update(contents[i].id, {
        order: i + 1,
      });
      contentData.update(contentPlus.id, {
        order: i,
      });
    }
  };

  const handleDeleteOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const i = parseInt(
      String(event.currentTarget.getAttribute('data-index')),
      10,
    );

    if (
      parentId &&
      Number.isInteger(i) &&
      contents &&
      window.confirm('Confirmation de la suppression du contenu')
    ) {
      const document = contents[i];
      contentData.delete(document.id);
    }
  };

  if (!parentId) {
    return null;
  }

  return (
    <HOCGroup addOnClick={handleAddOnClick} display={display} title={title}>
      {!contents && <p>Chargement en cours</p>}

      {contents && 0 === contents.length && <p>Aucun contenu</p>}

      {contents && 0 < contents.length && (
        <>
          {contents.map((content, idx) => (
            <Item
              key={content.id}
              deleteOnClick={handleDeleteOnClick}
              document={content}
              downOnClick={handleDownOnClick}
              editOnClick={handleEditOnClick}
              espaceId={espaceId}
              index={idx}
              upOnClick={handleUpOnClick}
            />
          ))}
        </>
      )}
    </HOCGroup>
  );
};

export default DataContentsList;
