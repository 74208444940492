import { DataSection } from '@innedit/formidable';
import React, { FC, SyntheticEvent } from 'react';

import Button from '../../../components/Button';
import IconDelete from '../../../icons/Delete';
import tier from '../../../params/produit/price/tier.json';

const PricesTiersIem: FC<{
  index: number;
  name: string;
  removeOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
}> = ({ index, name, removeOnClick }) => (
  <div className="flex items-end">
    <span className="flex-1">
      <DataSection datas={tier} name={name} />
    </span>
    <Button
      className="rounded-l-none"
      color="neutral"
      data-index={index}
      iconLeft={IconDelete}
      onClick={removeOnClick}
      variant="field"
    />
  </div>
);

export default PricesTiersIem;
