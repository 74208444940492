import { parse, stringify } from 'query-string';

interface ActionType {
  name: string;
  value?: string;
}

interface PathnameProps {
  to?: string;
  actions?: ActionType[];
}

const pathname = ({ actions, to }: PathnameProps): string => {
  if ('undefined' !== typeof window && actions && actions.length > 0) {
    const params = parse(window.location.search);

    actions.forEach(({ name, value }) => {
      if (value) {
        params[name] = value as string;
      } else if (params[name]) {
        delete params[name];
      }
    });

    return `${to ?? './'}?${stringify(params)}`;
  }

  return to ?? '.';
};

export default pathname;
