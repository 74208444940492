import { change, DataProps, useDispatch } from '@innedit/formidable';
import { FeatureData } from '@innedit/innedit';
import { DocumentType, PriceType } from '@innedit/innedit-type';
import { navigate } from 'gatsby';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import Button from '../../../components/Button';
import HOCGroup from '../../../components/Group/HOC';
import IconAdd from '../../../icons/Add';
import price from '../../../params/produit/price/index.json';
import PricesDefault from './Default';
import PricesList from './List';

export interface DataPricesCrudProps extends Omit<DataProps, 'componentType'> {
  espaceId: string;
  display?: 'inside' | 'group';
  docCollectionName: string;
  docId: string;
  editPathname: string;
  formName: string;
  title?: string;
}

const DataPricesCrud: FC<DataPricesCrudProps> = ({
  espaceId,
  display,
  docCollectionName,
  docId,
  editPathname,
  formName,
  params,
  title,
}) => {
  const dispatch = useDispatch();

  const [docs, setDocs] = useState<DocumentType<PriceType>[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const hasSavedPrices = docs && docs.length > 0;

  const hasInitialPrices = Boolean(
    params && params[formName] && params[formName].prices,
  );

  useEffect(() => {
    if (hasInitialPrices && hasSavedPrices) {
      dispatch(change(formName, 'prices', undefined));
    }
  }, [hasInitialPrices, hasSavedPrices]);

  useEffect(() => {
    let isMounted = true;
    let unsub: any;

    if (docCollectionName && docId) {
      setIsLoading(true);
      const featureData = new FeatureData<PriceType>({
        espaceId,
        collectionName: 'prices',
        orderDirection: 'desc',
        orderField: 'datetime',
        parentCollectionName: docCollectionName,
        parentId: docId,
      });

      unsub = featureData.watch(newDocs => {
        if (isMounted) {
          setDocs(newDocs);
          setIsLoading(false);
        }
      }, {});
    }

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [espaceId, docCollectionName, docId]);

  const handleAddOnClick = async (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const model = new FeatureData<PriceType>({
      espaceId,
      collectionName: 'prices',
      params: price,
      parentCollectionName: docCollectionName,
      parentId: docId,
    });

    const newDoc: Partial<PriceType> = model.initialize();

    const doc = await model.create(newDoc);

    if (doc) {
      await navigate(`${editPathname + doc.id}/`);
    }
  };

  const handleRemoveOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    const model = new FeatureData({
      espaceId,
      collectionName: 'prices',
      params: price,
      parentCollectionName: docCollectionName,
      parentId: docId,
    });

    const id = event.currentTarget.getAttribute('data-id');
    if (docs && id) {
      const doc = docs.find(d => d.id === id);
      if (doc) {
        await model.delete(doc.id);
      }
    }
  };

  // il faut vérifier la compatibilité entre les différents prix
  // il ne peut pas y avoir plusieurs prix avec le même tarif / type

  return (
    <HOCGroup
      customInfos={
        docId && (
          <div className="flex space-x-3 absolute min-h-0 right-0 top-0">
            <Button
              iconLeft={IconAdd}
              onClick={handleAddOnClick}
              variant="link"
            />
          </div>
        )
      }
      display={display}
      title={title || 'Prix'}
    >
      {isLoading && <p>Chargement en cours</p>}
      {(!docs || 0 === docs.length) && <PricesDefault espaceId={espaceId} />}
      {docs && docs.length > 0 && (
        <PricesList
          docs={docs}
          editPathname={editPathname}
          removeOnClick={handleRemoveOnClick}
        />
      )}
    </HOCGroup>
  );
};

export default DataPricesCrud;
