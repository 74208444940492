import styled from '@emotion/styled';

import clipboard from '../../images/font-awesome/regular/clipboard.svg';
import voir from '../../images/font-awesome/regular/eye.svg';
import supprimer from '../../images/font-awesome/regular/trash-alt.svg';
import rotate from '../../images/font-awesome/solid/redo.svg';
import screens from '../../styles/screens';

export const Medias = styled.div`
  width: 100%;
  display: block;
  margin-bottom: 1.5rem;

  .is-pulled-right {
    float: right;
    color: #4b71fc;
    font-size: 13px;
  }

  .is-pulled-right + .is-pulled-right {
    margin-right: 1.5rem;
  }

  .group {
    background: white;
    border: 1px solid rgba(228, 231, 237, 0.8);
    padding: 1.5rem;
    text-align: center;

    input {
      display: none;
    }

    .first-upload {
      margin: 1.5rem;
      img {
        width: 80px;
      }

      h4 {
        color: #d3dbe2;
        font-size: 14px;
      }
    }
  }

  .is-clearfix {
    &:after {
      clear: both;
      content: ' ';
      display: table;
    }
  }

  .dropzone-hide {
    display: none;
  }
  .dropzone-active {
    border: 3px dashed #00aeef;
  }

  .aspect-ratio {
    position: relative;
    display: block;
    background: #fafbfc;
    padding: 0;

    &:before {
      z-index: 1;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid rgba(195, 207, 216, 0.3);
    }

    &.isDragging:before {
      border: 1px solid red;
    }
  }

  .aspect-ratio--square {
    width: 100%;
    padding-bottom: 100%;
    flex-shrink: 0;
  }

  .aspect-ratio__content {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }

  .loading-in-progress {
    padding: 0 0 1.5rem 0;
    display: block;
    text-align: center;
  }
`;

export const List = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(5, 1fr);

  @media only screen and (max-width: ${screens.md}) {
    grid-gap: 0.75rem;
    grid-template-columns: repeat(2, 1fr);
  }

  > li {
    position: relative;
    box-sizing: border-box;

    &:first-of-type {
      grid-column: 1 / 3;
      grid-row: 1 / 3;
    }

    figure {
      margin: 0;
    }

    &.isOver .aspect-ratio:before {
      border: 1px solid green !important;
    }

    .hover-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      background: rgba(49, 55, 61, 0.75);

      opacity: 0;
      transition: opacity 0.1s ease-in-out;

      &:hover {
        opacity: 1;
      }

      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-bottom: 10px;

      button {
        flex: 1;
        background-color: #fff;
        mask-image: url(${supprimer});
        mask-repeat: no-repeat;
        mask-position: center;
        height: 18px;
        width: 18px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #9f9da4;
        //border: 1px solid #b7c6fe;
        text-decoration: none;
        margin-left: 0.375rem;
        span {
          display: none;
        }

        &:hover {
          background-color: #4b71fc;
        }

        &.clipboard {
          mask-image: url(${clipboard});
        }

        &.voir {
          mask-image: url(${voir});
        }

        &.rotate {
          mask-image: url(${rotate});
        }
      }
    }
  }

  .aspect-ratio {
    position: relative;
    display: block;
    background: #fafbfc;
    padding: 0;

    &:before {
      z-index: 1;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid rgba(195, 207, 216, 0.3);
    }

    &.isDragging:before {
      border: 1px solid red;
    }
  }

  .aspect-ratio--square {
    width: 100%;
    padding-bottom: 100%;
    flex-shrink: 0;
  }

  .aspect-ratio__content {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
`;
