import styled from '@emotion/styled';

import checkboxChecked from '../images/checkbox-checked.svg';
import flagEN from '../images/flag_en.png';
import radio from '../images/radio.svg';
import radioChecked from '../images/radio-checked.svg';

interface FieldProps {
  noBorderLeft?: boolean;
  noBorderRight?: boolean;
  noLeftRadius?: boolean;
  noRightRadius?: boolean;
  noMargin?: boolean;
  noMarginBottom?: boolean;
  noMarginLeft?: boolean;
  noMarginRight?: boolean;
  noMarginTop?: boolean;
}

export default styled.div<FieldProps>`
  height: 34px;
  position: relative;
  display: flex;
  margin-top: ${props => (props.noMarginTop || props.noMargin ? 0 : '30px')};
  margin-bottom: ${props =>
    props.noMarginBottom || props.noMargin ? 0 : '20px'};

  &.is-expanded {
    flex: 1;
  }

  margin-right: ${props =>
    props.noMarginRight || props.noMargin ? 0 : '1.5rem'};
  margin-left: ${props =>
    props.noMarginLeft || props.noMargin ? 0 : '1.5rem'};

  &.no-margin {
    margin: 0;
  }

  &.no-margin-top {
    margin-top: 0;
  }

  &.no-margin-left {
    margin-left: 0;
  }

  &.no-margin-right {
    margin-right: 0;
  }

  &.no-margin-bottom {
    margin-bottom: 0;
  }

  &.language {
    margin-left: 3rem;

    &:before {
      content: '';
      background-image: url(${flagEN});
      display: block;
      background-size: contain;
      background-position: center;
      height: 34px;
      width: 34px;
      margin-right: 0.375rem;
    }
  }

  > div:not(:first-of-type) {
    margin-left: 1.5rem;
  }

  .has-addons {
    display: flex;
    flex-direction: row;

    button {
      // border-radius: 4px !important;
    }
  }

  &.search {
    margin-top: 0;
    margin-bottom: 0;
  }

  &.textarea {
    height: auto;
  }

  &.information {
    height: auto;
    margin-top: 1.5rem;
    color: #afb4be;
    font-size: 80%;
    border-left: 1px solid #afb4be;
    padding-left: 0.75rem;
  }

  &.select {
    height: 34px;
    &:after {
      //@include arrow(#000);
      border: 1px solid #000;
      border-right: 0;
      border-top: 0;
      content: ' ';
      display: block;
      height: 0.5em;
      pointer-events: none;
      position: absolute;
      transform: rotate(-45deg);
      width: 0.5em;
      margin-top: -0.375em;
      right: 1.125em;
      top: 50%;
      z-index: 4;
    }

    select {
      width: 100%;
      height: 34px;
      padding-right: 36px;
    }
  }

  &.checkbox {
    min-height: 22px;
    height: auto;
    display: block;
    line-height: 22px;

    span {
      white-space: nowrap;
    }
  }

  &.radio {
    align-items: center;
    flex-wrap: wrap;
    min-height: 34px;
    height: fit-content;

    div.radio {
      margin-right: 1.5rem;
    }

    span {
      white-space: nowrap;
      display: inline-block;
      line-height: 34px;
    }

    label + label:not(.label) {
      margin-left: 0.75rem;
    }
  }

  > .label {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-weight: 300;
    line-height: 32px;
    // border-bottom: 1px solid #f0f5fa;
    transition: border-bottom-color 200ms ease-in-out;
    cursor: text;
    pointer-events: none;

    &.hide {
      color: transparent;
    }
  }

  > .label span {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    transform-origin: 0 50%;
    transition: transform 200ms ease-in-out;
    cursor: text;
    white-space: nowrap;

    // temporaire
    //transform: scale(0.68) translateY(-36px);
    transform: scale(0.8) translateY(-36px);
  }

  &.checkbox .label span,
  &.select .label span,
  input.is-focused + .label span,
  textarea.is-focused + .label span,
  .has-addons.is-focused + .label span,
  input:not(.is-empty) + .label span,
  textarea:not(.is-empty) + .label span,
  div:not(.is-empty) + .label span,
  .has-addons:not(.is-empty) + .label span {
    transform: scale(0.68) translateY(-36px);
    cursor: default;
  }

  input.is-focused + div.label,
  textarea.is-focused + div.label,
  div.is-focused {
    // border-bottom-color: #ddb884;
  }

  input[type='text'],
  input[type='number'],
  input[type='email'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='tel'],
  textarea {
    background: white;
    font-weight: 300;
    border: 1px solid #d7d6d9;
    border-radius: 2px;
    outline: none;
    cursor: text;
    display: block;
    width: 100%;
    line-height: 32px;
    padding: 0 10px;
    transition: opacity 200ms ease-in-out;
  }

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    border-radius: 2px;
    border: 1px solid #d7d6d9;
    background: white;
    padding: 0 10px;
    position: relative;
    cursor: pointer;
    display: block;
    outline: none;
    font-weight: 300;
  }

  &.no-left-radius {
    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='datetime-local'],
    input[type='date'],
    textarea,
    button,
    select {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  ${props => {
    if (props.noLeftRadius) {
      return {
        // eslint-disable-next-line max-len
        "button,input[type='text'],input[type='number'],input[type='email'],input[type='datetime-local'],input[type='date'],textarea,select":
          {
            borderBottomLeftRadius: '0 !important',
            borderTopLeftRadius: '0 !important',
          },
      };
    }

    return null;
  }};

  &.no-right-radius {
    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='datetime-local'],
    input[type='date'],
    textarea,
    select {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  ${props => {
    if (props.noRightRadius) {
      return {
        // eslint-disable-next-line max-len
        "input[type='text'],input[type='number'],input[type='email'],input[type='datetime-local'],input[type='date'],textarea,select":
          {
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
          },
      };
    }

    return null;
  }};

  &.no-border-right {
    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='datetime-local'],
    input[type='date'],
    textarea,
    select {
      border-right: none;
    }
  }

  ${props => {
    if (props.noBorderRight) {
      return {
        // eslint-disable-next-line max-len
        "input[type='text'],input[type='number'],input[type='email'],input[type='datetime-local'],input[type='date'],textarea,select":
          {
            borderRight: 'none',
          },
      };
    }

    return null;
  }};

  textarea {
    height: auto;
    resize: none;
    line-height: 24px;
    min-height: 96px;
    padding: 4px 10px;
  }

  input.is-empty:not(.is-focused),
  textarea.is-empty:not(.is-focused),
  div.is-empty:not(.is-focused) input {
    //opacity: 0;

    // temporaire
    opacity: 1;
  }

  input[type='checkbox'],
  input[type='radio'] {
    display: inline;
    //opacity: 0;
    position: absolute;
    z-index: -1;

    //temporaire
    opacity: 1;
  }

  input[type='radio'] + span:before {
    appearance: none;
    background: 0 0;
    background-size: 18px 18px !important;
    box-sizing: border-box;
    content: '';
    display: inline-block;
    height: 18px !important;
    margin-right: 10px;
    outline: 0;
    position: relative;
    top: -1px;
    vertical-align: middle;
    width: 18px !important;
    z-index: 1;
  }

  input[type='radio'] + span:before {
    background-color: #afb4be;
    background-image: url(${radio});
    background-repeat: no-repeat;
    background-position: 0 0;
    border-radius: 100%;
    transition: background 0.1s ease;
  }

  input[type='radio']:checked + span:before {
    background-image: url(${radioChecked});
    background-color: #4b71fc;
    transition: background 0.2s ease;
  }

  input[type='checkbox'] + span:before {
    appearance: none;
    background: 0 0;
    background-size: 18px 18px !important;
    box-sizing: border-box;
    content: '';
    display: inline-block;
    height: 18px !important;
    margin-right: 10px;
    outline: 0;
    position: relative;
    top: -1px;
    vertical-align: middle;
    width: 18px !important;
    z-index: 1;
  }

  input[type='checkbox'] + span:before {
    background: #fff !important;
    border: 1px solid #afb4be !important;
    border-radius: 2px !important;
    transition: all 0.1s ease;
  }

  input[type='checkbox']:checked + span:before {
    background-color: #4b71fc !important;
    background-image: url(${checkboxChecked}) !important;
    background-repeat: no-repeat !important;
    background-position: -4px -4px !important;
    border-color: #4b71fc !important;
    transition: all 0.2s ease;
  }

  &.orientation_vertical {
    flex-direction: column;
    height: auto;
    align-items: flex-start;
  }

  &.error {
    input,
    textarea {
      border-color: red;
    }
    input + div span,
    textarea + div span {
      color: red;
    }
  }

  > .error {
    position: absolute;
    width: auto;
    top: 0;
    right: 0;
    display: inline;
    color: red;
    line-height: 34px;
    margin-right: 0.75rem;

    span:not(.icon) {
      // display: none;
      margin-left: 0.75rem;
    }
  }

  .input-icon {
    line-height: 34px;
    padding: 0 0.375rem;
    color: #d7d6d9;
    border: 1px solid #d7d6d9;
    border-right: none;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
  }

  .input-icon + input {
    border-left: none;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`;
