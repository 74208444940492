import styled from '@emotion/styled';
import { MediaData } from '@innedit/innedit';
import { Markdown } from '@innedit/innedit-react';
import { ContentType, DocumentType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import Button from '../../../components/Button';
import ButtonGroup from '../../../components/Button/Group';
import IconArrowDown from '../../../icons/ArrowDown';
import IconArrowUp from '../../../icons/ArrowUp';
import IconDelete from '../../../icons/Delete';
import IconEdit from '../../../icons/Edit';
import { spacing } from '../../../styles/theme';

const ItemEL = styled.div`
  position: relative;

  & + & {
    margin-top: ${spacing[3]};
  }

  // .actions {
  //   display: none;
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   left: 0;
  //   bottom: 0;
  // }

  &:hover {
    .actions {
      display: flex;
    }
  }

  h2 {
    font-weight: bold;
    margin: ${spacing[12]} 0 ${spacing[6]} 0;

    &:first-of-type {
      margin-top: 0;
    }
  }

  p {
    text-align: justify;
  }

  p + p {
    margin-top: ${spacing[3]};
  }

  ul {
    list-style: disc;
    margin-left: ${spacing[6]};
    text-align: justify;
  }
`;

const Item: FC<{
  espaceId: string;
  deleteOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  document: DocumentType<ContentType>;
  downOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  editOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  index: number;
  upOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
}> = ({
  espaceId,
  deleteOnClick,
  document,
  downOnClick,
  editOnClick,
  index,
  upOnClick,
}) => {
  const [medias, setMedias] = useState<string[]>();
  useEffect(() => {
    let isMounted = true;
    const { mediaIds } = document;
    if (mediaIds) {
      const options = document.mediasOptions || 'w=320&h=320&c=max';
      const mediaData = new MediaData({ espaceId });
      mediaData
        .findByIds(mediaIds)
        .then(docs => {
          if (isMounted) {
            setMedias(
              docs.map(
                doc =>
                  `${String(process.env.GATSBY_IMAGES_DOMAIN_NAME)}/${
                    doc.fullPath
                  }?${options}`,
              ),
            );
          }

          return true;
        })
        .catch(console.error);
    }

    return () => {
      isMounted = false;
    };
  }, [espaceId, document]);

  const refs: { [key: string]: string[] } = {};
  if (medias) {
    refs.medias = medias;
  }

  return (
    <ItemEL className="flex flex-col min-h-12">
      {document.text && (
        <Markdown className="markdown" refs={refs} text={document.text} />
      )}
      <hr className="mt-2" />
      <div className="mt-3 flex justify-between text-light-700">
        <div className="flex space-x-3 text-xs">
          <span>{dayjs(document.createdAt).format('DD/MM/YYYY HH:mm')}</span>
          {document.mediaIds && <div>{document.mediaIds.length} médias</div>}
        </div>
        <div className="actions justify-center items-center">
          <ButtonGroup className=" rounded">
            <Button
              color="light"
              data-id={document.id}
              iconLeft={IconEdit}
              onClick={editOnClick}
              size="xs"
              variant="outline"
            />
            <Button
              color="light"
              data-index={index}
              iconLeft={IconArrowUp}
              onClick={upOnClick}
              size="xs"
              variant="outline"
            />
            <Button
              color="light"
              data-index={index}
              iconLeft={IconArrowDown}
              onClick={downOnClick}
              size="xs"
              variant="outline"
            />
            <Button
              color="light"
              data-index={index}
              iconLeft={IconDelete}
              onClick={deleteOnClick}
              size="xs"
              variant="outline"
            />
          </ButtonGroup>
        </div>
      </div>
    </ItemEL>
  );
};

export default Item;
