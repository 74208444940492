import cms, { ReducerCMSProps } from './cms';

export interface ReducersProps {
  cms: ReducerCMSProps;
  form: {
    [name: string]: {
      values: { [attribute: string]: any };
    };
  };
}

const reducers = {
  cms,
};

export default reducers;
