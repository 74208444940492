/* eslint-disable max-len */
import classnames from 'classnames';
import React, { FC } from 'react';

import { IconType } from '../../components/Icon';

const IconFileUpload: FC<IconType> = ({
  color,
  className,
  onClick,
  size = 16,
}) => (
  <svg
    className={classnames(className, {
      'cursor-pointer': onClick,
    })}
    height={size}
    onClick={onClick}
    viewBox="0 0 384 512"
    width={size}
  >
    <path
      d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm65.18 216.01H224v80c0 8.84-7.16 16-16 16h-32c-8.84 0-16-7.16-16-16v-80H94.82c-14.28 0-21.41-17.29-11.27-27.36l96.42-95.7c6.65-6.61 17.39-6.61 24.04 0l96.42 95.7c10.15 10.07 3.03 27.36-11.25 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default IconFileUpload;
