import { DataProps, useSelector } from '@innedit/formidable';
import React, { FC } from 'react';

import { ReducersProps } from '../../reducers';

export interface DataUtilityCounterProps
  extends Omit<DataProps, 'componentType'> {
  formName: string;
  field: string;
}

const DataUtilityCounter: FC<DataUtilityCounterProps> = ({
  field,
  formName,
}) => {
  const values = useSelector(
    (state: ReducersProps) => state.form[formName].values,
  );

  const value = values && values[field];

  if (undefined === value) {
    return null;
  }

  const nb = value.replace(/\n/g, ' ').trim().split(' ').length;

  return (
    <span className="text-xxs absolute -mt-5 right-6">
      {nb} mot{nb > 1 && 's'}
    </span>
  );
};

export default DataUtilityCounter;
