import { DataField, DataFieldProps, useSelector } from '@innedit/formidable';
import { DocumentType, EspaceType } from '@innedit/innedit-type';
import React, { FC } from 'react';

export type DataEspacesSelectProps = Omit<DataFieldProps, 'componentType'>;

const DataEspacesSelect: FC<DataEspacesSelectProps> = props => {
  const espaces: DocumentType<EspaceType>[] = useSelector(
    (state: any) => state.cms.espaces,
  );

  if (!espaces || 0 === espaces.length) {
    return null;
  }

  return (
    <DataField
      {...props}
      componentType="select"
      options={espaces.map(espace => ({
        label: espace.name,
        value: espace.id,
      }))}
    />
  );
};

export default DataEspacesSelect;
