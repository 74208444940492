export const throughData = (data: {
  [key: string]: any;
}): { [key: string]: { [key: string]: boolean } | boolean } => {
  const tmpData: { [key: string]: boolean } = {};

  if (data.datas) {
    data.datas.forEach((tmp: { [key: string]: any }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const result = throughData(tmp);
      Object.keys(result).forEach(temp => {
        // temp est un objet -> on doit iterer sur les key
        if ('object' === typeof temp) {
          Object.keys(temp).forEach(key => {
            tmpData[key] = temp[key];
          });
        } else {
          tmpData[temp] = result[temp] as boolean;
        }
      });
    });
  }

  if (data.name) {
    return {
      [data.name]:
        Object.keys(tmpData).length > 0 ? tmpData : true === data.required,
    };
  }

  return tmpData;
};

const requiredData = (datas: {
  [key: string]: any;
}): { [key: string]: any } => {
  const objet: { [key: string]: any } = {};

  const result = throughData(datas);
  Object.keys(result).forEach(temp => {
    if ('object' === typeof temp) {
      Object.keys(temp).forEach(key => {
        objet[key] = temp[key];
      });
    } else {
      objet[temp] = result[temp];
    }
  });

  return objet;
};

export default requiredData;
