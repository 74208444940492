import { DataFieldProps, FieldArray } from '@innedit/formidable';
import React, { FC } from 'react';

import ItemsRender from './Render';

export interface DataPaiementItemsProps
  extends Omit<DataFieldProps, 'componentType'> {
  espaceId: string;
  formName: string;
}

const DataPaiementItems: FC<DataPaiementItemsProps & { name: string }> = ({
  ...props
}) => <FieldArray component={ItemsRender} name={props.name} props={props} />;

export default DataPaiementItems;
