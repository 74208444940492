import {
  change,
  DataField,
  DataFieldProps,
  useDispatch,
  useSelector,
} from '@innedit/formidable';
import { ProduitData } from '@innedit/innedit';
import React, { FC, SyntheticEvent, useState } from 'react';

import Button from '../../components/Button';
import IconRedo from '../../icons/Redo';

interface SkuProps extends DataFieldProps {
  espaceId: string;
  formName: string;
}

const Sku: FC<SkuProps> = ({
  espaceId,
  formName,
  name,
  label,
  placeholder,
}) => {
  const dispatch = useDispatch();
  const [inProgress, setInProgress] = useState<boolean>();

  const values = useSelector((state: any) => state.form[formName].values);

  const date = values && values.createdAt;

  const generateSku = async () => {
    if (!inProgress) {
      setInProgress(true);

      const produitData = new ProduitData({ espaceId });
      const sku = await produitData.generateNextSku(date);
      dispatch(change(formName, 'sku', sku));
      setInProgress(false);
    }
  };

  const handleGenerate = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    return generateSku();
  };

  return (
    <DataField
      componentType="input"
      customAction={
        <Button
          className="rounded-l-none"
          color="neutral"
          iconLeft={IconRedo}
          onClick={handleGenerate}
          variant="field"
        />
      }
      fieldProps={{
        className: 'border-r-0 rounded-r-none',
      }}
      label={label}
      name={name}
      placeholder={placeholder}
      type="text"
    />
  );
};

export default Sku;
