import styled from '@emotion/styled';
import { DataProps, DataWithChildren, useSelector } from '@innedit/formidable';
import { ChannelType, DocumentType } from '@innedit/innedit-type';
import React, { FC } from 'react';

const ChannelStyled = styled.div`
  div > div {
    margin-bottom: 0px !important;
  }
`;

export interface ChannelsListProps extends Omit<DataProps, 'componentType'> {
  display?: 'inside' | 'group';
  name: string;
}

const ChannelsList: FC<ChannelsListProps> = ({
  display,
  name,
  title = "Canaux d'interactions",
}) => {
  const [channels] = useSelector(
    (state: { cms: { channels: DocumentType<ChannelType>[] } }) => [
      state.cms.channels,
    ],
  );

  if (!channels || 0 === channels.length) {
    return null;
  }

  return (
    <ChannelStyled>
      <DataWithChildren
        componentType="group"
        datas={channels.map(channel => ({
          componentType: 'input',
          description: channel.libelle,
          labelShow: false,
          name: `${name}.${channel.id}.isVisible`,
          type: 'checkbox',
        }))}
        display={display}
        title={title}
      />
    </ChannelStyled>
  );
};

export default ChannelsList;
