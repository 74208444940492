import styled from '@emotion/styled';
import React, { FC } from 'react';

import { colors } from '../../../styles/theme';
import { MenuType, NavBar } from '../../Menu';

const FooterEL = styled.div`
  border-top: 1px solid ${colors.light[600]};
  padding: 20px;
`;

interface ModalFooterProps {
  buttons?: MenuType;
}

const ModalFooter: FC<ModalFooterProps> = ({ buttons }) => {
  if (!buttons || (0 === buttons.left?.length && 0 === buttons.right?.length)) {
    return null;
  }

  return (
    <FooterEL className="flex space-x-3 items-center justify-between">
      <div>{buttons.left && <NavBar menu={buttons.left} />}</div>
      <div>{buttons.right && <NavBar menu={buttons.right} />}</div>
    </FooterEL>
  );
};

export default ModalFooter;
