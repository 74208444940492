import { change, useDispatch } from '@innedit/formidable';
import { EventData, MediaData } from '@innedit/innedit';
import { DocumentType, MediaType } from '@innedit/innedit-type';
import React, { FC, SyntheticEvent } from 'react';
import slug from 'slug';

import HOCGroup from '../Group/HOC';
import Photo from './Item';
import { List as ListSC } from './styles';

export interface PhotoProps {
  description?: string;
  fullPath: string;
  link?: string;
  media?: string;
  title?: string;
}

interface PhotosProps {
  espaceId: string;
  formName: string;
  handleChangePosition: (oldIndex: number, newIndex: number) => void;
  handleRemoveItem: (index: number) => void;
  items: PhotoProps[];
  title: string;
}

const Photos: FC<PhotosProps> = ({
  espaceId,
  formName,
  handleChangePosition,
  handleRemoveItem,
  items,
  title,
}) => {
  const dispatch = useDispatch();

  const handleTransformOnclick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const mediaData = new MediaData({ espaceId });

    const promises = items.map(({ fullPath }) =>
      mediaData.create({
        fullPath,
        bucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
        type: 'image/jpeg',
      }),
    );

    Promise.all(promises)
      .then((values: DocumentType<MediaType>[]) => {
        const mediaIds = values.map(value => value.id);

        dispatch(change(formName, 'mediaIds', mediaIds));

        items.forEach((item, index) => {
          removeItem(index);
        });

        return true;
        // return dispatch(change(formName, 'photos', []));
      })
      .catch(error => {
        console.error(error.message);
      });
  };

  const removeItem = (index: number) => {
    if (items[index]) {
      if (items[index].media) {
        const eventData = new EventData({ espaceId });
        eventData.create({
          data: {
            object: items[index].media,
          },
          type: 'media.deleted',
        });
      }

      handleRemoveItem(index);
    }
  };

  return (
    <>
      <HOCGroup addOnClick={handleTransformOnclick} title={title}>
        <div className="group">
          {items && items.length > 0 && (
            <div className="w-full outline-none box-border">
              <ListSC>
                {items.map((photo, index) => (
                  <Photo
                    key={slug(photo.fullPath)}
                    changePosition={handleChangePosition}
                    data={photo}
                    handleRemoveItem={removeItem}
                    index={index}
                  />
                ))}
              </ListSC>
            </div>
          )}
        </div>
      </HOCGroup>
    </>
  );
};

export default Photos;
