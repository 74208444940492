import { DocumentType, PriceType } from '@innedit/innedit-type';
import React, { createElement, ElementType, FC } from 'react';
import { useTranslation } from 'react-i18next';

import displayCurrency from '../../utils/displayCurrency';

const Price: FC<{
  as?: ElementType;
  asProps?: any;
  data: DocumentType<PriceType>;
  showDisplayMode?: boolean;
  showType?: boolean;
}> = ({ as, asProps = {}, data, showDisplayMode = true, showType = true }) => {
  const { t } = useTranslation();
  let label = '';

  const price = data;

  switch (price.scheme) {
    case 'per_unit': {
      if (price.amount) {
        label += `${price.amount}`;
      } else {
        label += `Montant non renseigné`;
      }

      label += ` ${displayCurrency(price.currency ?? 'EUR')}`;
      break;
    }

    default: {
      if (price.tiers) {
        const [tier] = price.tiers.sort(
          (a: any, b: any) => b.amount - a.amount,
        );
        label += `À partir de ${tier.amount} ${displayCurrency(
          price.currency,
        )}`;
      }
    }
  }

  if (price.isRecurring) {
    label += ` / ${t(`intervals.${price.interval}`, {
      count: price.intervalCount,
    })}`;
  }

  if ('volume' === price.scheme) {
    label += ' (au volume)';
  }

  if ('graduated' === price.scheme) {
    label += ' (progressif)';
  }

  if (!showType && !showDisplayMode) {
    return createElement(as || React.Fragment, asProps, label);
  }

  const priceInfo = (
    <div className="flex space-x-3">
      <span>{label}</span>
      {showType && data?.type && (
        <span className="italic text-light-800">
          {t(`prices.types.${data.type}`)}
        </span>
      )}
      {showType &&
        (!data.type || 'custom' === data.type) &&
        data.description && (
          <span className="italic text-light-800">{data.description}</span>
        )}
    </div>
  );

  if (!showDisplayMode) {
    return createElement(as || React.Fragment, asProps, priceInfo);
  }

  return (
    <div className="flex-1 flex justify-between items-center">
      {priceInfo}
      <span className="text-xs">
        {t(`prices.display-modes.${price.displayMode}`)}
      </span>
    </div>
  );
};

export default Price;
