import React, { FC, SyntheticEvent, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import nextPhoto from '../../images/next-photo.svg';

const ItemTypes = {
  PHOTO: 'photo',
};

const Photo: FC<{
  removeOnClick: (e: SyntheticEvent<HTMLButtonElement>) => void;
  index: number;
  isDragging: boolean;
  data: {
    fullPath: string;
  };
}> = ({ index, isDragging, data, removeOnClick }) => {
  let newPhoto: string;
  try {
    if (data) {
      newPhoto = `${String(process.env.GATSBY_IMAGES_DOMAIN_NAME)}/${
        data.fullPath
      }`;
      newPhoto += 0 === index ? '?w=640&h=640&c=max' : '?w=320&h=320&c=max';
    } else {
      newPhoto = nextPhoto;
    }
  } catch (e) {
    newPhoto = nextPhoto;
  }

  const handleClipboardOnClick = () => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(
        `${String(process.env.GATSBY_IMAGES_DOMAIN_NAME)}/${data.fullPath}`,
      );
    }
  };

  return (
    <div className={isDragging ? ' isDragging' : ''}>
      <figure className="aspect-ratio aspect-ratio--square">
        <img
          alt="chargement"
          className="aspect-ratio__content"
          src={newPhoto}
        />
      </figure>
      <div className="hover-overlay controls">
        <button
          className="clipboard"
          data-index={index}
          onClick={handleClipboardOnClick}
          type="button"
        >
          <span>Copier</span>
        </button>
        <button
          className="supprimer"
          data-index={index}
          onClick={removeOnClick}
          type="button"
        >
          <span>Supprimer</span>
        </button>
      </div>
    </div>
  );
};

interface DragPhoto {
  index: number;
  data: string;
  type: string;
}

const PhotoPosition: FC<{
  changePosition: (oldIndex: number, newIndex: number) => void;
  handleRemoveItem: (index: number) => void;
  index: number;
  data: {
    fullPath: string;
  };
}> = ({ changePosition, handleRemoveItem, index, data }) => {
  const ref = useRef<HTMLLIElement>(null);

  const handleRemoveOnClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const i = e.currentTarget.getAttribute('data-index');

    if (i) {
      if (window.confirm('Voulez-vous vraiment supprimer cette photo ?')) {
        handleRemoveItem(parseInt(i, 10));
      }
    }
  };

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.PHOTO,
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
    }),
    drop(item: DragPhoto) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      changePosition(dragIndex, hoverIndex);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    item: { data, index, type: ItemTypes.PHOTO },
    type: ItemTypes.PHOTO,
  });

  drag(drop(ref));

  return (
    <li ref={ref} className={isOver ? 'isOver' : ''}>
      <Photo
        data={data}
        index={index}
        isDragging={isDragging}
        removeOnClick={handleRemoveOnClick}
      />
    </li>
  );
};

export default PhotoPosition;
