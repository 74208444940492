import { DataProps } from '@innedit/formidable';
import { FeatureData } from '@innedit/innedit';
import { DocumentType, SlotType } from '@innedit/innedit-type';
import { navigate } from 'gatsby';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import Button from '../../components/Button';
import Slots from '../../components/Slots';
import IconCalendarPlus from '../../icons/CalendarPlus';
import { colors } from '../../styles/theme';

export interface ProduitsAvailabilityProps
  extends Omit<DataProps, 'componentType'> {
  espaceId: string;
  docId?: string;
}

const ProduitsAvailability: FC<ProduitsAvailabilityProps> = ({
  espaceId,
  docId,
}) => {
  const [slots, setSlots] = useState<DocumentType<SlotType>[]>();

  useEffect(() => {
    let isMounted = true;
    let unsub: any;

    if (docId) {
      const featureData = new FeatureData<SlotType>({
        espaceId,
        collectionName: 'slots',
        parentCollectionName: 'produits',
        parentId: docId,
      });

      unsub = featureData.watch(docs => {
        if (isMounted) {
          setSlots(docs);
        }
      });
    }

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [docId]);

  const handleAddOnClick = async (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const slotData = new FeatureData<SlotType>({
      espaceId,
      collectionName: 'slots',
      parentCollectionName: 'produits',
      parentId: docId,
    });

    const newDoc: { [key: string]: any } = slotData.initialize({
      isRecurring: false,
    });

    const slot = await slotData.create(newDoc);

    return navigate(
      `/espaces/${espaceId}/produits/${docId}/slots/${slot.id}/update/`,
    );
  };

  if (!docId) {
    return null;
  }

  return (
    <div className="border-t pt-6 flex flex-col space-y-6">
      <div className="flex justify-between">
        {slots && slots.length > 0 ? (
          <Button
            to={`/espaces/${espaceId}/produits/${docId}/slots/${slots[0].id}/update/`}
            variant="link"
          >
            Editer les créneaux
          </Button>
        ) : (
          <span>Créneaux</span>
        )}

        <Button
          iconColor={colors.light['800']}
          iconRight={IconCalendarPlus}
          onClick={handleAddOnClick}
          variant="link"
        />
      </div>

      {slots && slots.length > 0 && (
        <div className="flex flex-col">
          <Slots datas={slots} espaceId={espaceId} produitId={docId} />
        </div>
      )}
    </div>
  );
};

export default ProduitsAvailability;
