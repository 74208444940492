import { DocumentType, PageType } from '@innedit/innedit-type';
import React, { FC, SyntheticEvent } from 'react';

import Button from '../../../components/Button';
import IconDelete from '../../../icons/Delete';
import IconEdit from '../../../icons/Edit';

export interface ListItemProps {
  index: number;
  doc: DocumentType<PageType>;
  editPathname?: string;
  removeOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
}

const ItemPosition: FC<
  ListItemProps & {
    index: number;
  }
> = ({ editPathname, index, doc, removeOnClick }) => (
  <div className="flex items-center">
    <strong className="flex-1">{doc.name || '<nom>'}</strong>
    <div className="flex space-x-1 self-end">
      {doc && doc.id && (
        <Button
          className="no-left-radius"
          color="neutral"
          size="sm"
          tooltip={doc.id}
          type="tooltip"
          variant="ghost"
        />
      )}
      {editPathname && (
        <Button
          color="secondary"
          iconLeft={IconEdit}
          size="sm"
          to={`${editPathname}${doc.id}/update/`}
          variant="ghost"
        />
      )}
      <Button
        color="danger"
        data-index={index}
        iconLeft={IconDelete}
        onClick={removeOnClick}
        size="sm"
        square
        variant="groove"
      />
    </div>
  </div>
);

export default ItemPosition;
