import {
  arrayPush,
  change,
  DataWithChildren,
  DataWithChildrenProps,
  useDispatch,
  useSelector,
} from '@innedit/formidable';
import { EspaceData } from '@innedit/innedit';
import React, { FC, SyntheticEvent } from 'react';
import { toast } from 'react-toastify';

import HOCGroup from '../../components/Group/HOC';

export interface DataGoogleSearchProps
  extends Omit<DataWithChildrenProps, 'componentType'> {
  formName: string;
  field: string;
}

interface ResultSearchType {
  extraits: string[];
  graph?: {
    datas?: { value: string; label: string }[];
    description?: string;
    infos?: string[];
    relatedBusiness?: { name: string; type: string }[];
    title?: string;
    type?: string;
  };
  questions: string[];
  results: { link: string; title: string }[];
  searches: string[];
}
const DataGoogleSearch: FC<DataGoogleSearchProps> = ({
  datas,
  formName,
  field,
  title,
}) => {
  const dispatch = useDispatch();
  // const [result, setResult] = useState<{
  //   graph?: any;
  //   questions: string[];
  //   results: any[];
  //   searches: string[];
  // }>();

  const { values } = useSelector(
    (globalState: any) => globalState.form[formName],
  );

  const handleOnclick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // setResult(undefined);
    const libelle = values && values[field];
    if (libelle) {
      const espaceData = new EspaceData();

      espaceData
        .search(libelle)
        .then((data: ResultSearchType) => {
          // setResult(data);
          if (data.extraits && data.extraits.length > 0) {
            const extrait = data.extraits.reduce(
              (acc, value) => `${acc} ${value}`,
              values.extract || '',
            );
            dispatch(change(formName, 'extract', extrait));
          }

          if (data.graph) {
            if (data.graph.datas && data.graph.datas.length > 0) {
              data.graph.datas.forEach(d => {
                dispatch(arrayPush(formName, 'graph.datas', d));
              });
            }
            if (data.graph.description) {
              dispatch(
                change(formName, 'graph.description', data.graph.description),
              );
            }
            if (
              data.graph.relatedBusiness &&
              data.graph.relatedBusiness.length > 0
            ) {
              data.graph.relatedBusiness.forEach(d => {
                dispatch(arrayPush(formName, 'graph.relatedBusiness', d));
              });
            }
            if (data.graph.title) {
              dispatch(change(formName, 'graph.title', data.graph.title));
            }
            if (data.graph.type) {
              dispatch(change(formName, 'graph.type', data.graph.type));
            }
          }

          if (data.results && data.results.length > 0) {
            data.results.forEach(d => {
              dispatch(arrayPush(formName, 'results', d));
            });
          }

          if (data.questions && data.questions.length > 0) {
            data.questions.forEach(d => {
              dispatch(arrayPush(formName, 'questions', d));
            });
          }
          if (data.searches && data.searches.length > 0) {
            data.searches.forEach(d => {
              dispatch(arrayPush(formName, 'searches', d));
            });
          }

          return true;
        })
        .catch(toast.error);
    }
  };

  return (
    <HOCGroup addOnClick={handleOnclick} title={title}>
      <DataWithChildren
        componentType="flex"
        datas={datas}
        formName={formName}
      />
    </HOCGroup>
  );
};

export default DataGoogleSearch;
