import styled from '@emotion/styled';
import React, { FC, SyntheticEvent, useEffect, useRef } from 'react';

import { borderRadius, spacing } from '../../../styles/theme';
import Portal from '../../../utils/Portal';
import { MenuType } from '../../Menu';
import ModalContent from './Content';
import ModalFooter from './Footer';
import ModalHeader from './Header';

const BackgroundEL = styled.div<{
  'data-max-width'?: 'auto' | number | string;
  'data-min-width'?: 'auto' | number | string;
  'data-width'?: 'auto' | number | string;
}>`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.5);
  z-index: 200000;

  > div {
    height: 100%;

    padding: ${spacing[6]} 0 !important;
    pointer-events: auto;
    position: relative;
    min-width: ${props => props['data-min-width'] || 'auto'};
    max-width: ${props => props['data-max-width'] || '62rem'};
    margin: 0 auto;
    width: ${props => props['data-width'] || '100%'};
  }
`;

const ModalEL = styled.div`
  border-radius: ${borderRadius.s};

  background: #fff !important;

  display: flex;
  flex-direction: column;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export interface ViewModalProps {
  buttons?: MenuType;
  closeOnClick: (event?: SyntheticEvent<HTMLButtonElement>) => void;
  maxWidth?: 'auto' | string;
  minWidth?: 'auto' | string;
  title: string;
  width?: 'auto' | string;
}

const ViewModal: FC<ViewModalProps> = ({
  buttons,
  children,
  closeOnClick,
  maxWidth,
  minWidth,
  title,
  width,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      closeOnClick(event);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <Portal>
      <BackgroundEL
        data-max-width={maxWidth}
        data-min-width={minWidth}
        data-width={width}
      >
        <div ref={ref}>
          <ModalEL>
            <ModalHeader closeOnClick={closeOnClick} title={title} />
            <ModalContent>{children}</ModalContent>
            <ModalFooter buttons={buttons} />
          </ModalEL>
        </div>
      </BackgroundEL>
    </Portal>
  );
};

export default ViewModal;
