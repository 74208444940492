import {
  change,
  DataFieldProps,
  Field,
  WrappedFieldProps,
} from '@innedit/formidable';
import { CommandeData } from '@innedit/innedit';
import { CommandeType, DocumentType } from '@innedit/innedit-type';
import React, { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import ComponentField from '../../styles/Field';

interface DataCommandesSelectProps
  extends Omit<DataFieldProps, 'componentType'> {
  espaceId: string;
  formName: string;
}

const DataCommandesSelectRender: FC<
  DataCommandesSelectProps & WrappedFieldProps
> = ({ espaceId, formName, input, label, meta }) => {
  const [options, setOptions] = useState<DocumentType<CommandeType>[]>();
  const selectRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    const current = selectRef && selectRef.current;

    const handleKeyup = () => {
      if (current) {
        if (0 === current.value.length) {
          current.classList.add('is-empty');
        } else {
          current.classList.remove('is-empty');
        }
      }
    };

    const commandeData = new CommandeData({ espaceId });
    const unsub = commandeData.watch(newDocs => {
      setOptions(newDocs);

      if (current) {
        if (current.value) {
          current.classList.remove('is-empty');
        } else {
          current.classList.add('is-empty');
        }

        current.addEventListener('focus', () => {
          current.classList.add('is-focused');
        });
        current.addEventListener('blur', () => {
          current.classList.remove('is-focused');
        });
        current.addEventListener('keyup', handleKeyup);
      }
    });

    return () => {
      if (unsub) {
        unsub();
      }

      if (current) {
        current.removeEventListener('keyup', handleKeyup);
      }
    };
  }, [espaceId]);

  const handleOnChange = (event: SyntheticEvent<HTMLSelectElement>) => {
    const { name } = input;
    const { dispatch } = meta;

    if (options) {
      dispatch(change(formName, name, event.currentTarget.value));
    }
  };

  const { name, value } = input;
  const { touched, error } = meta;

  const id = uuidv4();

  return (
    <ComponentField
      className={`field select ${touched && error ? 'error ' : ''}`}
    >
      {options && options.length > 0 ? (
        <select
          ref={selectRef}
          id={id}
          name={name}
          onChange={handleOnChange}
          value={value}
        >
          <option value="">Non spécifié</option>
          {options.map(item => (
            <option key={`${name}_${item.id}`} value={item.id}>
              {item.reference
                ? `#${item.reference} par ${item.contactFirstName} ${item.contactLastName}`
                : item.id}
            </option>
          ))}
        </select>
      ) : (
        <div>...</div>
      )}
      <label className="label" htmlFor={id}>
        <span>{label}</span>
      </label>
      {touched && error && (
        <div className={`error error_${error.code}`}>
          <span className="icon">
            <i className="fa fa-exclamation" />
          </span>
          <span>{error.message}</span>
        </div>
      )}
    </ComponentField>
  );
};

const DataCommandesSelect: FC<DataCommandesSelectProps> = props => (
  <Field {...props} component={DataCommandesSelectRender} />
);

export default DataCommandesSelect;
