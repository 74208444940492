import {
  DocumentType,
  PriceType,
  ProduitType,
  StockType,
  TarifType,
} from '@innedit/innedit-type';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import FirebaseFirestore, {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';

import { auth } from '../../../config/firebase';
import { FeatureData, StockData } from '../../../index';
import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

dayjs.extend(isoWeek);

class Produit extends ModelEspace<ProduitType> {
  constructor(props: Omit<ModelEspaceProps<ProduitType>, 'collectionName'>) {
    super({
      addButtonLabel: 'Ajouter un produit',
      collectionName: 'produits',
      itemFieldTitle: 'name',
      tabs: [
        {
          itemMode: 'grid',
          label: 'Tous les produits',
          pathname: `/espaces/${props.espaceId}/produits/`,
        },
        {
          label: 'Caché',
          pathname: `/espaces/${props.espaceId}/produits/cache/`,
          wheres: {
            hidden: true,
          },
        },
        {
          label: 'En stock',
          pathname: `/espaces/${props.espaceId}/produits/en-stock/`,
          wheres: {
            hasInventory: true,
            qtyAvailable: {
              operator: '>',
              value: 0,
            },
          },
        },
        {
          label: 'Réservé',
          pathname: `/espaces/${props.espaceId}/produits/reserve/`,
          wheres: {
            hasInventory: true,
            qtyReserved: {
              operator: '>',
              value: 0,
            },
          },
        },
        {
          label: 'Rupture de stock',
          pathname: `/espaces/${props.espaceId}/produits/rupture-de-stock/`,
          wheres: {
            hasInventory: true,
            qtyAvailable: 0,
          },
        },
        {
          label: 'Sans inventaire',
          pathname: `/espaces/${props.espaceId}/produits/sans-inventaire/`,
          wheres: {
            hasInventory: false,
          },
        },
        {
          label: 'Créneaux',
          pathname: `/espaces/${props.espaceId}/produits/creneaux/`,
          wheres: {
            qtySlots: {
              operator: '>',
              value: 0,
            },
          },
        },
        {
          label: 'Tarif global',
          pathname: `/espaces/${props.espaceId}/produits/sans-prix/`,
          wheres: {
            hasGlobalPricing: true,
          },
        },
      ],
      ...props,
    });
  }

  public async create(
    data: Partial<ProduitType> & {
      prices?: { [tarif: string]: number };
    },
  ): Promise<DocumentType<ProduitType>> {
    const { prices, qtyAvailable, ...others } = data;
    const produit = await super.create(others);

    if (prices && Object.keys(prices).length > 0) {
      // On peut créer les prix passés en paramètre
      const model = new FeatureData<PriceType>({
        collectionName: 'prices',
        espaceId: this.espaceId,
        parentCollectionName: 'produits',
        parentId: produit.id,
      });

      await Promise.all(
        Object.keys(prices).map(tarif =>
          model.create({
            amount: prices[tarif],
            currency: 'EUR',
            displayMode: 'public' === tarif ? 'show' : 'request',
            isRecurring: false,
            scheme: 'per_unit',
            type: tarif as TarifType,
            unit: 1,
          }),
        ),
      );
    }

    if (qtyAvailable && qtyAvailable > 0) {
      // On peut enregistrer le stock pour stocker cette information
      const stockData = new StockData({
        espaceId: this.espaceId,
        parentCollectionName: 'produits',
        parentId: produit.id,
      });

      await stockData.create({
        quantity: qtyAvailable,
      });
    }

    return produit;
  }

  public async duplicate(
    id: string,
    data: ProduitType,
  ): Promise<FirebaseFirestore.DocumentReference<ProduitType>> {
    const sku = await this.generateNextSku();

    return super.duplicate(id, {
      ...data,
      sku,
    });
  }

  public async findPrices(id: string): Promise<DocumentType<PriceType>[]> {
    const ref = collection(this.getCollectionRef(), id, 'prices');
    const constraints = [
      where('deleted', '==', false),
      where('hidden', '==', false),
    ];

    const q = query(ref, ...constraints);
    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map(d => ({
      id: d.id,
      ...(d.data() as PriceType),
    }));
  }

  public async findStockById(id: string): Promise<DocumentType<StockType>[]> {
    const documentSnapshot = await getDoc<ProduitType>(
      doc(
        this.getCollectionRef(),
        id,
      ) as FirebaseFirestore.DocumentReference<ProduitType>,
    );

    if (
      !documentSnapshot ||
      !documentSnapshot.exists() ||
      documentSnapshot.get('deleted')
    ) {
      throw new Error("Le document n'existe pas ou a été supprimé");
    }

    const constaints = [
      where('deleted', '==', false),
      orderBy('createdAt', 'desc'),
    ];

    const q = query(collection(documentSnapshot.ref, 'stocks'), ...constaints);

    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map(d => ({
      id: d.id,
      ...(d.data() as StockType),
    }));
  }

  public clean(
    values: Partial<ProduitType>,
    validate?: boolean,
  ): Partial<ProduitType> {
    return super.clean({
      ...values,
      depth: values.depth && Number(values.depth),
      foldedDepth: values.foldedDepth && Number(values.foldedDepth),
      foldedHeight: values.foldedHeight && Number(values.foldedHeight),
      foldedWidth: values.foldedWidth && Number(values.foldedWidth),
      hasGlobalPricing: Boolean(values.hasGlobalPricing),
      hasInventory: Boolean(values.hasInventory),
      height: values.height && Number(values.height),
      kind: values.kind || 'good',
      qtyAvailable: values.qtyAvailable && Number(values.qtyAvailable),
      qtyByNumber: values.qtyByNumber && Number(values.qtyByNumber),
      qtyInLot: values.qtyInLot && Number(values.qtyInLot),
      qtyMax: values.qtyMax && Number(values.qtyMax),
      qtyMin: values.qtyMin && Number(values.qtyMin),
      qtyPrices: values.qtyPrices && Number(values.qtyPrices),
      qtyReserved: values.qtyReserved && Number(values.qtyReserved),
      qtySlots: values.qtySlots && Number(values.qtySlots),
      shipping2Carriers: Boolean(values.shipping2Carriers),
      shippingRisky: Boolean(values.shippingRisky),
      shippingSharing: values.shippingSharing && Number(values.shippingSharing),
      shippingSupport: values.shippingSupport && Number(values.shippingSupport),
      shippingVolume: values.shippingVolume && Number(values.shippingVolume),
      shippingWeight: values.shippingWeight && Number(values.shippingWeight),
      stackableByQty: values.stackableByQty && Number(values.stackableByQty),
      weight: values.weight && Number(values.weight),
      width: values.width && Number(values.width),
    });
  }

  public async set(
    id: string,
    values: Partial<ProduitType> & { prices?: { [tarif: string]: number } },
  ): Promise<void> {
    if (!auth.currentUser) {
      throw new Error(
        "L'utilisateur doit être connecté pour mettre à jour un document",
      );
    }

    const { prices, ...others } = values;
    await super.set(id, others);

    if (prices && Object.keys(prices).length > 0) {
      // On peut créer les prix passés en paramètre
      const model = new FeatureData<PriceType>({
        collectionName: 'prices',
        espaceId: this.espaceId,
        parentCollectionName: 'produits',
        parentId: id,
      });

      await Promise.all(
        Object.keys(prices).map(tarif =>
          model.create({
            amount: prices[tarif],
            currency: 'EUR',
            displayMode: 'public' === tarif ? 'show' : 'request',
            isRecurring: false,
            scheme: 'per_unit',
            type: tarif as TarifType,
            unit: 1,
          }),
        ),
      );
    }
  }

  static calculNewQuantity(
    qty: number,
    oldQty: number,
    item: ProduitType,
  ): number {
    const { hasInventory, qtyAvailable, qtyMin, qtyByNumber, qtyMax } = item;

    if (0 === qty) {
      return 0;
    }

    const addOp = qty > oldQty;
    const newQtyByNumber = qtyByNumber || 1;

    const newQtyMin = qtyMin
      ? Math.max(1, qtyMin, newQtyByNumber)
      : newQtyByNumber;
    let newQtyMax;
    if (hasInventory) {
      newQtyMax = qtyMax || 0;
    } else {
      newQtyMax = 1;
    }
    let newQty = qty || newQtyMin;
    newQtyMax -=
      newQtyMax % newQtyByNumber > 0 ? newQtyMax % newQtyByNumber : 0;

    if (newQty % newQtyByNumber > 0) {
      newQty -= newQty % newQtyByNumber;
      newQty += addOp ? newQtyByNumber : 0;
    }

    if (newQtyMin && newQty < newQtyMin) {
      newQty = newQtyMin;
    }
    if (newQtyMax > 0 && newQty > newQtyMax) {
      newQty = newQtyMax;
    }
    if (hasInventory && qtyAvailable && newQty > qtyAvailable) {
      newQty = qtyAvailable;
    }

    return newQty;
  }

  public async generateNextSku(date?: string): Promise<string> {
    const debut = date ? dayjs(date).format('YYWWd') : dayjs().format('YYWWd');
    const fin = `${parseInt(debut, 10)}${1}`;
    const constraints = [where('sku', '>', debut), where('sku', '<', fin)];
    if (this.espaceId) {
      constraints.push(where('boutique', '==', this.espaceId));
    }
    constraints.push(orderBy('sku'));
    const q = query(this.getCollectionRef(), ...constraints);

    const querySnapshot = await getDocs(q);

    // TODO améliorer la génération du sku comme pour les url pathnames
    if (querySnapshot.size > 0) {
      const { sku } = querySnapshot.docs[querySnapshot.size - 1].data();

      if (sku) {
        // TODO gérer des sku supérieur à 999 -> A01 -> A02 ->
        return debut + `000${parseInt(sku.substr(-3), 10) + 1}`.slice(-3);
      }
    }

    return `${debut}001`;
  }

  public initialize(data?: Partial<ProduitType>): Partial<ProduitType> {
    const newData = { ...data };
    const products = this.espace?.products;
    if (products?.defaultCondition) {
      newData.condition = products.defaultCondition;
    }
    if (products?.defaultHasInventory) {
      newData.hasInventory = products.defaultHasInventory;
      newData.qtyAvailable = 1;
    }
    if (products?.defaultHasGlobalPricing) {
      newData.hasGlobalPricing = products.defaultHasGlobalPricing;
    }
    if (products?.defaultIsPhysical) {
      newData.isPhysical = products.defaultIsPhysical;
    }
    if (products?.defaultKind) {
      newData.kind = products.defaultKind;
    }
    if (products?.defaultShippingMode) {
      newData.shippingMode = products.defaultShippingMode;
    }

    return super.initialize({
      ...newData,
      condition: newData.condition ?? 'new',
      hasGlobalPricing: newData.hasGlobalPricing ?? false,
      hasInventory: newData.hasInventory ?? false,
      hidden: newData.hidden ?? false,
      isALot: newData.isALot ?? false,
      isDemountable: newData.isDemountable ?? false,
      isFeatured: newData.isFeatured ?? false,
      isFragile: newData.isFragile ?? false,
      isHeavy: newData.isHeavy ?? false,
      isPhysical: newData.isPhysical ?? false,
      kind: newData.kind || 'good',
      qtyAvailable: newData.qtyAvailable ?? 0,
      qtyByNumber: newData.qtyByNumber ?? 1,
      qtyMin: newData.qtyMin ?? 1,
      qtyPrices: newData.qtyPrices ?? 0,
      qtyReserved: newData.qtyReserved ?? 0,
      qtySlots: newData.qtySlots ?? 0,
      shipping2Carriers: newData.shipping2Carriers ?? false,
      shippingFloor: newData.shippingFloor ?? 'valid',
      shippingMode: newData.shippingMode ?? 'auto',
      shippingRisky: newData.shippingRisky ?? false,
    });
  }

  public async secureUpdate(
    id: string,
    data: Partial<ProduitType>,
  ): Promise<void> {
    const docRef = doc(
      this.getFirestore(),
      this.collectionName,
      id,
    ) as FirebaseFirestore.DocumentReference<ProduitType>;

    const values: Partial<ProduitType> = {};
    if (undefined !== data.hidden) {
      values.hidden = data.hidden;
    }

    if (undefined !== data.datetime) {
      values.datetime = data.datetime;
    }

    if (Object.keys(values).length > 0) {
      await updateDoc(docRef, {
        ...values,
        updatedAt: dayjs().toISOString(),
      } as FirebaseFirestore.UpdateData<ProduitType>);
    }
  }

  static getDepth(item: ProduitType): number {
    return Number(item.foldedDepth || item.depth || 0);
  }

  static getHeight(item: ProduitType): number {
    return Number(item.foldedHeight || item.height || 0);
  }

  static getMaxQuantity(item: ProduitType): number {
    const qtyAvailable = item.qtyAvailable || 0;
    let qtyMax = item.qtyMax
      ? Math.min(item.qtyMax, qtyAvailable)
      : qtyAvailable;
    qtyMax = !item.hasInventory ? 0 : qtyMax;

    return qtyMax;
  }

  static getMinQuantity(item: ProduitType): number {
    const qtyAvailable = item.qtyAvailable || 0;
    const qtyMin = item.qtyMin || 0;
    const qtyByNumber = item.qtyByNumber || 1;

    return Math.max(1, Math.min(qtyMin, qtyAvailable), qtyByNumber);
  }

  static getWidth(item: ProduitType): number {
    return Number(item.foldedWidth || item?.width || 0);
  }

  static calcDimensionMax(item: ProduitType): number {
    const width = this.getWidth(item);
    const depth = this.getDepth(item);
    const height = this.getHeight(item);

    return width + depth + height;
  }

  static calcVolume(item: ProduitType): number {
    const width = this.getWidth(item);
    const depth = this.getDepth(item);
    const height = this.getHeight(item);
    const shippingVolume = Number(item.shippingVolume || 0);

    return shippingVolume || (width * depth * height) / 1000000;
  }

  static calcWeight(item: ProduitType): number {
    const shippingWeight = Number(item.shippingWeight || 0);
    const weight = Number(item.weight || 0);

    return shippingWeight || weight;
  }
}

export default Produit;
