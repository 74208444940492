import {
  change,
  DataProps,
  DataWithChildren,
  useDispatch,
  useSelector,
  WrapperProps,
} from '@innedit/formidable';
import React, { FC, SyntheticEvent, useState } from 'react';

import HOCGroup from '../../../components/Group/HOC';
import Modal from './Modal';

export interface FieldProps {
  label: string;
  name: string;
  type: string;
}

export interface SchemasDatasProps extends Omit<DataProps, 'componentType'> {
  formName: string;
  name: string;
  title?: string;
}

const Wrapper: FC<WrapperProps> = ({
  addAfterOnClick,
  addBeforeOnClick,
  addInsideOnClick,
  children,
  componentType,
  editOnClick,
  name,
  position,
  removeOnClick,
}) => (
  <div className="border border-dark-200 w-full">
    <div className="bg-tertiary-200 flex space-x-2 mb-2 p-2 text-sm items-center">
      <span>
        {componentType} {name}
      </span>
      <button
        className="text-primary-800 underline rounded"
        data-position={position}
        onClick={addBeforeOnClick}
        type="button"
      >
        Ajouter avant
      </button>
      <button
        className="text-primary-800 underline rounded"
        data-position={position}
        onClick={addAfterOnClick}
        type="button"
      >
        Ajouter après
      </button>
      {['box', 'grid', 'flex', 'group'].includes(componentType) && (
        <button
          className="text-primary-800 underline rounded"
          data-position={position}
          onClick={addInsideOnClick}
          type="button"
        >
          Ajouter à l&apos;intérieur
        </button>
      )}
      <button
        className="text-primary-800 underline rounded"
        data-position={position}
        onClick={editOnClick}
        type="button"
      >
        Editer
      </button>
      <button
        className="text-primary-800 underline rounded"
        data-position={position}
        onClick={removeOnClick}
        type="button"
      >
        Supprimer
      </button>
    </div>
    <div className="p-2">{children}</div>
  </div>
);

const SchemasDatas: FC<SchemasDatasProps> = ({
  formName,
  name,
  params,
  title = 'Liste des datas',
}) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalName, setModalName] = useState<string>();

  const values = useSelector((state: any) => state.form[formName].values);
  const datas = values[name];

  const handleAddAfterOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const p = event.currentTarget.getAttribute('data-position');
    if (p) {
      const positions = p
        .replace(/\[/g, '')
        .replace(/]/g, '')
        .replace(/datas/g, '')
        .split('.');
      if (positions.length > 0) {
        const lastIndex = parseInt(String(positions.pop()), 10);

        const newDatas = Array.isArray(datas) ? [...datas] : [{ ...datas }];
        let tmpDatas = newDatas;
        positions.forEach(position => {
          tmpDatas = tmpDatas[parseInt(position, 10)].datas;
        });

        if (lastIndex < tmpDatas.length - 1) {
          tmpDatas.splice(lastIndex + 1, 0, {
            componentType: '',
          });
        } else {
          tmpDatas.push({
            componentType: '',
          });
        }

        dispatch(change(formName, name, newDatas));
      }
    }
  };

  const handleAddBeforeOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const p = event.currentTarget.getAttribute('data-position');
    if (p) {
      const positions = p
        .replace(/\[/g, '')
        .replace(/]/g, '')
        .replace(/datas/g, '')
        .split('.');
      if (positions.length > 0) {
        const lastIndex = parseInt(String(positions.pop()), 10);

        const newDatas = Array.isArray(datas) ? [...datas] : [{ ...datas }];
        let tmpDatas = newDatas;
        positions.forEach(position => {
          tmpDatas = tmpDatas[parseInt(position, 10)].datas;
        });

        if (lastIndex > 0) {
          tmpDatas.splice(lastIndex, 0, {
            componentType: '',
          });
        } else {
          tmpDatas.unshift({
            componentType: '',
          });
        }

        dispatch(change(formName, name, newDatas));
      }
    }
  };

  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    let newDatas: any[];
    if (Array.isArray(datas)) {
      newDatas = [...datas];
    } else {
      newDatas = [datas];
    }

    newDatas.push({
      componentType: '',
    });

    dispatch(change(formName, name, newDatas));
  };

  const handleAddInsideOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const p = event.currentTarget.getAttribute('data-position');
    if (p) {
      const positions = p
        .replace(/\[/g, '')
        .replace(/]/g, '')
        .replace(/datas/g, '')
        .split('.');

      const newDatas = Array.isArray(datas) ? [...datas] : [{ ...datas }];
      let tmpDatas = newDatas;
      positions.forEach(position => {
        if (!tmpDatas[parseInt(position, 10)].datas) {
          tmpDatas[parseInt(position, 10)].datas = [];
        }
        tmpDatas = tmpDatas[parseInt(position, 10)].datas;
      });
      tmpDatas.push({
        componentType: '',
      });

      dispatch(change(formName, name, newDatas));
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleEditOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const p = event.currentTarget.getAttribute('data-position');
    if (p) {
      setModalName(p);
      setOpenModal(true);
    }
  };

  const handleRemoveOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const p = event.currentTarget.getAttribute('data-position');
    if (p) {
      const positions = p
        .replace(/\[/g, '')
        .replace(/]/g, '')
        .replace(/datas/g, '')
        .split('.');
      if (positions.length > 0) {
        const lastIndex = parseInt(String(positions.pop()), 10);

        const newDatas = Array.isArray(datas) ? [...datas] : [{ ...datas }];
        let tmpDatas = newDatas;
        positions.forEach(position => {
          tmpDatas = tmpDatas[parseInt(position, 10)].datas;
        });

        tmpDatas.splice(lastIndex, 1);

        dispatch(change(formName, name, newDatas));
      }
    }
  };

  return (
    <HOCGroup addOnClick={handleAddOnClick} title={title}>
      {openModal && modalName && (
        <Modal
          closeOnClick={handleCloseModal}
          formName={formName}
          name={modalName}
          params={params}
          title={modalName}
        />
      )}
      <DataWithChildren
        className="mt-0"
        componentType="flex"
        datas={datas as DataProps}
        formName={formName}
        mode="creation"
        params={params}
        wrapper={Wrapper}
        wrapperFunc={{
          addAfterOnClick: handleAddAfterOnClick,
          addBeforeOnClick: handleAddBeforeOnClick,
          addInsideOnClick: handleAddInsideOnClick,
          editOnClick: handleEditOnClick,
          removeOnClick: handleRemoveOnClick,
        }}
      />
    </HOCGroup>
  );
};

export default SchemasDatas;
