import { DataField, DataFieldProps } from '@innedit/formidable';
import { DocumentType, FeatureType, UserType } from '@innedit/innedit-type';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '../../../components/Button';
import ViewModal from '../../../components/View/Modal';
import IconSpinner from '../../../icons/Spinner';
import Modal from './Modal';

const stripePromise = loadStripe(
  String(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY),
);

export interface DataPaiementsModeProps
  extends Omit<DataFieldProps, 'componentType'> {
  user: DocumentType<UserType>;
}

const DataPaiementsMode: FC<DataPaiementsModeProps> = ({
  label,
  name,
  required,
  user,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [clientSecret, setClienSecret] = useState<string>();
  const [paymentMethods, setPaymentMethods] =
    useState<DocumentType<FeatureType>[]>();

  useEffect(() => {
    let isMounted = true;

    const { customer, customerSecret } = user;
    if (customer && customerSecret) {
      const url = `users/${user.id}/payment_methods/list?secret=${customerSecret}`;

      fetch(`${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/${url}`)
        .then(r => r.json())
        .then(r => {
          if (isMounted) {
            setPaymentMethods(r.data);
          }

          return true;
        })
        .catch(error => {
          toast.error(error);
        });
    } else if (isMounted) {
      setPaymentMethods([]);
    }

    return () => {
      isMounted = false;
    };
  }, [user]);

  const handleOpenOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!user.setupIntent) {
      const url = `${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/users/${user.id}/setup_intents/create`;
      fetch(url)
        .then(r => r.text())
        .then(setClienSecret)
        .catch(toast.error);
    }

    setOpen(true);
  };

  const handleCloseOnClick = (event?: SyntheticEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault();
    }

    setOpen(false);
  };

  return (
    <>
      {open && (
        <ViewModal
          closeOnClick={handleCloseOnClick}
          maxWidth="400px"
          title="Ajouter un mode de paiement"
        >
          {clientSecret && (
            <Elements
              options={{
                clientSecret,
              }}
              stripe={stripePromise}
            >
              <Modal />
            </Elements>
          )}
          {!clientSecret && <IconSpinner />}
        </ViewModal>
      )}
      {!paymentMethods ||
        (0 === paymentMethods.length && (
          <div>
            <span>Aucun mode de paiement enregistré</span>
            <Button onClick={handleOpenOnClick}>
              Ajouter votre premier mode de paiement
            </Button>
          </div>
        ))}
      {paymentMethods && paymentMethods.length > 0 && (
        <DataField
          componentType="input"
          customTop={
            <Button onClick={handleOpenOnClick} variant="link">
              Ajouter un nouveau mode de paiement
            </Button>
          }
          customTopProps={{
            className: 'absolute right-0',
          }}
          label={label}
          name={name}
          options={paymentMethods.map(object => {
            let l = '';
            switch (object.type) {
              case 'card': {
                const {
                  brand,
                  last4,
                  exp_month: expMonth,
                  exp_year: expYear,
                } = object.card;
                l += `${brand} •••• ${last4} ${expMonth}/${expYear}`;
                break;
              }
            }

            return {
              label: l,
              value: object.id,
            };
          })}
          required={required}
          type="radio"
          wrapperProps={{
            className: 'relative',
          }}
        />
      )}
    </>
  );
};

export default DataPaiementsMode;
