import {
  change,
  DataFieldArrayProps,
  FieldArray,
  Group,
  useDispatch,
  useSelector,
} from '@innedit/formidable';
import { FeatureData } from '@innedit/innedit';
import { DocumentType, FeatureType } from '@innedit/innedit-type';
import React, { FC, memo, useEffect, useState } from 'react';

import CaracteristiquesRender from './Render';

export interface CaracteristiquesProps extends DataFieldArrayProps {
  espaceId: string;
  formName: string;
  name: string;
}

const Caracteristiques: FC<CaracteristiquesProps> = props => {
  const [productFeatures, setProductFeatures] =
    useState<DocumentType<FeatureType>[]>();
  const { espaceId, className, formName, name, label } = props;

  const dispatch = useDispatch();
  const {
    values: { features },
  } = useSelector((state: any) => state.form[formName]);

  useEffect(() => {
    const featureData = new FeatureData<FeatureType>({
      espaceId,
      collectionName: `boutiques/${espaceId}/productFeatures`,
      orderDirection: 'asc',
      orderField: 'libelle',
    });

    featureData
      .find()
      .then(documents => {
        if (documents && documents.length > 0) {
          const tmpFeatures = documents.map(doc => ({
            id: doc.id,
            libelle: doc.libelle,
            valeur: '',
          }));

          if (!features || !Array.isArray(features) || 0 === features.length) {
            dispatch(change(formName, 'features', tmpFeatures));
          } else {
            // On regarde si la feature est dans le tableau
            let hasAddFeature = false;
            tmpFeatures.forEach(doc => {
              if (!features.some(feature => feature.id === doc.id)) {
                features.push(doc);
                hasAddFeature = true;
              }
            });

            if (hasAddFeature) {
              dispatch(change(formName, 'features', features));
            }
          }
        }

        return setProductFeatures(documents);
      })
      .catch((error: Error) => {
        throw new Error(`boutique.ref
      .collection('productFeatures') : ${error.message}`);
      });
  }, [espaceId, formName]);

  return (
    <Group className={className} title={label}>
      <FieldArray
        component={CaracteristiquesRender}
        name={name}
        props={{ ...props, features: productFeatures }}
      />
    </Group>
  );
};

export default memo(Caracteristiques);
