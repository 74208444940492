import { WrappedFieldArrayProps } from '@innedit/formidable';
import { DocumentType, MediaType } from '@innedit/innedit-type';
import React, { FC } from 'react';

import Medias from '../../components/Medias';
import { MediasProps } from './index';

const MediasRender: FC<WrappedFieldArrayProps & MediasProps> = ({
  espaceId,
  display,
  fields,
  label,
}) => {
  const handleAddItems = (medias: DocumentType<MediaType>[]): void => {
    medias.forEach(media => {
      fields.push(media.id);
    });
  };

  const handleChangePosition = (oldIndex: number, newIndex: number): void => {
    const mediaId = fields.get(oldIndex);
    if (newIndex !== oldIndex) {
      if (newIndex < oldIndex) {
        fields.insert(newIndex, mediaId);
        fields.remove(oldIndex + 1);
      } else {
        // la nouvelle position est après
        fields.remove(oldIndex);
        fields.insert(newIndex, mediaId);
      }
    }
  };

  const handleRemoveItem = (index: number): void => {
    fields.remove(index);
  };

  const items: string[] = fields.length > 0 ? fields.getAll() : [];

  return (
    <Medias
      addItems={handleAddItems}
      changePosition={handleChangePosition}
      display={display}
      espaceId={espaceId}
      items={items}
      removeItem={handleRemoveItem}
      title={label ?? 'Médias'}
    />
  );
};

export default MediasRender;
