import { Dispatch } from '@innedit/formidable';
import { FeatureData } from '@innedit/innedit';

import { ESPACE_SET_COLLECTIONS } from '../../constants/actionTypes2';

function findByEspaceId(espaceId: string): any {
  return (dispatch: Dispatch): any => {
    const featureData = new FeatureData({
      espaceId,
      collectionName: 'collections',
    });

    featureData
      .find({
        wheres: {
          hidden: false,
        },
      })
      .then(collections =>
        dispatch({
          collections,
          type: ESPACE_SET_COLLECTIONS,
        }),
      )
      .catch(error => {
        console.error(error.message);
      });
  };
}

export default findByEspaceId;
