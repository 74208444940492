import { DataSection } from '@innedit/formidable';
import React, { FC } from 'react';

import { spacing } from '../../../../styles/theme';

interface ItemNoEditableProps {
  name: string;
}

const ItemEditable: FC<ItemNoEditableProps> = ({ name }) => (
  <DataSection
    datas={
      [
        {
          cols: ['none', 4],
          componentType: 'grid',
          datas: [
            {
              componentType: 'input',
              labelShow: false,
              name: 'name',
              placeholder: 'Nom',
              wrapperProps: {
                col: ['full', 2],
              },
            },
            {
              componentType: 'input',
              labelShow: false,
              name: 'quantity',
              placeholder: 'Quantity',
              type: 'number',
            },
            {
              componentType: 'input',
              labelShow: false,
              name: 'price',
              placeholder: 'Prix',
              type: 'number',
            },
          ],
          gap: spacing[3],
        },
      ] as any
    }
    name={name}
  />
);

export default ItemEditable;
