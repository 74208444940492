import { Dispatch } from '@innedit/formidable';
import { EspaceData } from '@innedit/innedit';

import { USER_SET_ESPACES } from '../../constants/actionTypes2';

function findByIds(ids: string[]): any {
  return async (dispatch: Dispatch): Promise<any> => {
    const espaceData = new EspaceData({
      orderField: 'name',
    });

    const espaces = await espaceData.findByIds(ids);

    return dispatch({ espaces, type: USER_SET_ESPACES });
  };
}

export default findByIds;
