import { DataFieldArrayProps, FieldArray } from '@innedit/formidable';
import React, { FC } from 'react';

import DataSchemasListRender from './Render';

export interface DataSchemasListProps
  extends Omit<DataFieldArrayProps, 'componentType'> {
  formName: string;
}

const DataSchemasList: FC<DataSchemasListProps> = props => {
  const { name } = props;

  return (
    <FieldArray component={DataSchemasListRender} name={name} props={props} />
  );
};

export default DataSchemasList;
