import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { FC, SyntheticEvent } from 'react';

import Button from '../../../components/Button';

const PaiementModeModal: FC = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleOnSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.stopPropagation();
    event.preventDefault();

    if (stripe && elements) {
      await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
      });
    }
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <PaymentElement />
      <div className="flex justify-center mt-6">
        <Button className="w-full" type="submit">
          Ajouter cette carte
        </Button>
      </div>
    </form>
  );
};
export default PaiementModeModal;
