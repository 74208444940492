import { Box, DataProps, Field, FormSection } from '@innedit/formidable';
import { UserData } from '@innedit/innedit';
import { DocumentType, UserType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';

export interface DataProfessionnelsMultiSelectProps
  extends Omit<DataProps, 'componentType'> {
  espaceId: string;
  label?: string;
  name: string;
  orderDirection?: 'asc' | 'desc';
  orderField?: string;
}
const DataProfessionnelsMultiSelect: FC<DataProfessionnelsMultiSelectProps> = ({
  espaceId,
  className,
  label,
  name,
  orderDirection,
  orderField,
}) => {
  const [options, setOptions] = useState<DocumentType<UserType>[]>();

  useEffect(() => {
    let isMounted = true;
    const userData = new UserData({
      orderDirection,
      orderField,
    });
    const wheres: { [key: string]: any } = {
      hidden: false,
      professionals: {
        operator: 'array-contains',
        value: espaceId,
      },
      stripeAccountHidden: false,
      stripeAccountVerified: true,
    };

    const unsub: () => void = userData.watch(
      docs => {
        if (isMounted) {
          setOptions(docs);
        }

        return isMounted;
      },
      {
        wheres,
      },
    );

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [espaceId, orderDirection, orderField]);

  if (!options || 0 === options.length) {
    return null;
  }

  return (
    <Box>
      <FormSection name={name}>
        <div className={className}>
          <div className="label">
            <span>{label}</span>
          </div>

          {options.map(option => (
            <React.Fragment key={`${name}.${option.id}`}>
              <label
                className="flex flex-row space-x-2 items-center content-center"
                htmlFor={`${name}.${option.id}`}
              >
                <Field
                  component="input"
                  id={`${name}.${option.id}`}
                  name={option.id}
                  type="checkbox"
                />
                <span>{option.libelle}</span>
              </label>
            </React.Fragment>
          ))}
        </div>
      </FormSection>
    </Box>
  );
};

export default DataProfessionnelsMultiSelect;
