import styled from '@emotion/styled';
import React, { FC } from 'react';

import spacing from '../../styles/spacing';

const Translation = styled.div`
  margin-top: ${spacing[3]};
`;

export const TranslationItem = styled.div`
  position: relative;
  padding-left: 30px;
  margin-top: ${spacing[1.5]};
`;
export const TranslationItemLang: FC<{ lang: string }> = ({ lang }) => (
  <div className="absolute left-0">{lang}</div>
);

export default Translation;
