import { DocumentType, MediaType } from '@innedit/innedit-type';
import React, { FC, SyntheticEvent } from 'react';

import ListCard from '../../../../containers/Espace/Card';
import nextPhoto from '../../../../images/next-photo.svg';
import StyledCard from './style';

const ListItemMedia: FC<{
  doc: DocumentType<MediaType>;
  index: number;
  isSelected?: boolean;
  onClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
}> = ({ doc, index, isSelected = false, onClick }) => {
  let photo;
  try {
    if (doc.fullPath) {
      photo = `${String(process.env.GATSBY_IMAGES_DOMAIN_NAME)}/${
        doc.fullPath
      }?w=320&h=320&c=max`;
    } else {
      photo = nextPhoto;
    }
  } catch (e) {
    photo = nextPhoto;
  }

  return (
    <ListCard displayOrder={false} doc={doc} index={index} onClick={onClick}>
      <StyledCard
        className={isSelected ? 'isSelected' : ''}
        isSelected={isSelected}
      >
        <figure className="card-image aspect-ratio aspect-ratio--square">
          <img alt="Chargement" className="aspect-ratio__content" src={photo} />
        </figure>
      </StyledCard>
    </ListCard>
  );
};

export default ListItemMedia;
