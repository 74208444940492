import { Field, FormSection } from '@innedit/formidable';
import { FeatureData } from '@innedit/innedit';
import { DocumentType, FeatureType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';

interface FeaturesMultiSelectSubProps {
  espaceId: string;
  collectionName: string;
  orderDirection?: 'asc' | 'desc';
  orderField?: string;
  name: string;
  parent: string;
}
const FeaturesMultiSelectSub: FC<FeaturesMultiSelectSubProps> = ({
  espaceId,
  collectionName,
  orderDirection,
  orderField,
  name,
  parent,
}) => {
  const [options, setOptions] = useState<DocumentType<FeatureType>[]>();

  useEffect(() => {
    const feature = new FeatureData<FeatureType>({
      collectionName,
      espaceId,
      orderDirection,
      orderField,
    });

    const unsub: () => void = feature.watch(
      newDocs => {
        setOptions(newDocs);
      },
      {
        wheres: {
          parent,
          hidden: false,
        },
      },
    );

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [espaceId, collectionName, orderDirection, orderField, parent]);

  if (!options || 0 === options.length) {
    return null;
  }

  return (
    <FormSection className="ml-3" name={name}>
      {options &&
        options.map(option => (
          <React.Fragment key={`${name}.${option.id}`}>
            <label
              className="flex flex-row space-x-2 items-center content-center"
              htmlFor={`${name}.${option.id}`}
            >
              <Field
                component="input"
                id={`${name}.${option.id}`}
                name={option.id}
                type="checkbox"
              />
              <span>{option.libelle}</span>
            </label>
            <FeaturesMultiSelectSub
              collectionName={collectionName}
              espaceId={espaceId}
              name={`${option.id}`}
              orderDirection={orderDirection}
              orderField={orderField}
              parent={option.id}
            />
          </React.Fragment>
        ))}
    </FormSection>
  );
};

export default FeaturesMultiSelectSub;
