import { Box, DataProps, useSelector } from '@innedit/formidable';
import { ChannelType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';

import { ChannelData } from '../../../submodules/innedit/src';
import ChannelDeployment from '../../components/Channel/Deployment';

export interface ChannelsDeploymentProps
  extends Omit<DataProps, 'componentType'> {
  formName: string;
}

const ChannelsDeployment: FC<ChannelsDeploymentProps> = function ({
  formName,
  params,
}) {
  const [workflowRuns, setWorkflowRuns] = useState<any[]>();
  const { docId, espaceId, user } = params as any;

  useEffect(() => {
    const channelData = new ChannelData({ espaceId });
    const unsub = channelData.watchWorkflowRuns(docId, setWorkflowRuns, {
      limit: 3,
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, []);

  const channel = useSelector(
    (state: { form: { [key: string]: { values: Partial<ChannelType> } } }) =>
      state.form[formName]?.values,
  );

  return (
    <Box>
      <p className="text-light-800 text-center text-sm">
        Le traitement qui est éxécuté pour déploiement le site sur les serveurs
        dure environ 5 minutes. Il est donc recommandé de réaliser toutes les
        mises à jour sur le site en même temps et ensuite de déployer
        l&apos;intégralité des modifications en une seule fois.
      </p>
      {user && (
        <ChannelDeployment
          channelId={docId}
          className="mt-6"
          disabled={
            !channel.deployment?.workflowId ||
            !channel.deployment?.repositoryFullName ||
            !channel.deployment?.token
          }
          espaceId={espaceId}
          user={user}
        />
      )}
      {workflowRuns && workflowRuns.length > 0 && (
        <>
          <h2 className="mt-6 text-light-800 italic">
            Les dernières déploiements :
          </h2>
          <ul>
            {workflowRuns.map(workflowRun => {
              const duree = dayjs(workflowRun.updated_at).diff(
                dayjs(workflowRun.created_at),
                'second',
              );

              const minute = Math.floor(duree / 60);
              const seconde = duree % 60;

              return (
                <li key={workflowRun.id} className="flex justify-between">
                  <span>
                    {dayjs(workflowRun.created_at).format('D MMM à HH:mm')}
                  </span>
                  {'completed' === workflowRun.status && (
                    <span className="text-success-500">{`${minute}'m ${seconde}s`}</span>
                  )}
                  {'queued' === workflowRun.status && (
                    <span className="text-warning-500">
                      En cours de déploiement
                    </span>
                  )}
                  {!['queued', 'completed'].includes(workflowRun.status) && (
                    <span>{workflowRun.status}</span>
                  )}
                </li>
              );
            })}
          </ul>
        </>
      )}
    </Box>
  );
};

export default ChannelsDeployment;
