import { CommandeProduitType } from '@innedit/innedit-type';
import { Link } from 'gatsby';
import React, { FC } from 'react';

interface ItemNoEditableProps {
  espaceId: string;
  item: CommandeProduitType;
}

const ItemNoEditable: FC<ItemNoEditableProps> = ({ espaceId, item }) => (
  <div className="grid grid-cols-4 gap-3">
    <div className="col-span-2 flex ">
      {item.thumbnail && (
        <img alt={item.name} className="h-[60px] mr-3" src={item.thumbnail} />
      )}
      {item.id ? (
        <Link to={`/espaces/${espaceId}/produits/${item.id}/update/`}>
          {item.name}
        </Link>
      ) : (
        <strong>{item.name}</strong>
      )}
    </div>
    <div>{item.quantity || 1}</div>
    <div>{item.price}€</div>
  </div>
);

export default ItemNoEditable;
