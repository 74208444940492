import styled from '@emotion/styled';
import React, { FC, SyntheticEvent } from 'react';

import { colors } from '../../../styles/theme';
import ModalClose from './Close';

interface ModalHeaderProps {
  closeOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  title: string;
}

const HeaderEL = styled.div`
  position: relative;
  border: 0;
  padding: 20px 20px 20px 22.5px;
  border-bottom: 1px solid ${colors.light[600]};

  h3 {
    margin: 0;
    padding: 0;
  }
`;

const ModalHeader: FC<ModalHeaderProps> = ({ title, closeOnClick }) => (
  <HeaderEL>
    <h3 className="text-xl">{title}</h3>
    <ModalClose closeOnClick={closeOnClick} />
  </HeaderEL>
);

export default ModalHeader;
