/* eslint-disable max-len */
import React, { FC } from 'react';

import { IconType } from '../../components/Icon';

const IconMinus: FC<IconType> = ({ className, color, size = 16 }) => (
  <svg
    className={className}
    height={size}
    viewBox="0 0 448 512"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default IconMinus;
