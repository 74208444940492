import { DocumentType, EspaceType, ModelType } from '@innedit/innedit-type';
import FirebaseFirestore from 'firebase/firestore';

import { FindOptionsProp, WatchOptionsProp } from '../../functions';
import Model, { ModelProps } from '../index';

export interface ModelEspaceProps<T> extends ModelProps<T> {
  espace?: DocumentType<EspaceType>;
  espaceId: string;
}

abstract class ModelEspace<T extends ModelType> extends Model<T> {
  public espace?: DocumentType<EspaceType>;
  public espaceId: string;

  constructor(props: ModelEspaceProps<T>) {
    const { espace, espaceId, ...others } = props;
    super({ ...others, firebase: espace?.firebase });

    this.espace = espace;
    this.espaceId = espaceId;
  }

  public clean(values?: Partial<T>, validate?: boolean): Partial<T> {
    return super.clean(
      {
        ...values,
        boutique: this.espaceId,
      } as any,
      validate,
    );
  }

  public async create(data: Partial<T>): Promise<DocumentType<T>> {
    return super.create({ ...data, boutique: this.espaceId });
  }

  public async find(options?: FindOptionsProp): Promise<DocumentType<T>[]> {
    const newOptions = options || {};

    if (!newOptions.wheres) {
      newOptions.wheres = {};
    }

    newOptions.wheres.boutique = this.espaceId;

    return super.find(newOptions);
  }

  public watch(
    next: (docs: DocumentType<T>[]) => void,
    options?: WatchOptionsProp,
  ): FirebaseFirestore.Unsubscribe {
    const newOptions = options || {};

    if (!newOptions.wheres) {
      newOptions.wheres = {};
    }

    newOptions.wheres.boutique = this.espaceId;

    return super.watch(next, newOptions);
  }
}

export default ModelEspace;
