import { Box, DataProps, useSelector } from '@innedit/formidable';
// import fetch from 'node-fetch';
import React, { FC, SyntheticEvent } from 'react';

import Button from '../../components/Button';

// const usePreviousAccountId = (value?: string): string | undefined => {
//   const ref = useRef<string>();
//
//   useEffect(() => {
//     ref.current = value;
//   });
//
//   return ref.current;
// };

export interface DataProfessionnelsKYCProps
  extends Omit<DataProps, 'componentType'> {
  formName: string;
  docId: string;
}

const DataProfessionnelsKYC: FC<DataProfessionnelsKYCProps> = ({
  docId,
  formName,
}) => {
  const { stripeAccountId, stripeAccountToken, stripeAccountVerified } =
    useSelector((globalState: any) => globalState.form[formName].values);
  const handleCreateAccountOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    let url = `${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/users/${docId}`;
    url += `/accounts/create?professionnelId=${docId}`;
    fetch(url).catch(error => {
      console.error(error.message);
    });
  };

  const handleCreateAccountTokenOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    let url = `${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/users/${docId}`;
    url += `/tokens/create?professionnelId=${docId}&type=account`;
    fetch(url).catch(error => {
      console.error(error.message);
    });
  };

  return (
    <Box>
      <h2>Stripe KYC</h2>
      {!stripeAccountId ? (
        <>
          {stripeAccountToken && (
            <Button
              color="neutral"
              onClick={handleCreateAccountOnClick}
              size="sm"
              variant="groove"
            >
              Créer un compte connecté
            </Button>
          )}
          {!stripeAccountToken && (
            <Button
              className="w-full"
              color="neutral"
              onClick={handleCreateAccountTokenOnClick}
              size="sm"
              variant="groove"
            >
              Créer un token account
            </Button>
          )}
        </>
      ) : (
        <div className="text-center text-light-800 flex space-x-1">
          <span>Compte connecté créé</span>
          {stripeAccountVerified ? (
            <span className="text-success-500">et vérifié</span>
          ) : (
            <span className="text-danger-500">et non vérifié</span>
          )}
        </div>
      )}
    </Box>
  );
};

export default DataProfessionnelsKYC;
