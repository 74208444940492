import { ChannelType, DocumentType } from '@innedit/innedit-type';
import FirebaseFirestore, {
  collection,
  limit,
  onSnapshot,
  query,
} from 'firebase/firestore';

import { updateConstraints, WhereProps } from '../../functions';
import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

class Channel extends ModelEspace<ChannelType> {
  constructor(props: Omit<ModelEspaceProps<ChannelType>, 'collectionName'>) {
    super({
      ...props,
      collectionName: 'channels',
      orderDirection: props.orderDirection || 'desc',
      orderField: props.orderField || 'datetime',
    });
  }

  public watchWorkflowRuns(
    docId: string,
    next: (docs: DocumentType<any>[]) => void,
    options?: {
      limit?: number;
      wheres?: WhereProps;
    },
  ): FirebaseFirestore.Unsubscribe {
    let constraints: FirebaseFirestore.QueryConstraint[] = [];

    constraints = updateConstraints(constraints, {
      orderDirection: 'desc',
      orderField: 'created_at',
      wheres: options?.wheres,
    });

    constraints.push(
      limit(
        options?.limit ??
          (parseInt(String(process.env.GATSBY_INNEDIT_WATCH_LIMIT), 10) || 250),
      ),
    );

    return onSnapshot(
      query(
        collection(this.getCollectionRef(), docId, 'workflowRuns'),
        ...constraints,
      ),
      {
        next: querySnaphot => {
          next(querySnaphot.docs.map(d => ({ id: d.id, ...d.data() })));
        },
      },
    );
  }
}

export default Channel;
