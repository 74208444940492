import { DataField, DataFieldProps } from '@innedit/formidable';
import { CollectionData } from '@innedit/innedit';
import { CollectionType, DocumentType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';

export interface DataCollectionsSelectProps
  extends Omit<DataFieldProps, 'componentType'> {
  espaceId: string;
}

const DataCollectionsSelect: FC<DataCollectionsSelectProps> = props => {
  const [collections, setCollections] =
    useState<DocumentType<CollectionType>[]>();
  useEffect(() => {
    let isMounted = true;
    const collectionData = new CollectionData({
      espaceId: props.espaceId,
    });

    collectionData.watch(
      newDocs => {
        if (isMounted) {
          setCollections(newDocs);
        }
      },
      {
        wheres: {
          hidden: false,
        },
      },
    );

    return () => {
      isMounted = false;
    };
  }, []);

  if (!collections || 0 === collections.length) {
    return null;
  }

  return (
    <DataField
      {...props}
      componentType="select"
      options={collections.map(collection => ({
        label: collection.name,
        value: collection.id,
      }))}
    />
  );
};

export default DataCollectionsSelect;
