import { EventData, MediaData } from '@innedit/innedit';
import { DocumentType, MediaType } from '@innedit/innedit-type';
import React, { FC, SyntheticEvent, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import IconFileUpload from '../../icons/FileUpload';
import IconPaste from '../../icons/Paste';
import svg from '../../images/dropzone.svg';
import Button from '../Button';
import HOCGroup from '../Group/HOC';
import Modal from '../View/Modal';
import Media from './Item';
import MediasList from './List';
import { List as ListSC } from './styles';

interface MediasProps {
  accept?: string;
  addItems: (medias: DocumentType<MediaType>[]) => void;
  espaceId: string;
  changePosition: (oldIndex: number, newIndex: number) => void;
  display?: 'inside' | 'group' | 'content';
  removeItem: (index: number) => void;
  items: string[];
  title: string;
}

const Medias: FC<MediasProps> = ({
  accept = 'image/*',
  addItems,
  espaceId,
  changePosition,
  display,
  items,
  removeItem,
  title,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [previews, setPreviews] = useState<string[]>();
  const [isOpenMedias, setIsOpenMedias] = useState<boolean>();

  const mediaData = new MediaData({ espaceId });

  const onDrop = (files: FileList) => {
    const promises: any[] = [];
    const tmpPreviews = [];
    for (let i = 0; i < files.length; i += 1) {
      const media = files.item(i);
      if (media) {
        const preview = URL.createObjectURL(media);
        tmpPreviews.push(preview);

        promises.push(mediaData.uploadFile(media));
      }
    }
    setPreviews(tmpPreviews);

    Promise.all(promises)
      .then(values => {
        addItems(values);

        return setPreviews([]);
      })
      .catch(error => {
        toast.error(error.message);

        return setPreviews([]);
      });
  };

  const handleOnChange = (e: SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (e.currentTarget && e.currentTarget.files) {
      onDrop(e.currentTarget.files);
    }
  };

  const handleOpenBrowser = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const handlePasteOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    const text = await navigator.clipboard.readText();

    let result: DocumentType<MediaType> | undefined;

    try {
      result = await toast.promise(mediaData.uploadUrl(text), {
        error: 'Problème lors du traitement',
        pending: 'Traitement en cours',
        success: 'Enregistrement du média réussi',
      });

      if (result) {
        addItems([result]);
      }
    } catch (e) {
      toast.error((e as Error).message);
    }
  };

  const handleSelectMedias = () => {
    setIsOpenMedias(!isOpenMedias);
  };

  /**
   * Cette methode permet de supprimer un media du tableau
   *
   * @param {number} index
   */
  const handleRemoveItem = (index: number) => {
    if (items[index]) {
      if (items[index]) {
        const eventData = new EventData({ espaceId });
        eventData.create({
          data: {
            object: items[index],
          },
          type: 'media.deleted',
        });
      }

      removeItem(index);
    }
  };

  return (
    <HOCGroup
      customInfos={
        <div className="flex space-x-3 absolute min-h-0 right-0 top-0">
          <Button
            iconLeft={IconPaste}
            onClick={handlePasteOnClick}
            variant="link"
          />
          <Button
            iconLeft={IconFileUpload}
            onClick={handleOpenBrowser}
            variant="link"
          />
        </div>
      }
      display={display}
      title={title}
    >
      {isOpenMedias && (
        <Modal
          closeOnClick={handleSelectMedias}
          title="Selectionner des médias"
        >
          <MediasList addItems={addItems} espaceId={espaceId} />
        </Modal>
      )}

      <div className="group">
        {previews && previews.length > 0 && (
          <span className="loading-in-progress">Chargement en cours</span>
        )}
        <input
          ref={inputRef}
          accept={accept}
          className="hidden"
          multiple
          onChange={handleOnChange}
          type="file"
        />
        {(!items || 0 === items.length) &&
          (!previews || 0 === previews.length) && (
            <div className="flex items-center justify-center">
              <img alt="Upload files" src={svg} width={100} />
            </div>
          )}
        {((items && items.length > 0) || (previews && previews.length > 0)) && (
          <div className="w-full outline-none box-border">
            <ListSC>
              {items.map((mediaId, index) => (
                <Media
                  key={mediaId}
                  changePosition={changePosition}
                  espaceId={espaceId}
                  index={index}
                  mediaId={mediaId}
                  removeItem={handleRemoveItem}
                />
              ))}
              {previews &&
                previews.length > 0 &&
                previews.map(media => (
                  <li key={media}>
                    <figure className="aspect-ratio aspect-ratio--square">
                      <img
                        alt="chargement en cours"
                        className="aspect-ratio__content"
                        src={media}
                        style={{ filter: 'grayscale(1)' }}
                      />
                    </figure>
                  </li>
                ))}
            </ListSC>
          </div>
        )}
      </div>
    </HOCGroup>
  );
};

export default Medias;
