import { EventData, FeatureData } from '@innedit/innedit';
import { ChannelType, DocumentType, UserType } from '@innedit/innedit-type';
import React, { FC, SyntheticEvent, useState } from 'react';

import IconLoading from '../../../icons/Loading';
import Button from '../../Button';

const Deployment: FC<{
  channelId: string;
  className?: string;
  disabled?: boolean;
  espaceId: string;
  user: DocumentType<UserType>;
}> = ({ channelId, className, disabled, espaceId, user }) => {
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [successed, setSuccessed] = useState<boolean>(false);

  const handleDeployWWWOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);

      const channelData = new FeatureData<ChannelType>({
        espaceId,
        collectionName: 'channels',
      });
      const channel: DocumentType<ChannelType> = await channelData.findById(
        channelId,
      );

      if (channel) {
        const { deployment } = channel;

        if (
          !deployment ||
          !deployment.token ||
          !deployment.repositoryFullName ||
          !deployment.workflowId
        ) {
          setError("Le canal n'est pas configuré pour lancer le traitement");
          setLoading(false);
        } else {
          fetch(
            `https://api.github.com/repos/${deployment.repositoryFullName}/actions/workflows/${deployment.workflowId}/dispatches`,
            {
              body: JSON.stringify({
                ref: 'master',
              }),
              headers: {
                Accept: 'application/vnd.github.v3+json',
                Authorization: `Bearer ${deployment.token}`,
                // 'Content-Type': 'application/json',
              },
              method: 'POST',
            },
          )
            .then(() => {
              setLoading(false);

              const eventData = new EventData({ espaceId });
              eventData.create({
                data: {
                  object: channel,
                },
                type: 'channel.deployed',
              });

              return setSuccessed(true);
            })

            .catch(({ message }) => {
              setError(message);
              setLoading(false);
            });
        }
      }
    }
  };

  if (
    'admin' !== user.type &&
    (!user.profils || !user.profils.includes('deploy-www'))
  ) {
    return (
      <p className={className}>
        Vous n&apos;avez pas les autorisations pour déployer ce site.
      </p>
    );
  }

  return (
    <>
      <Button
        className={className}
        color="primary"
        disabled={disabled || Boolean(error) || Boolean(successed)}
        iconRight={loading ? IconLoading : undefined}
        onClick={handleDeployWWWOnClick}
        size="sm"
        text="Déployer"
        variant="outline"
      />
      {successed && (
        <span className="mt-2 text-center text-success-500">
          La demande de déploiement a été enregistré avec succès. <br />
          Le déploiement va apparaitre dans quelques secondes.
        </span>
      )}
      {error && (
        <span className="mt-2 text-center text-danger-500"> {error}</span>
      )}
    </>
  );
};

export default Deployment;
