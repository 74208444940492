import classnames from 'classnames';
import objectHash from 'object-hash';
import React, { FC } from 'react';

import ActionBar from './ActionBar';
import Button, { ButtonProps } from './Button';
import Search from './Search';

export interface MenuType {
  left?: (ButtonProps | ButtonProps[])[];
  right?: (ButtonProps | ButtonProps[])[];
}

export const NavBar: FC<{
  menu?: (ButtonProps | ButtonProps[])[];
}> = ({ menu }) => {
  if (!menu || 0 === menu.length) {
    return null;
  }

  return (
    <ul className="flex space-x-2">
      {menu.map(item => (
        <li key={objectHash(item)} className="flex flex-nowrap">
          {Array.isArray(item) ? (
            <>
              {item.map((subItem, index) => (
                <Button
                  key={objectHash({ ...item, ...subItem })}
                  {...subItem}
                  className={classnames(subItem.className, {
                    'rounded-l-none': index > 0,
                    'rounded-r-none border-r-0': item.length - 1 > index,
                  })}
                />
              ))}
            </>
          ) : (
            <Button {...item} />
          )}
        </li>
      ))}
    </ul>
  );
};

const Menu: FC<{
  displaySearch?: boolean;
  menu?: MenuType;
  q?: string;
}> = ({ displaySearch, menu, q }) => {
  if (!menu || 0 === Object.keys(menu).length) {
    return null;
  }

  return (
    <ActionBar className="action-bar is-clearfix">
      {menu.left && <NavBar menu={menu.left} />}
      <div className="flex flex-row space-x-2 flex-1">
        {displaySearch && <Search q={q} />}
        {menu.right && <NavBar menu={menu.right} />}
      </div>
    </ActionBar>
  );
};

export default Menu;
