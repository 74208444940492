/* eslint-disable max-len */
import React, { FC } from 'react';

import { IconType } from '../../components/Icon';

const IconChevron: FC<IconType> = ({ className, color, size = 24 }) => (
  <svg
    className={className}
    height={size}
    version="1.1"
    viewBox="0 0 24 24"
    width={size}
  >
    <g transform="matrix(1,0,0,1,-2.26108,0.40548)">
      <path
        d="M14.977,17.57L8.953,23.595L7.545,22.187L13.569,16.162L13.567,16.16L18.147,11.57L13.567,6.98L13.569,6.978L7.545,0.954L8.953,-0.454L20.977,11.57L14.977,17.57Z"
        fill={color || 'currentColor'}
      />
    </g>
  </svg>
);

export default IconChevron;
