import { Box, DataProps, Field, FormSection } from '@innedit/formidable';
import { FeatureData } from '@innedit/innedit';
import { DocumentType, FeatureType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';

import FeaturesMultiSelectSub from './Sub';

export interface FeaturesMultiSelectProps
  extends Omit<DataProps, 'componentType'> {
  espaceId: string;
  collectionName: string;
  label?: string;
  name: string;
  orderDirection?: 'asc' | 'desc';
  orderField?: string;
  type?: 'all' | 'parent';
}
const FeaturesMultiSelect: FC<FeaturesMultiSelectProps> = ({
  espaceId,
  className,
  collectionName,
  label,
  name,
  orderDirection,
  orderField,
  type = 'all',
}) => {
  const [options, setOptions] = useState<DocumentType<FeatureType>[]>();

  useEffect(() => {
    const feature = new FeatureData<FeatureType>({
      collectionName,
      espaceId,
      orderDirection,
      orderField,
    });
    const wheres: { [key: string]: any } = {
      hidden: false,
    };

    if ('parent' === type) {
      wheres.parent = '';
    }

    const unsub: () => void = feature.watch(docs => setOptions(docs), {
      wheres,
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [espaceId, collectionName, orderDirection, orderField]);

  if (!options || 0 === options.length) {
    return null;
  }

  return (
    <Box>
      <FormSection name={name}>
        <div className={className}>
          <div className="label">
            <span>{label}</span>
          </div>

          {options.map(option => (
            <React.Fragment key={`${name}.${option.id}`}>
              <label
                className="flex flex-row space-x-2 items-center content-center"
                htmlFor={`${name}.${option.id}`}
              >
                <Field
                  component="input"
                  id={`${name}.${option.id}`}
                  name={option.id}
                  type="checkbox"
                />
                <span>{option.libelle}</span>
              </label>
              {'parent' === type && (
                <FeaturesMultiSelectSub
                  collectionName={collectionName}
                  espaceId={espaceId}
                  name={option.id}
                  orderDirection={orderDirection}
                  orderField={orderField}
                  parent={option.id}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </FormSection>
    </Box>
  );
};

export default FeaturesMultiSelect;
