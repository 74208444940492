import {
  Box,
  change,
  DataFieldArrayProps,
  FieldArray,
  useDispatch,
  useSelector,
} from '@innedit/formidable';
import { FeatureData } from '@innedit/innedit';
import { FeatureType, recursiveFeatures } from '@innedit/innedit-type';
import React, { FC, useEffect } from 'react';

import { ReducersProps } from '../../../reducers';
import DimensionsRender from './Render';

export interface DataProduitsDimensionsProps
  extends Omit<DataFieldArrayProps, 'componentType'> {
  espaceId: string;
  formName: string;
  name: string;
}

const DataProduitsDimensions: FC<DataProduitsDimensionsProps> = props => {
  const { espaceId, formName, name } = props;
  const dispatch = useDispatch();
  const values = useSelector(
    (globalState: ReducersProps) => globalState.form[formName].values,
  );

  const features: string[] =
    values && values.features ? recursiveFeatures(values.features) : [];
  const dimensions = values && values.dimensions;

  useEffect(() => {
    if (features.length > 0) {
      const featureData = new FeatureData({
        espaceId,
        collectionName: 'features',
      });

      featureData
        .findByIds(features.slice(0, 9))
        .then(docs => {
          if (docs.length > 0) {
            // On récupére toutes les dimensions
            return docs.map(feature => {
              const featureDimensionData = new FeatureData<FeatureType>({
                espaceId,
                collectionName: 'dimensions',
                parentCollectionName: 'features',
                parentId: feature.id,
              });

              return featureDimensionData
                .find({
                  wheres: {
                    deleted: false,
                    hidden: false,
                  },
                })
                .then(newDimensions => {
                  if (newDimensions) {
                    const tmpDimensions = dimensions ? [...dimensions] : [];
                    let addOne = false;
                    newDimensions.forEach(doc => {
                      if (
                        !dimensions ||
                        !dimensions.some(
                          (d: { id?: string }) => d.id && d.id === doc.id,
                        )
                      ) {
                        addOne = true;
                        const data: { [key: string]: any } = { ...doc };

                        delete data.boutique;
                        delete data.createdAt;
                        delete data.datetime;
                        delete data.deleted;
                        delete data.hidden;
                        delete data.parent;
                        delete data.updatedAt;
                        tmpDimensions.push({
                          ...doc,
                          id: doc.id,
                          valeur: '',
                        });
                      }
                    });

                    if (addOne) {
                      dispatch(change(formName, 'dimensions', tmpDimensions));
                    }
                  }

                  return true;
                })
                .catch((error: Error) => {
                  console.error(error);
                });
            });
          }

          return true;
        })
        .catch((error: Error) => {
          console.error(error);
        });
    }
  }, [espaceId, features, dimensions, dispatch, formName]);

  return (
    <Box>
      <FieldArray component={DimensionsRender} name={name} props={props} />
    </Box>
  );
};

export default DataProduitsDimensions;
