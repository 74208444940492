import styled from '@emotion/styled';
import React, { FC, SyntheticEvent } from 'react';

import Button from '../../../components/Button';
import IconClose from '../../../icons/Close';
import Select from '../Select';

const ListItemEL = styled.div`
  select {
    min-width: calc(100% - 50px);
  }
`;

const ListItem: FC<{
  formName: string;
  index: number;
  name: string;
  removeOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
}> = ({ formName, index, name, removeOnClick }) => (
  <ListItemEL>
    <Select
      customAction={
        <div className="flex">
          <Button
            className="rounded-l-none"
            color="neutral"
            data-index={index}
            iconLeft={IconClose}
            onClick={removeOnClick}
            variant="field"
          />
        </div>
      }
      fieldProps={{ className: 'rounded-r-none border-r-0' }}
      formName={formName}
      labelShow={false}
      name={name}
    />
  </ListItemEL>
);

export default ListItem;
