import { DataFieldArrayProps, FieldArray } from '@innedit/formidable';
import React, { FC } from 'react';

import PricesTiersRender from './Render';

export interface PricesTiersProps
  extends Omit<DataFieldArrayProps, 'componentType'> {
  espaceId: string;
}

const PricesTiers: FC<PricesTiersProps> = props => (
  <FieldArray {...props} component={PricesTiersRender} />
);

export default PricesTiers;
