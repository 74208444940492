import styled from '@emotion/styled';
import TextareaAutosize from 'react-textarea-autosize';

import spacing from '../../styles/spacing';
import { ControlSC } from '../../utils/getControlStyle';

const TextareaControl = styled(ControlSC)`
  line-height: 1.5rem;
  padding-top: ${spacing[3]};
  padding-bottom: ${spacing[3]};
  box-sizing: border-box;
`;

const Textarea = TextareaControl.withComponent(TextareaAutosize);

export default Textarea;
