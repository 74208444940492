/* eslint-disable max-len */
import { DataProps } from '@innedit/formidable';
import { Facia } from '@innedit/innedit';
import React, { FC, SyntheticEvent } from 'react';

import Button from '../../../components/Button';
import ButtonGroup from '../../../components/Button/Group';

export interface DataIntelligenceCollaborativeProps
  extends Omit<DataProps, 'componentType'> {
  formName: string;
  itemField: string;
}

// Facia - from interfacial: Of or pertaining to an interface

const DataIntelligenceCollaborative: FC<DataIntelligenceCollaborativeProps> = ({
  formName,
  itemField,
  params,
}) => {
  const data = params && params[formName];

  const handleSynonymesOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    const [q] = data && data[itemField] ? data[itemField].split(' ') : [];
    event.preventDefault();

    Facia.call({ q, kind: 'synonyme' })
      .then(synonymes => {
        console.info('synonymes', synonymes);

        return null;
      })
      .catch(console.error);
  };

  const handleDictionnaireOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    Facia.call({ kind: 'dictionnaire', q: data[itemField] })
      .then(dictionnaire => {
        console.info('dictionnaire', dictionnaire);

        return null;
      })
      .catch(console.error);
  };

  return (
    <ButtonGroup>
      <Button color="neutral" onClick={handleSynonymesOnClick} variant="groove">
        Synonymes
      </Button>
      <Button
        color="neutral"
        onClick={handleDictionnaireOnClick}
        variant="groove"
      >
        Dictionnaire
      </Button>
    </ButtonGroup>
  );
};
export default DataIntelligenceCollaborative;
