/* eslint-disable max-len */
import React, { FC } from 'react';

const IconClose: FC<{ className?: string; color?: string; size?: number }> = ({
  className,
  color,
  size = 16,
}) => (
  <svg className={className} height={size} viewBox="0 0 16 16" width={size}>
    <path
      d="M9.40785 7.99784L13.7034 3.71204C14.0942 3.32016 14.0942 2.68471 13.7034 2.29391C13.3115 1.90203 12.676 1.90203 12.2852 2.29391L7.99838 6.58945L3.71367 2.29391C3.32287 1.90203 2.68742 1.90203 2.29553 2.29391C1.90365 2.68471 1.90365 3.32016 2.29553 3.71204L6.59107 7.99892L2.29553 12.2836C2.10609 12.4709 2 12.7275 2 12.9927C2 13.259 2.10609 13.5145 2.29553 13.7028C2.48281 13.8912 2.73938 13.9984 3.0046 13.9984C3.27091 13.9984 3.52639 13.8912 3.71367 13.7028L8.00054 9.40623L12.2852 13.7028C12.4736 13.8912 12.7291 13.9984 12.9943 13.9984C13.2617 13.9984 13.5161 13.8912 13.7045 13.7028C13.8928 13.5145 14 13.259 14 12.9927C14 12.7264 13.8928 12.4709 13.7045 12.2836L9.40785 7.99784Z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default IconClose;
