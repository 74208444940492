import React, { FC, memo, SyntheticEvent } from 'react';

import Button from '../../../components/Button';
import IconDelete from '../../../icons/Delete';

interface ItemProps {
  data: {
    id: string;
    libelle: string;
    valeur: string;
  };
  handleRemove: (event: SyntheticEvent<HTMLButtonElement>) => void;
  name: string;
}
const Item: FC<ItemProps> = ({ data, handleRemove, name }) => (
  <div className="flex space-x-1 mb-3">
    {data.libelle}
    <input type="text" value={data.valeur} />
    {}
    <div className="flex space-x-1 self-end">
      {data.id && (
        <Button
          color="secondary"
          tooltip={data.id}
          type="tooltip"
          variant="groove"
        />
      )}
      <Button
        color="danger"
        iconLeft={IconDelete}
        onClick={handleRemove}
        size="sm"
        variant="outline"
      />
    </div>
  </div>
);

export default memo(
  Item,
  (prev, next) =>
    prev.name === next.name && prev.data.valeur === next.data.valeur,
);
