import { Dispatch } from '@innedit/formidable';

import findChannelByEspaceId from '../channel/findByEspaceId';
import findCollectionByEspaceId from '../collection/findByEspaceId';

function load(id: string): any {
  return (dispatch: Dispatch): void => {
    dispatch(findChannelByEspaceId(id));
    dispatch(findCollectionByEspaceId(id));
  };
}

export default load;
