import { MediaData } from '@innedit/innedit';
import { DocumentType, MediaType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import compact from 'lodash/compact';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import ActionBar from '../ActionBar';
import Button from '../Button';
import Item from '../List/Item/Media';

const MediasList: FC<{
  addItems: (medias: DocumentType<MediaType>[]) => void;
  espaceId: string;
}> = ({ addItems, espaceId }) => {
  const [ids, setIds] = useState<string[]>([]);
  const [medias, setMedias] = useState<DocumentType<MediaType>[]>();
  const [nbParLigne, setNbParLigne] = useState<number>(5);

  useEffect(() => {
    let unsub: () => void;

    if (espaceId) {
      const mediaData = new MediaData({
        espaceId,
      });
      unsub = mediaData.watchNotUse(
        newDocs => {
          setMedias(newDocs);
        },
        {
          espaceId,
        },
      );
    }

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [espaceId]);

  const handleOnChangeNbParLigne = (e: SyntheticEvent<HTMLInputElement>) => {
    setNbParLigne(parseInt(e.currentTarget.value, 10));
  };

  if (!medias || 0 === medias.length) {
    return null;
  }

  const handleOnClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.getAttribute('data-id');
    if (id) {
      const newIds = [...ids];
      const index = ids.findIndex(tmp => 0 === tmp.localeCompare(id));
      if (index >= 0) {
        newIds.splice(index, 1);
      } else {
        newIds.push(id);
      }

      setIds(newIds);
    }
  };

  const handleSelectMedias = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (ids && ids.length > 0) {
      const mediaData = new MediaData({
        espaceId,
      });
      const promises: Promise<void>[] = [];
      const newItems = compact(
        ids.map(id => {
          const media = medias.find(m => m.id === id);

          if (media) {
            promises.push(
              mediaData.update(media.id, {
                updatedAt: dayjs().toISOString(),
              }),
            );
          }

          return media;
        }),
      );

      Promise.all(promises)
        .then(() => addItems(newItems))
        .catch(error => {
          console.error(error.message);
        });
    }
  };

  return (
    <>
      <ActionBar className="action-bar is-clearfix">
        <ul className="border-left navbar-right">
          <li>
            <Button
              onClick={handleSelectMedias}
              text="Selectionner ces photos"
            />
          </li>
          <li className="navbar-item nbByRow">
            <input
              onChange={handleOnChangeNbParLigne}
              type="number"
              value={nbParLigne}
            />
          </li>
        </ul>
      </ActionBar>
      <ol>
        {medias.map((media, index) => (
          <Item
            key={media.id}
            doc={media}
            index={index}
            isSelected={ids.includes(media.id)}
            onClick={handleOnClick}
          />
        ))}
      </ol>
    </>
  );
};

export default MediasList;
