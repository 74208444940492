import { MediaData } from '@innedit/innedit';
import { DocumentType, MediaType } from '@innedit/innedit-type';
import classnames from 'classnames';
import React, { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import IconClipboard from '../../icons/Clipboard';
import IconDelete from '../../icons/Delete';
import IconDownload from '../../icons/Download';
import IconEdit from '../../icons/Edit';
import nextPhoto from '../../images/next-photo.svg';
import Button from '../Button';

const ItemTypes = {
  MEDIA: 'media',
};

const Media: FC<{
  espaceId: string;
  index: number;
  isDragging: boolean;
  mediaId: string;
  removeOnClick: (e: SyntheticEvent<HTMLButtonElement>) => void;
}> = ({ espaceId, index, isDragging, mediaId, removeOnClick }) => {
  const [media, setMedia] = useState<DocumentType<MediaType>>();

  useEffect(() => {
    const mediaData = new MediaData({ espaceId });
    const unsub = mediaData.watchById(mediaId, document => setMedia(document));

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [espaceId, mediaId]);

  let newMedia;
  try {
    if (media) {
      newMedia = `${String(process.env.GATSBY_IMAGES_DOMAIN_NAME)}/${
        media.fullPath
      }`;
      newMedia += 0 === index ? '?w=640&h=640&c=max' : '?w=320&h=320&c=max';
    } else {
      newMedia = nextPhoto;
    }
  } catch (e) {
    newMedia = nextPhoto;
  }

  const handleClipboardOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (navigator && navigator.clipboard && media) {
      navigator.clipboard.writeText(
        `${String(process.env.GATSBY_IMAGES_DOMAIN_NAME)}/${media.fullPath}`,
      );
    }
  };

  const handleDownloadOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (window && media) {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = `${String(process.env.GATSBY_IMAGES_DOMAIN_NAME)}/${
        media.fullPath
      }`;
      img.onload = () => {
        // create Canvas

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (ctx) {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          // create a tag
          const a = document.createElement('a');
          a.download = media.name;
          a.href = canvas.toDataURL(media.type);
          a.click();
        }
      };
    }
  };

  return (
    <div
      className={classnames(
        {
          isDragging,
        },
        'relative',
      )}
    >
      <figure className="aspect-ratio aspect-ratio--square">
        <img
          alt="chargement"
          className="aspect-ratio__content"
          src={newMedia}
        />
      </figure>
      <div className="hover-overlay">
        {media && (
          <div className="text-sm text-white-50 text-center">
            <div>{media.type}</div>
            {media.width && media.height && (
              <div>{`${media.width} x ${media?.height} px`}</div>
            )}
            {media.size && (
              <div>{`${Math.round(media.size / 10000) / 100} Mo`}</div>
            )}
          </div>
        )}
        <div className="flex justify-around">
          <Button
            color="light"
            data-index={index}
            iconLeft={IconClipboard}
            onClick={handleClipboardOnClick}
            variant="link"
          />
          <Button
            color="light"
            data-index={index}
            iconLeft={IconDownload}
            onClick={handleDownloadOnClick}
            variant="link"
          />
          <Button
            color="light"
            data-index={index}
            iconLeft={IconEdit}
            to={`/espaces/${espaceId}/medias/${media?.id}/update/`}
            variant="link"
          />
          <Button
            color="light"
            data-index={index}
            iconLeft={IconDelete}
            onClick={removeOnClick}
            variant="link"
          />
        </div>
      </div>
    </div>
  );
};

interface DragMedia {
  index: number;
  id: string;
  type: string;
}

const MediaPosition: FC<{
  espaceId: string;
  changePosition: (oldIndex: number, newIndex: number) => void;
  index: number;
  mediaId: string;
  removeItem: (index: number) => void;
}> = ({ espaceId, changePosition, removeItem, index, mediaId }) => {
  const ref = useRef<HTMLLIElement>(null);

  const handleRemoveOnClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const i = e.currentTarget.getAttribute('data-index');

    if (i) {
      if (window.confirm('Voulez-vous vraiment supprimer ce média ?')) {
        removeItem(parseInt(i, 10));
      }
    }
  };

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.MEDIA,
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
    }),
    drop(item: DragMedia) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      changePosition(dragIndex, hoverIndex);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    item: { index, id: mediaId, type: ItemTypes.MEDIA },
    type: ItemTypes.MEDIA,
  });

  drag(drop(ref));

  return (
    <li ref={ref} className={isOver ? 'isOver' : ''}>
      <Media
        espaceId={espaceId}
        index={index}
        isDragging={isDragging}
        mediaId={mediaId}
        removeOnClick={handleRemoveOnClick}
      />
    </li>
  );
};

export default MediaPosition;
