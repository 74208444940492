import { MediaData } from '@innedit/innedit';
import { DocumentType, MediaType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';

import nextPhoto from '../../images/next-photo.svg';

interface MediaProps {
  className?: string;
  espaceId: string;
  id: string;
  options?: string;
}

const Media: FC<MediaProps> = ({ className, espaceId, id, options }) => {
  const [media, setMedia] = useState<DocumentType<MediaType>>();

  useEffect(() => {
    const mediaData = new MediaData({ espaceId });
    mediaData.findById(id).then(setMedia).catch(console.error);
  }, [espaceId, id]);

  if (!media) {
    return <div>En attente</div>;
  }

  let newMedia;
  try {
    if (media) {
      newMedia = `${String(process.env.GATSBY_IMAGES_DOMAIN_NAME)}/${
        media.fullPath
      }`;
      newMedia += options ? `?${options}` : '?w=320&h=320&c=max';
    } else {
      newMedia = nextPhoto;
    }
  } catch (e) {
    newMedia = nextPhoto;
  }

  return <img alt="chargement" className={className} src={newMedia} />;
};

export default Media;
