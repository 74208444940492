import { DataField } from '@innedit/formidable';
import { DataFieldSelectProps } from '@innedit/formidable/dist/components/Data/Field/Render/Select';
import { PageData } from '@innedit/innedit';
import { DocumentType, PageType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';

export interface DataPagesSelectProps extends DataFieldSelectProps {
  channelId: string;
  className?: string;
  espaceId: string;
  formName: string;
  id?: string;
  label?: string;
}
const DataPagesSelect: FC<
  Omit<DataPagesSelectProps, 'options' | 'componentType'>
> = ({ channelId, className, espaceId, formName, name, id, label }) => {
  const [options, setOptions] = useState<DocumentType<PageType>[]>([]);

  useEffect(() => {
    const pageData = new PageData({ channelId, espaceId });
    pageData
      .find()
      .then(docs => {
        setOptions(docs);

        return true;
      })
      .catch(e => {
        throw new Error(`loadPages : Page.search(boutique) : ${e.message}`);
      });
  }, [channelId, espaceId, id]);

  if (!options || 0 === options.length) {
    return null;
  }

  return (
    <DataField
      className={className}
      componentType="select"
      formName={formName}
      label={label}
      name={name}
      options={options.map(item => ({
        label: item.name,
        value: item.id,
      }))}
    />
  );
};

export default DataPagesSelect;
