import { PaiementItemType } from '@innedit/innedit-type';
import React, { FC, SyntheticEvent } from 'react';
import { toast } from 'react-toastify';

import Button from '../../../../components/Button';
import ButtonGroup from '../../../../components/Button/Group';
import IconCheckCircle from '../../../../icons/CheckCircle';
import IconEdit from '../../../../icons/Edit';
import ProfessionnelsItem from './Item';

interface ProfessionnelsProps {
  espaceId: string;
  index: number;
  item: PaiementItemType;
  paiementId: string;
}

const Professionnels: FC<ProfessionnelsProps> = ({
  espaceId,
  index,
  paiementId,
  item: { price, professionnels, quantity, refundedAmount, transferredAt },
}) => {
  const handleTransfertOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const url = `espaces/${espaceId}/transfers/create?paiement=${paiementId}&item=${index}`;

    fetch(`${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/${url}`)
      .then(r => r.json())
      .catch(toast.error);
  };

  if (professionnels) {
    const hasConnected = Object.keys(professionnels).reduce(
      (acc, key) => acc || Boolean(professionnels[key].destination),
      false,
    );

    return (
      <div className="mt-1.5 bg-light-500 p-2 rounded text-xs">
        {!transferredAt && (
          <ButtonGroup className="float-right space-x-2">
            <Button
              color="neutral"
              iconLeft={IconEdit}
              size="sm"
              variant="link"
            />
            {hasConnected && (
              <Button
                color="neutral"
                iconLeft={IconCheckCircle}
                onClick={handleTransfertOnClick}
                size="sm"
                variant="link"
              />
            )}
          </ButtonGroup>
        )}

        <h2>Professionnels</h2>
        {Object.keys(professionnels).length > 0 && (
          <div className="flex flex-col space-y-1 mt-2">
            {Object.keys(professionnels).map(key => (
              <ProfessionnelsItem
                key={key}
                itemAmount={price * quantity}
                professionel={professionnels[key]}
                refundedAmount={refundedAmount}
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default Professionnels;
