import {
  Box,
  DataWithChildren,
  DataWithChildrenProps,
} from '@innedit/formidable';
import { FeatureData } from '@innedit/innedit';
import { DocumentType, FeatureType } from '@innedit/innedit-type';
import classnames from 'classnames';
import React, { FC, useEffect, useState } from 'react';

export interface DataLanguagesTraductionsProps
  extends Omit<DataWithChildrenProps, 'componentType'> {
  espaceId: string;
  formName: string;
  name: string;
  type?: 'minimal' | 'group';
}

const DataLanguagesTraductions: FC<DataLanguagesTraductionsProps> = ({
  espaceId,
  className,
  datas,
  formName,
  name,
  type,
}) => {
  const [langues, setLangues] = useState<DocumentType<FeatureType>[]>();

  useEffect(() => {
    let isMounted = true;

    const featureData = new FeatureData<FeatureType>({
      espaceId,
      collectionName: 'langues',
      orderDirection: 'desc',
      orderField: 'datetime',
      parentCollectionName: `boutiques`,
      parentId: espaceId,
    });

    featureData
      .find({
        wheres: {
          hidden: false,
        },
      })
      .then(documents => {
        if (isMounted) {
          setLangues(documents);
        }

        return true;
      })
      .catch(error => console.error(error.message));

    return () => {
      isMounted = false;
    };
  }, [espaceId]);

  if (!langues || 0 === langues.length) {
    return null;
  }

  if ('minimal' === type) {
    return (
      <>
        {langues &&
          langues.map(langue => (
            <Box
              key={langue.id}
              className={classnames('flex flex-row !w-auto', className)}
            >
              <div className="pt-5">
                <div className="transform -rotate-90">{langue.libelle}</div>
              </div>
              <div className="flex-1 w-full">
                <DataWithChildren
                  componentType="flex"
                  datas={[
                    {
                      datas,
                      formName,
                      componentType: 'section',
                      name: `${name}.${langue.id}`,
                    } as DataWithChildrenProps,
                  ]}
                  formName={formName}
                />
              </div>
            </Box>
          ))}
      </>
    );
  }

  return (
    <>
      {langues &&
        langues.map(langue => (
          <DataWithChildren
            key={langue.id}
            componentType="group"
            datas={[
              {
                datas,
                formName,
                componentType: 'section',
                name: `${name}.${langue.id}`,
              } as DataWithChildrenProps,
            ]}
            formName={formName}
            title={langue.get('libelle')}
          />
        ))}
    </>
  );
};

export default DataLanguagesTraductions;
