import { DataFieldProps, FieldArray } from '@innedit/formidable';
import { WhereProps } from '@innedit/innedit';
import React, { FC } from 'react';

import Render from './Render';

export interface DataFeaturesSelectSubProps
  extends Omit<DataFieldProps, 'componentType'> {
  espaceId: string;
  collectionName: string;
  formName: string;
  orderDirection?: 'asc' | 'desc';
  orderField?: string;
  parentId?: string;
  wheres?: WhereProps;
}

const DataFeaturesSelectSub: FC<DataFeaturesSelectSubProps> = props => {
  const { name } = props;

  return <FieldArray component={Render} name={name} props={props} />;
};

export default DataFeaturesSelectSub;
