import { DataProps } from '@innedit/formidable';
import React, { FC } from 'react';

import Boutique, { DataBoutiquesProps } from './Boutique';
import DataChannelsCrud, { DataChannelsCrudProps } from './Channels/Crud';
import ChannelsDeployment, {
  ChannelsDeploymentProps,
} from './Channels/Deployment';
import ChannelsList, { ChannelsListProps } from './Channels/List';
import DataCollectionsSelect, {
  DataCollectionsSelectProps,
} from './Collections/Select';
import DataCommandesProduits, {
  DataCommandesProduitsProps,
} from './Commandes/Produits';
import DataContactsSelect, { DataContactsSelectProps } from './Contacts/Select';
import DataContentsList, { DataContentsListProps } from './Contents/List';
import DataDocumentsSelect, {
  DataDocumentsSelectProps,
} from './Documents/Select';
import DataEspacesList, { DataEspacesListProps } from './Espaces/List';
import DataEspacesSelect, { DataEspacesSelectProps } from './Espaces/Select';
import DataFeaturesList, { DataFeaturesListProps } from './Features/List';
import FeaturesMultiSelect, {
  FeaturesMultiSelectProps,
} from './Features/MultiSelect';
import DataFeaturesSelect, { DataFeaturesSelectProps } from './Features/Select';
import DataFeaturesSelectSub, {
  DataFeaturesSelectSubProps,
} from './Features/SelectSub';
import DataGoogleSearch, { DataGoogleSearchProps } from './Google/Search';
import DataHyperlinks, { DataHyperLinkProps } from './Hyperlinks';
import DataIntelligenceCollaborative, {
  DataIntelligenceCollaborativeProps,
} from './Intelligence/Collaborative';
import DataLanguagesTraductions, {
  DataLanguagesTraductionsProps,
} from './Languages/Traductions';
import Medias, { MediasProps } from './Medias';
import DataPagesList, { DataPagesListProps } from './Pages/List';
import DataPagesSelect, { DataPagesSelectProps } from './Pages/Select';
import DataPaiementItems, { DataPaiementItemsProps } from './Paiement/Items';
import DataPaiementManager, {
  DataPaiementManagerProps,
} from './Paiement/Manager';
import DataPaiementsMode, { DataPaiementsModeProps } from './Paiements/Mode';
import DataPricesCrud, { DataPricesCrudProps } from './Prices/Crud';
import DataPricesSelect, { DataPricesSelectProps } from './Prices/Select';
import PricesTiers, { PricesTiersProps } from './Prices/Tiers';
import DataProduitParent, { DataProduitParentProps } from './Produit/Parent';
import DataProduitPrices, { DataProduitPricesProps } from './Produit/Prices';
import DataProduitSlots, { DataProduitSlotsProps } from './Produit/Slots';
import ProduitsAvailability, {
  ProduitsAvailabilityProps,
} from './Produits/Availability';
import DataProduitsDimensions, {
  DataProduitsDimensionsProps,
} from './Produits/Dimensions';
import DataProduitsEstimationLivraison, {
  DataProduitsEstimationLivraisonProps,
} from './Produits/EstimationLivraison';
import DataProduitsInventaire, {
  DataProduitsInventaireProps,
} from './Produits/Inventaire';
import DataProfessionnelsKYC, {
  DataProfessionnelsKYCProps,
} from './Professionnels/KYC';
import DataProfessionnelsMultiSelect, {
  DataProfessionnelsMultiSelectProps,
} from './Professionnels/MultiSelect';
import DataProfessionnelsSelect, {
  DataProfessionnelsSelectProps,
} from './Professionnels/Select';
import SchemasDatas, { SchemasDatasProps } from './Schemas/Datas';
import DataSchemaDatasLabel, {
  DataSchemasDatasLabelProps,
} from './Schemas/Datas/Label';
import DataSchemasList, { DataSchemasListProps } from './Schemas/List';
import DataSchemasSelect, { DataSchemasSelectProps } from './Schemas/Select';
import TarificationsSelect, {
  TarificationsSelectProps,
} from './Tarifications/Select';
import DataUtilityCounter, { DataUtilityCounterProps } from './Utility/Counter';
import DataUtilityQRCode, { DataUtilityQRCodeProps } from './Utility/QRCode';

const Data: FC<unknown> = props => {
  const { componentType, params } = props as DataProps;
  let componentProps;

  switch (componentType) {
    case 'boutique': {
      componentProps = props as DataBoutiquesProps;
      if (!componentProps.type) {
        return (
          <div>
            {componentType} : erreur de paramètre : type est obligatoire
          </div>
        );
      }

      return <Boutique {...componentProps} espaceId={params?.espaceId} />;
    }

    case 'channels/crud': {
      componentProps = props as Partial<DataChannelsCrudProps>;

      return <DataChannelsCrud {...componentProps} />;
    }

    case 'channels/deployment': {
      componentProps = props as Partial<ChannelsDeploymentProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <ChannelsDeployment
          {...componentProps}
          formName={componentProps.formName}
        />
      );
    }

    case 'channels/list': {
      componentProps = props as Partial<ChannelsListProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return (
        <ChannelsList
          {...componentProps}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'collections/select': {
      componentProps = props as Partial<DataCollectionsSelectProps>;
      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      return (
        <DataCollectionsSelect
          {...componentProps}
          espaceId={params.espaceId}
          name={componentProps.name}
        />
      );
    }

    case 'commandes/produits': {
      componentProps = props as Partial<DataCommandesProduitsProps>;

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return (
        <DataCommandesProduits
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'contacts/select': {
      componentProps = props as Partial<DataContactsSelectProps>;

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return (
        <DataContactsSelect
          {...componentProps}
          espaceId={params?.espaceId}
          name={componentProps.name}
        />
      );
    }

    case 'contents/list': {
      componentProps = props as Partial<DataContentsListProps>;

      if (!componentProps.editPathname) {
        return (
          <div>
            {componentType}: erreur de paramètre : editPathname est obligatoire
          </div>
        );
      }

      if (!params?.parentCollectionName && !params?.collectionName) {
        return (
          <div>
            {componentType}: erreur de paramètre : parentCollectionName ou
            collectionName est obligatoire
          </div>
        );
      }

      return (
        <DataContentsList
          {...componentProps}
          editPathname={componentProps.editPathname}
          espaceId={params?.espaceId}
          parentCollectionName={
            params.parentCollectionName || params.collectionName
          }
          parentId={params.docId}
        />
      );
    }

    case 'documents/select': {
      componentProps = props as Partial<DataDocumentsSelectProps>;

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!componentProps.collectionId) {
        return (
          <div>
            {componentType}: erreur de paramètre : collectionId est obligatoire
          </div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      return (
        <DataDocumentsSelect
          {...componentProps}
          collectionId={componentProps.collectionId}
          espaceId={params.espaceId}
          name={componentProps.name}
        />
      );
    }

    case 'espaces/select': {
      componentProps = props as Partial<DataEspacesSelectProps>;
      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return (
        <DataEspacesSelect {...componentProps} name={componentProps.name} />
      );
    }

    case 'espaces/list': {
      componentProps = props as Partial<DataEspacesListProps>;

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.title) {
        return (
          <div>
            {componentType}: erreur de paramètre : title est obligatoire
          </div>
        );
      }

      return (
        <DataEspacesList
          {...componentProps}
          formName={componentProps.formName}
          name={componentProps.name}
          title={componentProps.title}
        />
      );
    }

    case 'features/list': {
      componentProps = props as Partial<DataFeaturesListProps<unknown>>;

      if (!componentProps.collectionName) {
        return (
          <div>
            {componentType}: erreur de paramètre : collectionName est
            obligatoire
          </div>
        );
      }

      return (
        <DataFeaturesList
          {...componentProps}
          collectionName={componentProps.collectionName}
          espaceId={params?.espaceId}
        />
      );
    }

    case 'features/multiselect': {
      componentProps = props as Partial<FeaturesMultiSelectProps>;

      if (!params?.espaceId) {
        return (
          <div>
            {componentType} : erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }
      if (!componentProps.collectionName) {
        return (
          <div>
            {componentType} : erreur de paramètre : collectionName est
            obligatoire
          </div>
        );
      }
      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }

      return (
        <FeaturesMultiSelect
          {...props}
          collectionName={componentProps.collectionName}
          espaceId={params.espaceId}
          name={componentProps.name}
        />
      );
    }

    case 'features/select': {
      componentProps = props as Partial<DataFeaturesSelectProps>;
      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }
      if (!componentProps.collectionName) {
        return (
          <div>
            {componentType}: erreur de paramètre : collectionName est
            obligatoire
          </div>
        );
      }
      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return (
        <DataFeaturesSelect
          {...componentProps}
          collectionName={componentProps.collectionName}
          espaceId={params.espaceId}
          name={componentProps.name}
        />
      );
    }

    case 'features/select-sub': {
      componentProps = props as Partial<DataFeaturesSelectSubProps>;
      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }
      if (!componentProps.collectionName) {
        return (
          <div>
            {componentType}: erreur de paramètre : collectionName est
            obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return (
        <DataFeaturesSelectSub
          {...componentProps}
          collectionName={componentProps.collectionName}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'intelligence/collaborative': {
      componentProps = props as Partial<DataIntelligenceCollaborativeProps>;
      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.itemField) {
        return (
          <div>
            {componentType}: erreur de paramètre : itemField est obligatoire
          </div>
        );
      }

      return (
        <DataIntelligenceCollaborative
          {...componentProps}
          formName={componentProps.formName}
          itemField={componentProps.itemField}
        />
      );
    }

    case 'languages/traductions': {
      componentProps = props as Partial<DataLanguagesTraductionsProps>;
      if (!componentProps.datas) {
        return (
          <div>
            {componentType}: erreur de paramètre : datas est obligatoire
          </div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }
      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataLanguagesTraductions
          {...componentProps}
          datas={componentProps.datas}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'medias': {
      componentProps = props as Partial<MediasProps>;

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return (
        <Medias
          {...componentProps}
          espaceId={params.espaceId}
          name={componentProps.name}
        />
      );
    }

    case 'paiement/manager': {
      componentProps = props as Partial<DataPaiementManagerProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      return (
        <DataPaiementManager
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
        />
      );
    }

    case 'paiement/items': {
      componentProps = props as Partial<DataPaiementItemsProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      return (
        <DataPaiementItems
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'pages/list': {
      componentProps = props as Partial<DataPagesListProps>;

      return <DataPagesList {...componentProps} espaceId={params?.espaceId} />;
    }

    case 'pages/select': {
      componentProps = props as Partial<DataPagesSelectProps>;

      if (!params?.channelId) {
        return (
          <div>
            {componentType}: erreur de paramètre : channelId est obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return (
        <DataPagesSelect
          {...componentProps}
          channelId={params.channelId}
          espaceId={params?.espaceId}
          formName={componentProps.formName}
          id={params?.docId}
          name={componentProps.name}
        />
      );
    }

    case 'prices/crud': {
      componentProps = props as Partial<DataPricesCrudProps>;

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!params.collectionName) {
        return (
          <div>
            {componentType}: erreur de paramètre : params.collectionName est
            obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.editPathname) {
        return (
          <div>
            {componentType}: erreur de paramètre : editPathname est obligatoire
          </div>
        );
      }

      return (
        <DataPricesCrud
          {...componentProps}
          docCollectionName={params.collectionName}
          docId={params.docId}
          editPathname={componentProps.editPathname}
          espaceId={params.espaceId}
          formName={componentProps.formName}
        />
      );
    }

    case 'prices/select': {
      componentProps = props as Partial<DataPricesSelectProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!params.collectionName) {
        return (
          <div>
            {componentType}: erreur de paramètre : params.collectionName est
            obligatoire
          </div>
        );
      }

      return (
        <DataPricesSelect
          {...componentProps}
          docCollectionName={params.collectionName}
          docId={params.docId}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'paiements/mode': {
      componentProps = props as Partial<DataPaiementsModeProps>;

      if (!params?.user) {
        return (
          <div>{componentType}: erreur de paramètre : user est obligatoire</div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return (
        <DataPaiementsMode
          {...componentProps}
          name={componentProps.name}
          user={params.user}
        />
      );
    }

    case 'prices/tiers': {
      componentProps = props as Partial<PricesTiersProps>;

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return (
        <PricesTiers
          {...componentProps}
          espaceId={params.espaceId}
          name={componentProps.name}
        />
      );
    }

    case 'produit/parent': {
      componentProps = props as Partial<DataProduitParentProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!params?.produitId) {
        return (
          <div>
            {componentType}: erreur de paramètre : produitId est obligatoire
          </div>
        );
      }

      return (
        <DataProduitParent
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          produitId={params.produitId}
        />
      );
    }

    case 'produit/prices': {
      componentProps = props as Partial<DataProduitPricesProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!params?.produitId) {
        return (
          <div>
            {componentType}: erreur de paramètre : produitId est obligatoire
          </div>
        );
      }

      return (
        <DataProduitPrices
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          produitId={params.produitId}
        />
      );
    }

    case 'produit/slots': {
      componentProps = props as Partial<DataProduitSlotsProps>;

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!params?.produitId) {
        return (
          <div>
            {componentType}: erreur de paramètre : produitId est obligatoire
          </div>
        );
      }

      return (
        <DataProduitSlots
          {...componentProps}
          espaceId={params.espaceId}
          produitId={params.produitId}
        />
      );
    }

    case 'produits/availability': {
      componentProps = props as Partial<ProduitsAvailabilityProps>;

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      return (
        <ProduitsAvailability
          {...componentProps}
          docId={params.docId}
          espaceId={params.espaceId}
        />
      );
    }

    case 'produits/dimensions': {
      componentProps = props as Partial<DataProduitsDimensionsProps>;
      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return (
        <DataProduitsDimensions
          {...componentProps}
          espaceId={params.espaceId}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'produits/estimation-livraison':
      componentProps = props as Partial<DataProduitsEstimationLivraisonProps>;
      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataProduitsEstimationLivraison
          {...componentProps}
          docId={params.docId}
          espaceId={params.espaceId}
          formName={componentProps.formName}
        />
      );

    case 'produits/inventaire': {
      componentProps = props as Partial<DataProduitsInventaireProps>;

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : params.espaceId est
            obligatoire
          </div>
        );
      }

      if (!params.collectionName) {
        return (
          <div>
            {componentType}: erreur de paramètre : params.collectionName est
            obligatoire
          </div>
        );
      }

      return (
        <DataProduitsInventaire
          {...componentProps}
          docCollectionName={params.collectionName}
          docId={params.docId}
          espaceId={params.espaceId}
        />
      );
    }

    case 'professionnels/kyc': {
      componentProps = props as Partial<DataProfessionnelsKYCProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }
      if (!params || !params.docId) {
        return (
          <div>
            {componentType}: erreur de paramètre : params.docId est obligatoire
          </div>
        );
      }

      return (
        <DataProfessionnelsKYC
          {...props}
          docId={params.docId}
          formName={componentProps.formName}
        />
      );
    }

    case 'professionnels/multiselect': {
      componentProps = props as Partial<DataProfessionnelsMultiSelectProps>;

      if (!params?.espaceId) {
        return (
          <div>
            {componentType} : erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }

      return (
        <DataProfessionnelsMultiSelect
          {...componentProps}
          espaceId={params.espaceId}
          name={componentProps.name}
        />
      );
    }

    case 'professionnels/select': {
      componentProps = props as Partial<DataProfessionnelsSelectProps>;

      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataProfessionnelsSelect
          {...componentProps}
          docId={params?.docId}
          espaceId={params?.espaceId}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'google/search': {
      componentProps = props as Partial<DataGoogleSearchProps>;

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      if (!componentProps.field) {
        return (
          <div>
            {componentType}: erreur de paramètre : field est obligatoire
          </div>
        );
      }

      if (!componentProps.datas) {
        return (
          <div>
            {componentType}: erreur de paramètre : datas est obligatoire
          </div>
        );
      }

      return (
        <DataGoogleSearch
          {...componentProps}
          datas={componentProps.datas}
          field={componentProps.field}
          formName={componentProps.formName}
        />
      );
    }

    case 'hyperlinks': {
      componentProps = props as Partial<DataHyperLinkProps>;

      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }
      if (!componentProps.formName) {
        return (
          <div>
            {componentType} : erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataHyperlinks
          {...componentProps}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'schemas/datas-label': {
      componentProps = props as Partial<DataSchemasDatasLabelProps>;
      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }
      if (!componentProps.formName) {
        return (
          <div>
            {componentType} : erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataSchemaDatasLabel
          {...componentProps}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'schemas/datas': {
      componentProps = props as Partial<SchemasDatasProps>;
      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }
      if (!componentProps.formName) {
        return (
          <div>
            {componentType} : erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <SchemasDatas
          {...componentProps}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'schemas/list': {
      componentProps = props as Partial<DataSchemasListProps>;
      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }
      if (!componentProps.formName) {
        return (
          <div>
            {componentType} : erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataSchemasList
          {...componentProps}
          formName={componentProps.formName}
          name={componentProps.name}
        />
      );
    }

    case 'schemas/select': {
      componentProps = props as Partial<DataSchemasSelectProps>;
      if (!componentProps.name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }

      return (
        <DataSchemasSelect {...componentProps} name={componentProps.name} />
      );
    }

    case 'tarifications/select': {
      componentProps = props as TarificationsSelectProps;

      if (!params?.espaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : espaceId est obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }
      if (!componentProps.name) {
        return (
          <div>{componentType}: erreur de paramètre : name est obligatoire</div>
        );
      }

      return (
        <TarificationsSelect
          {...componentProps}
          espaceId={params.espaceId}
          name={componentProps.name}
        />
      );
    }

    case 'utility/counter': {
      componentProps = props as Partial<DataUtilityCounterProps>;

      if (!componentProps.field) {
        return (
          <div>
            {componentType}: erreur de paramètre : field est obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataUtilityCounter
          {...componentProps}
          field={componentProps.field}
          formName={componentProps.formName}
        />
      );
    }

    case 'utility/qrcode': {
      componentProps = props as Partial<DataUtilityQRCodeProps>;

      if (!componentProps.field) {
        return (
          <div>
            {componentType}: erreur de paramètre : field est obligatoire
          </div>
        );
      }

      if (!componentProps.formName) {
        return (
          <div>
            {componentType}: erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataUtilityQRCode
          formName={componentProps.formName}
          {...componentProps}
          field={componentProps.field}
        />
      );
    }

    default:
  }

  return null;
};

export default Data;
