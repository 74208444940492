import { css } from '@emotion/react';
import { rem, rgba } from 'polished';

import { borderRadius, colors, spacing } from '../../../styles/theme';
import { ButtonColorType, ButtonVariantType } from '../props';

const getButtonVariantProps = ({
  color,
  selected,
  variant,
}: {
  color?: ButtonColorType;
  selected?: boolean;
  variant?: ButtonVariantType;
}): any => {
  switch (variant) {
    case 'card': {
      return css`
        background: transparent;
        border: none;
        outline: 0;
        color: ${colors.neutral['300']};
        text-align: center;
        text-decoration: none;
        padding: 0 !important;
        &:hover {
          color: ${colors.neutral['500']} !important;
          background: transparent;
        }
      `;
    }

    case 'calendar-day': {
      return css`
        width: 40px;
        max-width: 40px;
        height: 40px;
        border-radius: 50%;
        color: ${color ? colors[color][300] : colors.primary[200]};
      `;
    }

    case 'clear': {
      return css`
        background: none;
        border: none;
        color: ${color ? colors[color][500] : colors.primary[500]};
        text-decoration: none;
        padding: 0 !important;
        min-width: auto !important;
        min-height: auto !important;

        &:disabled {
          color: ${colors.black[300]};
        }

        &:hover {
          background: transparent;
          color: ${color ? colors[color][600] : colors.primary[600]};
        }
      `;
    }

    case 'groove': {
      return css`
        background: ${color
          ? rgba(colors[color][200], 0.5)
          : colors.transparent};
        border-color: ${color ? colors[color][500] : colors.transparent};
        border-radius: ${borderRadius.xs};
        text-align: center;

        &:hover {
          background: ${color
            ? rgba(colors[color][600], 0.5)
            : colors.transparent};
          border-color: ${color ? colors[color][500] : colors.transparent};
        }
      `;
    }

    case 'field': {
      return css`
        border-radius: ${borderRadius.xs};
        text-align: center;
        height: ${rem(42)};
        border-color: #afb4be;
      `;
    }

    case 'filter': {
      return css`
        min-height: ${rem(42)};
        background: ${colors.transparent};
        border-radius: ${borderRadius.xs};
        border-color: ${colors.light[600]};
        color: ${colors.dark[600]};
        font-weight: 400;

        &:hover {
          background: ${colors.light[500]};
          border-color: ${colors.light[600]};
        }

        ${selected &&
        css`
          background: ${colors.light[600]};
          border-color: ${colors.light[600]};
          opacity: 1;
        `}
      `;
    }

    case 'ghost': {
      return css`
        background: ${colors.transparent};
        border: none;
        text-align: center;
        border-radius: ${borderRadius.xs};

        &:hover {
          background: ${color
            ? rgba(colors[color][200], 0.5)
            : colors.transparent};
        }
      `;
    }

    case 'link': {
      return css`
        background: none;
        border: none;
        color: ${color ? colors[color][500] : colors.black[300]};
        text-decoration: underline;
        padding: 0 !important;
        min-width: auto !important;
        min-height: auto !important;

        &:hover {
          color: ${color ? colors[color][800] : colors.black[600]};
          background: none;
          border: none;
        }
      `;
    }

    case 'menu': {
      return css`
        width: calc(100% - ${spacing[2]} - ${spacing[2]});
        text-align: left;
        justify-content: start;
        text-decoration: none;
        background: none;
        border: none;
        color: ${colors.dark[600]};
        font-weight: 400;
        padding: 0 ${spacing[2]};
        box-sizing: border-box;
        overflow: visible !important;

        font-size: inherit !important;
        justify-content: space-between;
        margin: 0 ${spacing[2]};
        border-radius: ${spacing[1]};

        div {
          flex-grow: 1;
          padding: ${spacing[1]} ${spacing[2]};
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &:hover {
          background-color: ${colors.neutral[100]};
        }

        &.is-open {
          color: ${colors.primary[500]};
        }

        &.is-active {
          color: ${colors.primary[500]};
          &:before {
            content: '';
            height: calc(100% - ${spacing[2]});
            border-left: 3px solid ${colors.primary[500]};
            position: absolute;
            transform: translate(-${spacing[4]});
            border-bottom-right-radius: ${spacing[1]};
            border-top-right-radius: ${spacing[1]};
          }

          background-color: ${colors.neutral[100]};
        }
      `;
    }

    case 'outline': {
      return css`
        background: ${colors.transparent};
        border-radius: ${borderRadius.xs};
        text-align: center;

        &:hover {
          background: ${color
            ? rgba(colors[color][200], 0.5)
            : colors.transparent};
        }
      `;
    }

    case 'sub-menu': {
      return css`
        width: 100%;
        text-align: left;
        justify-content: start;
        text-decoration: none;
        background: none;
        border: none;
        color: ${colors.dark[400]};
        font-weight: 300;
        padding: 0 ${spacing[2]};
        box-sizing: border;
        font-size: inherit !important;

        span {
          width: 100%;
          height: 32px;
          padding: ${spacing[1]} ${spacing[2]} ${spacing[1]} ${spacing[10]};
          border-radius: ${spacing[1]};
        }

        &:hover {
          background: none;

          > span {
            background-color: ${colors.neutral[100]};
          }
        }

        &.is-active {
          color: ${colors.primary[500]};

          > span {
            background-color: ${colors.neutral[100]};
          }

          &:before {
            content: '';
            height: calc(100% - ${spacing[2]});
            border-left: 3px solid ${colors.primary[500]};
            position: absolute;
            transform: translate(-${spacing[2]});
            border-bottom-right-radius: ${spacing[1]};
            border-top-right-radius: ${spacing[1]};
          }
        }
      `;
    }

    case 'tab': {
      return css`
        background: none;
        border-top: 0;
        border-right: 0;
        border-bottom: 3px solid transparent;
        border-left: 0;
        color: ${colors.dark[400]};
        display: block;
        float: none;
        font-size: 14px;
        padding: 8px 10px;
        white-space: nowrap;

        &:hover {
          color: ${colors.primary[500]};
          background: none;
        }
        &.is-active {
          background: 0 0;
          border-bottom-color: ${colors.primary[500]};
          border-radius: 0;
          color: ${colors.primary[500]};
          font-weight: 400;
        }
      `;
    }

    case 'solid':
    default: {
      return css`
        border-radius: ${borderRadius.xs};
        text-align: center;
        text-decoration: none;
      `;
    }
  }
};

export default getButtonVariantProps;
