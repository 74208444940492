import { useSelector } from '@innedit/formidable';
import { CommandeData } from '@innedit/innedit';
import { CommandeType } from '@innedit/innedit-type';
import React, { FC } from 'react';

import StyledResume from '../../components/Resume';

interface DataCommandesResumeProps {
  formName: string;
}
const DataCommandesResume: FC<DataCommandesResumeProps> = ({ formName }) => {
  const {
    deliveryAmount,
    deliveryMethod,
    produits,
    carrierIsPaidDirectly,
  }: CommandeType = useSelector((state: any) => state.form[formName].values);

  if (produits) {
    const produitsAmout = CommandeData.calculateProduitsAmount(produits);
    const total =
      produitsAmout + ('carrier' === deliveryMethod ? deliveryAmount : 0);

    return (
      <StyledResume>
        <div className="analytics">
          <div className="total">
            <span className="label">Total</span>
            <span>{total}€</span>
          </div>
          <div className="infos">
            <h2>Détails</h2>
            <div className="datas">
              <div className="produits">
                <span>{produitsAmout}€</span>
                <span className="label">Produits</span>
              </div>
              {'carrier' === deliveryMethod && (
                <div>
                  <span>{deliveryAmount}€</span>
                  <span className="label">
                    Livraison {!!carrierIsPaidDirectly && '(au transporteur)'}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div />
      </StyledResume>
    );
  }

  return null;
};

export default DataCommandesResume;
