import styled from '@emotion/styled';

const ModalContent = styled.div`
  flex: 1;
  padding: 1.5rem;
  overflow-y: auto;

  .tab {
    margin: 3rem auto;
  }
  -webkit-overflow-scrolling: touch;
`;

export default ModalContent;
