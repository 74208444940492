import { DataField, DataFieldProps } from '@innedit/formidable';
import { UserData } from '@innedit/innedit';
import { DocumentType, UserType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export interface DataProfessionnelsSelectProps
  extends Omit<DataFieldProps, 'componentType'> {
  espaceId?: string;
  docId: string;
  formName: string;
}

const DataProfessionnelsSelect: FC<DataProfessionnelsSelectProps> = ({
  espaceId,
  docId,
  formName,
  label,
  name,
}) => {
  const [professionnels, setProfessionnels] =
    useState<DocumentType<UserType>[]>();

  useEffect(() => {
    let isMounted = true;

    const userData = new UserData({
      orderDirection: 'asc',
      orderField: 'libelle',
    });

    const wheres: { [key: string]: any } = {
      hidden: false,
      stripeAccountHidden: false,
      stripeAccountVerified: true,
    };

    if (espaceId) {
      wheres.professionals = {
        operator: 'array-contains',
        value: espaceId,
      };
    }

    userData
      .find({
        wheres,
      })
      .then(documents => {
        if (isMounted) {
          setProfessionnels(documents);
        }

        return true;
      })
      .catch(toast.error);

    return () => {
      isMounted = false;
    };
  }, [espaceId]);

  if (!docId) {
    return null;
  }

  if (!professionnels) {
    return <p>Chargement en cours</p>;
  }

  if (0 === professionnels.length) {
    return <p>Aucun professionnel</p>;
  }

  return (
    <DataField
      componentType="select"
      formName={formName}
      label={label}
      name={name}
      options={professionnels.map(doc => ({
        label: `${doc.libelle}`,
        value: doc.id,
      }))}
    />
  );
};

export default DataProfessionnelsSelect;
