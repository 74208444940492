import { DocumentType, SlotType } from '@innedit/innedit-type';
import React, { FC } from 'react';

import NotRecurringItem from './NotRecurringItem';
import RecurringItems from './RecurringItems';

interface SlotsProps {
  espaceId: string;
  datas?: DocumentType<SlotType>[];
  produitId: string;
}

const Slots: FC<SlotsProps> = ({ espaceId, datas, produitId }) => {
  if (!datas || 0 === datas.length) {
    return null;
  }

  const recurringSlots = datas.filter(d => d.isRecurring);
  const notRecurringSlots = datas.filter(d => !d.isRecurring);

  return (
    <div className="flex flex-col space-y-2">
      {notRecurringSlots &&
        notRecurringSlots.length > 0 &&
        notRecurringSlots
          .sort((a, b) => (a.date || 'Z').localeCompare(b.date || '0'))
          .map(data => (
            <NotRecurringItem
              key={data.id}
              data={data}
              espaceId={espaceId}
              produitId={produitId}
            />
          ))}
      {recurringSlots && recurringSlots.length > 0 && (
        <RecurringItems
          datas={recurringSlots}
          espaceId={espaceId}
          produitId={produitId}
        />
      )}
    </div>
  );
};

export default Slots;
