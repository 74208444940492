import { SearchResponse } from '@algolia/client-search';
import {
  DocumentType,
  FirebaseConfigType,
  ModelType,
} from '@innedit/innedit-type';
import algoliasearch from 'algoliasearch';
import dayjs from 'dayjs';
import FirebaseFirestore, {
  addDoc,
  collection,
  doc,
  documentId,
  Firestore,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore';
import { mergeWith } from 'lodash';

import { auth, firestore } from '../../config/firebase';
import { removeUndefined } from '../../utils/functions';
import initializeValues from '../../utils/initializeValues';
import requiredData from '../../utils/requiredData';
import {
  FindOptionsProp,
  getAlgoliaFilters,
  SearchOptionsProp,
  updateConstraints,
  WatchOptionsProp,
  WhereProps,
} from '../functions';

export interface ListTabType {
  allowSorting?: boolean;
  className?: string;
  label: string;
  itemMode?: 'grid' | 'list';
  orderDirection?: 'asc' | 'desc';
  orderField?: string;
  pathname: string;
  wheres?: WhereProps;
}

export interface ModelProps<T> {
  addButtonLabel?: string;
  collectionName: string;
  datas?: { [key: string]: any };
  firebase?: FirebaseConfigType;
  itemFieldTitle?: keyof T;
  orderDirection?: 'asc' | 'desc';
  orderField?: string;
  params?: { [key: string]: any };
  parentCollectionName?: string;
  parentId?: string;
  tabs?: ListTabType | ListTabType[];
  wheres?: WhereProps;
}

abstract class Model<T extends ModelType> {
  public addButtonLabel: string;
  public collectionName: string;
  public datas?: { [key: string]: any };
  public firebase?: FirebaseConfigType;

  public itemFieldTitle: keyof T;

  public params: { [key: string]: any };
  public parentCollectionName?: string;
  public parentId?: string;
  public orderDirection: 'asc' | 'desc';
  public orderField: string;

  public tabs?: ListTabType[];

  public wheres: WhereProps = {};

  protected constructor(props: ModelProps<T>) {
    const {
      addButtonLabel,
      collectionName,
      datas,
      firebase,
      orderDirection,
      orderField,
      params,
      parentCollectionName,
      parentId,
      tabs,
      itemFieldTitle,
      wheres,
    } = props;

    if (!collectionName) {
      throw new Error('collectionName obligatoire');
    }

    this.addButtonLabel = addButtonLabel || 'Ajouter';
    this.collectionName = collectionName;
    this.datas = datas;
    this.firebase = firebase;
    this.orderDirection = orderDirection || 'desc';
    this.orderField = orderField || 'datetime';
    this.params = params || {};
    this.parentCollectionName = parentCollectionName;
    this.parentId = parentId;
    this.tabs = tabs && !Array.isArray(tabs) ? [tabs] : tabs;
    this.itemFieldTitle = itemFieldTitle ?? 'createdAt';
    this.wheres = wheres || {};
  }

  public async create(data: Partial<T>): Promise<DocumentType<T>> {
    const user = auth.currentUser;
    if (!user) {
      throw new Error(
        "L'utilisateur doit être connecté pour enregistrer un document",
      );
    }

    const docRef = await addDoc<T>(
      this.getCollectionRef(),
      this.clean({ createdByUser: user.uid, ...data }, true) as T,
    );

    return getDoc<T>(docRef).then(documentSnapshot => ({
      id: documentSnapshot.id,
      ...(documentSnapshot.data() as T),
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  public delete(id: string): Promise<void> {
    const user = auth.currentUser;

    if (!user) {
      throw new Error(
        "L'utilisateur doit être connecté pour supprimer un document",
      );
    }

    const date = dayjs().toISOString();
    const ref = doc(this.getCollectionRef(), id);

    return updateDoc<ModelType>(ref, {
      deleted: true,
      deletedAt: date,
      updatedAt: date,
      updatedByUser: user.uid,
    });
  }

  public async duplicate(
    id: string,
    data: T,
  ): Promise<FirebaseFirestore.DocumentReference<T>> {
    if (!data) {
      throw new Error('Le document a dupliqué ne possède aucune donnée');
    }

    const docRef = doc(
      this.getFirestore(),
      this.collectionName,
    ) as FirebaseFirestore.DocumentReference<T>;

    await setDoc(docRef, this.clean({ ...data, parent: id }, true) as T);

    return docRef;
  }

  protected getCollectionRef(): FirebaseFirestore.CollectionReference<T> {
    let path = this.collectionName;
    let paths: string[] = [];

    if (this.parentId && this.parentCollectionName) {
      [path, ...paths] = this.parentCollectionName.split('/');
      if (!paths) {
        paths = [];
      }
      paths.push(this.parentId);
      paths.push(this.collectionName);
    }

    return collection(
      this.getFirestore(),
      path,
      ...paths,
    ) as FirebaseFirestore.CollectionReference<T>;
  }

  // eslint-disable-next-line class-methods-use-this
  protected getFirestore(): Firestore {
    return firestore;
  }

  protected getParams(): { [key: string]: any } {
    return this.datas || this.params;
  }

  public initialize(data?: Partial<T>): Partial<T> {
    const initializeData = this.initializeData(data);

    return this.clean(initializeData) as Partial<T>;
  }

  private initializeData(data?: Partial<T>): Partial<T> {
    const datas = initializeValues<T>(this.getParams());

    return {
      ...datas,
      ...data,
    } as Partial<T>;
  }

  public extractAttributes(
    data: Partial<T>,
    initialValues?: Partial<T>,
  ): Partial<T> {
    const attributes: Partial<T> = {};
    const initializedData = initialValues || initializeValues(this.getParams());
    Object.keys(initializedData).forEach(key => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      attributes[key] = data[key];
    });

    return attributes;
  }

  public extractRequiredAttributes(): { [key: string]: any } {
    return requiredData(this.getParams());
  }

  public async find(options?: FindOptionsProp): Promise<DocumentType<T>[]> {
    let constaints = [where('deleted', '==', false)];

    constaints = updateConstraints(constaints, {
      orderDirection: options?.orderDirection || this.orderDirection,
      orderField: options?.orderField || this.orderField,
      wheres: options?.wheres
        ? mergeWith(options.wheres, this.wheres)
        : this.wheres,
    });

    if (options?.startAfter) {
      constaints.push(startAfter(options.startAfter));
    }

    constaints.push(
      limit(
        options?.limit ??
          (parseInt(String(process.env.GATSBY_INNEDIT_WATCH_LIMIT), 10) || 250),
      ),
    );
    const q = query(this.getCollectionRef(), ...constaints);

    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map(d => ({ id: d.id, ...(d.data() as T) }));
  }

  public async findById(id: string): Promise<DocumentType<T>> {
    const documentSnapshot = await getDoc<T>(
      doc(
        this.getCollectionRef(),
        id,
      ) as FirebaseFirestore.DocumentReference<T>,
    );

    if (
      !documentSnapshot ||
      !documentSnapshot.exists() ||
      documentSnapshot.get('deleted')
    ) {
      throw new Error("Le document n'existe pas ou a été supprimé");
    }

    return {
      id,
      ...(documentSnapshot.data() as T),
    };
  }

  public async findByIds(ids: string[]): Promise<DocumentType<T>[]> {
    const promises = ids.map(id => getDoc(doc(this.getCollectionRef(), id)));

    const docs = await Promise.all(promises);
    // const q = query<T>(
    //   this.getCollectionRef(),
    //   where(documentId(), 'in', ids.slice(0, 10)),
    //   // orderBy(this.orderField, this.orderDirection),
    // );
    //
    // const querySnapshot = await getDocs<T>(q);

    return docs
      .sort((a, b) => {
        const aField = a.get(this.orderField);
        const bField = b.get(this.orderField);

        if ('asc' === this.orderDirection) {
          if ('string' === typeof aField) {
            return aField.localeCompare(bField);
          }

          return aField - bField;
        }

        if ('string' === typeof aField) {
          return bField.localeCompare(aField);
        }

        return bField - aField;
      })
      .map(d => ({
        id: d.id,
        ...(d.data() as T),
      }));
  }

  public async search(
    q: string,
    options: SearchOptionsProp = {},
  ): Promise<SearchResponse<T>> {
    const defaultNbParPage = 40;

    const algoliaIndexName = String(
      process.env.GATSBY_ALGOLIA_INDEX_NAME,
    ).toUpperCase();
    const index = `${this.collectionName}_${algoliaIndexName}`;

    // Recherches avec Algolia
    const wheres = options.wheres
      ? mergeWith(options.wheres, this.wheres)
      : this.wheres;

    const searchResponse = await algoliasearch(
      String(process.env.GATSBY_ALGOLIA_APP_ID),
      String(process.env.GATSBY_ALGOLIA_SEARCH_KEY),
    )
      .initIndex(index)
      .search(q, {
        ...options.params,
        analytics: false,
        attributesToRetrieve: options.attributesToRetrieve || ['*'],
        filters: getAlgoliaFilters(wheres),
        getRankingInfo: true,
        hitsPerPage: options.nbParPage || defaultNbParPage,
        page: options.page || 0,
        responseFields: options.responseFields || ['*'],
      });

    // if (q) {
    //   // Enregistrement de la recherche pour gérer un historique
    //   const rechercheData = new RechercheData({ boutique: this.boutique });
    //   const pathname = slug(q);
    //   rechercheData
    //     .findByPathname(pathname, options)
    //     .then(querySnapshot => {
    //       if (querySnapshot.empty) {
    //         const data = rechercheData.initialize({
    //           pathname,
    //           language: options.language,
    //           query: q,
    //         });
    //
    //         return rechercheData.create(data);
    //       }
    //
    //       const document = querySnapshot.docs[0];
    //
    //       return document.ref
    //         .update({
    //           nbSearchs: increment(1),
    //         })
    //         .then(() => document.ref);
    //     })
    //     .catch(error => {
    //       throw error;
    //     });
    // }

    return searchResponse as SearchResponse<T>;
  }

  public ref(id: string): FirebaseFirestore.DocumentReference {
    return doc(this.getFirestore(), this.collectionName, id);
  }

  public async set(id: string, values: Partial<T>): Promise<void> {
    if (!auth.currentUser) {
      throw new Error(
        "L'utilisateur doit être connecté pour mettre à jour un document",
      );
    }

    const ref = doc<T>(this.getCollectionRef(), id);
    const documentSnapshot = await getDoc<T>(ref);

    const newData = this.clean({ ...documentSnapshot.data(), ...values }, true);

    return setDoc<T>(ref, newData as T);
  }

  public update(id: string, values: Partial<T>): Promise<void> {
    if (!auth.currentUser) {
      throw new Error(
        "L'utilisateur doit être connecté pour mettre à jour un document",
      );
    }

    const ref = doc<T>(this.getCollectionRef(), id);

    return updateDoc(ref, {
      ...this.clean(values, true),
    } as FirebaseFirestore.UpdateData<T>);
  }

  protected allRequiredIsValid = (values?: { [key: string]: any }): boolean => {
    const requiredKeys = this.extractRequiredAttributes();

    Object.keys(requiredKeys).forEach(key => {
      if (requiredKeys[key] && 'boolean' === typeof requiredKeys[key]) {
        // le champs est obligatoire
        if (!values || !values[key]) {
          throw new Error(`${key} obligatoire`);
        }
      }
    });

    return true;
  };

  public clean(values?: Partial<T>, validate?: boolean): Partial<T> {
    const date = dayjs();

    if (validate && !this.allRequiredIsValid(values)) {
      throw new Error('Non validé');
    }

    const createdAt = values?.createdAt || date.toISOString();

    const cleanData = removeUndefined({
      ...values,
      createdAt,
      datetime:
        values?.datetime && !Number.isNaN(values.datetime)
          ? values.datetime
          : dayjs(createdAt).valueOf(),
      deleted: values?.deleted ?? false,
      hidden: values?.hidden || false,
      updatedAt: date.toISOString(),
      updatedByUser: auth.currentUser?.uid,
    });

    return {
      ...cleanData,
      parent: values?.parent || '',
    } as Partial<T>;
  }

  public watch(
    next: (docs: DocumentType<T>[]) => void,
    options?: WatchOptionsProp,
  ): FirebaseFirestore.Unsubscribe {
    let constraints = [where('deleted', '==', false)];

    constraints = updateConstraints(constraints, {
      orderDirection: options?.orderDirection || this.orderDirection,
      orderField: options?.orderField || this.orderField,
      wheres: options?.wheres
        ? mergeWith(options.wheres, this.wheres)
        : this.wheres,
    });

    if (options?.startAfter) {
      constraints.push(startAfter(options.startAfter));
    }
    constraints.push(
      limit(
        options?.limit ??
          (parseInt(String(process.env.GATSBY_INNEDIT_WATCH_LIMIT), 10) || 250),
      ),
    );

    return onSnapshot(query(this.getCollectionRef(), ...constraints), {
      next: querySnaphot => {
        next(querySnaphot.docs.map(d => ({ id: d.id, ...d.data() })));
      },
    });
  }

  public watchById(
    id: string,
    next: (
      snapshot?: DocumentType<T>,
      metadata?: FirebaseFirestore.SnapshotMetadata,
    ) => void,
  ): FirebaseFirestore.Unsubscribe {
    const ref = doc<T>(this.getCollectionRef(), id);

    return onSnapshot(ref, {
      next: snapshot => {
        if (!snapshot || !snapshot.exists() || snapshot.get('deleted')) {
          return next(undefined);
        }

        const document = {
          id,
          ...(snapshot.data() as T),
        };

        return next(document, snapshot.metadata);
      },
    });
  }

  public watchByIds(
    ids: string[],
    next: (snapshot: DocumentType<T>[]) => void,
  ): FirebaseFirestore.Unsubscribe {
    const constraints = [where(documentId(), 'in', ids)];

    const q = query(this.getCollectionRef(), ...constraints);

    return onSnapshot(q, {
      next: snapshot =>
        next(
          snapshot.docs.map(d => ({
            id: d.id,
            ...d.data(),
          })),
        ),
    });
  }
}

export default Model;
