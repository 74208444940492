import { DataProps, useSelector } from '@innedit/formidable';
import { DeliveryData, FeatureData } from '@innedit/innedit';
import {
  FeatureType,
  PanierShippingInfoType,
  ProduitType,
} from '@innedit/innedit-type';
import React, { useEffect, useState, VoidFunctionComponent } from 'react';
import { toast } from 'react-toastify';

import TransportRule from './TransportRule';

export interface DataProduitsEstimationLivraisonProps
  extends Omit<DataProps, 'componentType'> {
  espaceId: string;
  docId?: string;
  formName: string;
}

const DataProduitsEstimationLivraison: VoidFunctionComponent<
  DataProduitsEstimationLivraisonProps
> = ({ espaceId, docId, formName, params }) => {
  const [transports, setTransports] = useState<any[]>([]);
  const values: ProduitType = useSelector(
    (state: any) => state.form[formName].values,
  ) as ProduitType;

  useEffect(() => {
    let isMounted = true;
    let unsub: any;

    if (docId) {
      const featureData = new FeatureData<FeatureType>({
        espaceId,
        collectionName: `livraisons`,
        orderDirection: 'desc',
        orderField: 'datetime',
      });

      unsub = featureData.watch(newDocs => {
        const promises = newDocs.map(doc => {
          const featureValeurData = new FeatureData<FeatureType>({
            espaceId,
            collectionName: `valeurs`,
            parentCollectionName: `livraisons`,
            parentId: doc.id,
          });

          return new Promise((resolve, reject) =>
            featureValeurData
              .find({
                wheres: {
                  deleted: false,
                },
              })
              .then(valeurs =>
                resolve({
                  doc,
                  valeurs,
                }),
              )
              .catch(reject),
          );
        });

        Promise.all(promises)
          .then(result => {
            if (isMounted) {
              setTransports(result);
            }

            return true;
          })
          .catch(toast.error);
      });
    }

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [espaceId, docId]);

  if (!docId) {
    return null;
  }

  let shippingInfo: PanierShippingInfoType;
  try {
    shippingInfo = DeliveryData.getInfo(1, values);
  } catch (error) {
    return <div>{(error as Error).message}</div>;
  }

  if (!shippingInfo.volume && !shippingInfo.weight) {
    return <div>Impossible de calculer la livraison</div>;
  }

  return (
    <>
      <dl className="grid grid-cols-2">
        {shippingInfo.totalVolume > 0 && (
          <>
            <dt>Volume</dt>
            <dd>
              {`${shippingInfo.nbVolume} x ${
                Math.round(shippingInfo.volume * 1000) / 1000
              } = ${Math.round(shippingInfo.totalVolume * 1000) / 1000} m3`}
            </dd>
          </>
        )}

        {shippingInfo.totalVW > 0 && (
          <>
            <dt>Poids volumétrique</dt>
            <dd>{`${shippingInfo.nbVolume} x ${
              Math.round(shippingInfo.volumetricWeight * 100) / 100
            } = ${Math.round(shippingInfo.totalVW * 100) / 100} kg`}</dd>
          </>
        )}

        {shippingInfo.totalWeight > 0 && (
          <>
            <dt>Poids</dt>
            <dd>{`${shippingInfo.nbWeight} x ${shippingInfo.weight} = ${shippingInfo.totalWeight} kg`}</dd>
          </>
        )}
        {!shippingInfo.express && (
          <>
            <dt>Livraison express</dt>
            <dd>Impossible</dd>
          </>
        )}
        {shippingInfo.dimensionMax > 0 && (
          <>
            <dt>Dévelopé</dt>
            <dd>{`${shippingInfo.dimensionMax} cm`}</dd>
          </>
        )}
      </dl>

      <div>
        {transports.map(({ doc: rule, valeurs }) => (
          <TransportRule
            key={rule.id}
            rule={rule}
            shippingInfo={shippingInfo}
            valeurs={valeurs}
          />
        ))}
      </div>
    </>
  );
};

export default DataProduitsEstimationLivraison;
